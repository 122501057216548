(function(angular, app) {
	'use strict';

	app.service('AR', [
		function() {
			var self = this;

			self.id = 6;
			self.culture = 'ar';
			self.title = 'العربية';
			self.label = 'Arabic';
			self.short = 'ع';
			self.direction = 'rtl';
			self.icon = 'https://d226b0iufwcjmj.cloudfront.net/localization/ar.png';
			self.dateFormat = {
				date: 'dd/MM/yyyy',
				time: 'HH:mm',
				dateAndTime: 'dd/MM/yyyy' + ' ' + 'HH:mm'
			};
			self.translate = {
				'arabic': 'عربي',
				'english': 'إنجليزي',
				'spanish': 'الأسبانية',
				'french': 'فرنسي',
				'hebrew': 'اللغة العبرية',
				'russian': 'الروسية',
				'left': 'يسار',
				'right': 'يمين',
				'up': 'أعلى',
				'down': 'أسفل',
				'search': 'بحث',
				'add to cart': 'أضف إلى العربة',
				'add to current cart': 'أضف إلى العربة الحالية',
				'create new order': 'إنشاء طلب جديد',
				'cannot add to cart': 'لا يمكن الإضافة إلى العربة',
				'add cart products': 'أضف منتجات إلى العربة',
				'special products': 'منتجات خاصة',
				'save to shopping list': 'حفظ في قائمة التسوق',
				'new list name': 'اسم القائمة الجديدة',
				'home': 'الصفحة الرئيسية',
				'powered by': 'مُشغّل بواسطة',
				'remove': 'إزالة',
				'login': 'تسجيل الدخول',
				'email': 'البريد الإلكتروني',
				'password': 'كلمة المرور',
				'logout': 'تسجيل الخروج',
				'orders history': 'تاريخ الطلبات',
				'recent orders': 'الطلبات الأخيرة',
				'checkout': 'إنهاء الشراء',
				'clear cart': 'إخلاء العربة',
				'callcustomer': 'اتصل بالعميل',
				'creditcard': 'بطاقة الائتمان',
				'storecredit': 'رصيد المتجر',
				'the delivery time is no longer available': 'وقت التوصيل المحدد لم يعد متاحًا',
				'add card': 'إضافة بطاقة',
				'activate card': 'تنشيط البطاقة',
				'add another card': 'إضافة بطاقة أخرى',
				'order update': 'تحديث الطلب',
				'updated order value': 'قيمة الطلب التي تم تحديثها',
				'(estimated)': ' (المُقدَّر)',
				'in order to complete the order update, please choose the payment method': 'لاستكمال تحديثك للطلب، يُرجى اختيار طريقة السداد',
				'confirm update': 'برجاء تأكيد التحديث',
				'add another account': 'إضافة حساب آخر',
				'remove card': 'قم بإزالة البطاقة',
				'pay with': 'الدفع بواسطة',
				'pay with credit card': 'الدفع بواسطة بطاقة الائتمان',
				'remove account': 'إزالة حساب',
				'credit card number': 'رقم بطاقة الائتمان',
				'cvv': 'cvv',
				'delivery address': 'عنوان التوصيل',
				'city': 'المدينة',
				'street': 'الشارع',
				'phone num': 'رقم الهاتف',
				'recipient\'s name': 'اسم المستلم',
				'floor / apartment': 'الطابق / المنزل',
				'house number': 'رقم المنزل',
				'house no.': 'رقم المنزل',
				'available days': 'الأيام المتاحة',
				'entry: spautocompleterequirematch error': 'الإدخال: أدخل حرف (أ-ي)',
				'house_entry_none': 'بدون',
				'house_entry_a': 'أ',
				'house_entry_b': 'ب',
				'house_entry_c': 'ت',
				'house_entry_d': 'ث',
				'house_entry_e': 'ج',
				'house_entry_f': 'ح',
				'house_entry_g': 'خ',
				'house_entry_h': 'د',
				'house_entry_i': 'ذ',
				'house_entry_j': 'ر',
				'delivery time': 'وقت التوصيل',
				'pickup time': 'وقت الاستلام',
				'today': 'اليوم',
				'sunday': 'الأحد',
				'monday': 'الاثنين',
				'tuesday': 'الثلاثاء',
				'wednesday': 'الأربعاء',
				'thursday': 'الخميس',
				'friday': 'الجمعة',
				'saturday': 'السبت',
				'sun': 'الأحد',
				'mon': 'الاثنين',
				'tue': 'الثلاثاء',
				'wed': 'الأربعاء',
				'thu': 'الخميس',
				'fri': 'الجمعة',
				'notes': 'ملاحظات',
				'add instructions': 'إضافة تعليمات',
				'summary': 'الملخص',
				'products': 'المنتجات',
				'you saved': 'قمت بحفظ',
				'payment': 'الدفع',
				'delivery charge': 'رسوم التوصيل',
				'price without discount': 'السعر بدون خصم',
				'price after discount': 'السعر بعد الخصم',
				'total': 'الإجمالي',
				'between': 'بين',
				'order id': 'رقم معرف الطلب',
				'your order has been received': 'تم استلام طلبك',
				'shopping lists': 'قوائم التسوق',
				'shopping list created': 'تم إنشاء قائمة التسوق',
				'forgot password': 'نسيت كلمة المرور',
				'register': 'تسجيل',
				'retailer details': 'تفاصيل تاجر التجزئة',
				'in all departments': 'في جميع الأقسام',
				'edit details': 'تعديل التفاصيل',
				'loading': 'جارِ التحميل',
				'supervision': 'الإشراف',
				'load previous orders': 'تحميل الطلبات السابقة',
				'close previous orders': 'غلق الطلبات السابقة',
				'previous orders': 'الطلبات السابقة',
				'sale': 'تخفيض',
				'club': 'النادي',
				'club sale': 'تخفيض للأعضاء',
				'open sale details': 'فتح تفاصيل التخفيض',
				'choose brand': 'اختر العلامة التجارية',
				'specials_and_benefits_title': 'عروض ومزايا',
				'specials': 'العروض الخاصة',
				'go to specials': 'انتقل إلى العروض الخاصة',
				'item': 'عنصر',
				'items': 'عناصر',
				'hide cart': 'إخفاء العربة',
				'best ingredients for best results': 'أفضل مكونات ل أفضل نتائج',
				'order items by': 'ترتيب العناصر حسب',
				'name': 'الاسم',
				'actions': 'الأنشطة',
				'creation date': 'تاريخ الإنشاء',
				'delete': 'حذف',
				'create new shopping list': 'إنشاء قائمة تسوق جديدة',
				'shopping list name': 'اسم قائمة التسوق',
				'create': 'إنشاء',
				'id': 'رقم المعرف',
				'time': 'الوقت',
				'date and time': 'التاريخ والوقت',
				'ok': 'حسنًا',
				'address': 'العنوان',
				'phone': 'الهاتف',
				'the site is undergoing maintenance': 'الموقع تحت الصيانة',
				'please try again later': 'يُرجى إعادة المحاولة في وقت لاحق',
				'the site is active only for registered users, please login': 'الموقع غير نشط إلا للمستخدمين المسجلين، يُرجى تسجيل الدخول',
				'the site is disabled': 'الموقع معطل',
				'comment': 'تعليق',
				'noun search': 'بحث',
				'finish order': 'إنهاء الطلب',
				'reset password': 'إعادة ضبط كلمة المرور',
				'mobile': 'الجوال',
				'mobile phone': 'الهاتف الجوال',
				'phone number': 'رقم الهاتف',
				'edit': 'تعديل',
				'add phone': 'إضافة هاتف',
				'first name': 'الاسم الأول',
				'last name': 'اسم العائلة',
				'birth date': 'تاريخ الميلاد',
				'user name and password': 'اسم المستخدم وكلمة المرور',
				'user name': 'اسم المستخدم',
				'gender': 'النوع',
				'male': 'ذكر',
				'female': 'أنثى',
				'prefer not to say': 'أفضّل عدم القول',
				'personal information': 'المعلومات الشخصية',
				'user details': 'تفاصيل المستخدم',
				'contact details': 'تفاصيل الاتصال',
				'comments': 'التعليقات',
				'work': 'العمل',
				'allow to send promotions': 'السماح بإرسال عروض ترويجية',
				'my items': 'عناصري',
				'image': 'صورة',
				'price': 'السعر',
				'quantity': 'الكمية',
				'total (estimated)': 'المجموع (تقديري)',
				'call customer': 'اتصل بالزبون',
				'payment method' : 'طريقة الدفع',
				'substitutions': 'الاستبدال',
				'order time': 'وقت الطلب',
				'received_items': 'تم استلامه',
				'replaced with':'تم استبداله ب',
				'new product': 'منتج جديد',
				'credit card charge' : 'رسم بطاقة الائتمان',
				'view details' : 'عرض التفاصيل',
				'payment method ending with' : 'طريقة الدفع تنتهي ب',
				'actions on marked items': 'الأنشطة على العناصر المحددة',
				'create new cart': 'إنشاء عربة جديدة',
				'create shopping list': 'إنشاء قائمة تسوق',
				'no products were selected': 'لم يتم تحديد أي منتجات',
				'total sum': 'المبلغ الإجمالي',
				'order status': 'حالة الطلب',
				'new': 'جديد',
				'received': 'تم استلامه',
				'charged': 'تم احتساب رسومه',
				'delivered': 'تم توصيله',
				'cancelled': 'تم إلغاؤه',
				'suspended': 'معلق',
				'processing': 'جارِ المعالجة',
				'collected': 'تم جمعه',
				'registered': 'مسجل',
				'received text': 'تم استلام الطلب بنجاح في النظام ولكن لم يتم تحصيل الرسوم بعد',
				'charged text': 'تم خصم مبلغ الشراء من بطاقة الائتمان',
				'delivered text': 'تم إرسال الطلب وهو في طريقه إليكم',
				'cancelled text': 'تم إلغاء الطلب ولا تتم معالجته',
				'suspended text': 'لا يمكن إكمال الطلب، مطلوب تدخل العميل',
				'processing text': 'الطلب في عملية التجميع في المتجر حاليًا',
				'collected text': 'تم جمع المنتجات ولكن لم يتم تسجيلها للصندوق بعد',
				'registered text': 'تم جمع منتجات ومسحها ولكن لم يتم دفعها بعد',
				'no products were found': 'لم يتم العثور على أي منتجات',
				'order number': 'رقم الطلب',
				'date': 'التاريخ',
				'back': 'العودة',
				'<< back': '<< العودة',
				'save': 'حفظ',
				'shopping list': 'قائمة التسوق',
				'address 1': 'شارع+رقم البيت',
				'address 2': 'مدخل + طابق + شقة',
				'i confirm that i have read the ': 'أؤكد أنني قرأت',
				'terms': 'الشروط',
				'similar products': 'منتجات مماثلة',
				'check all': 'تحديد الكل',
				'uncheck all': 'إزالة تحديد الكل',
				'all': 'الكل',
				'narrow your choice': 'تضييق اختيارك',
				'brand': 'العلامة التجارية',
				'type': 'النوع',
				'category': 'الفئة',
				'sign in': 'تسجيل الدخول',
				'sign in using facebook': 'تسجيل الدخول بواسطة فيسبوك',
				'it\'s easy': 'إنه سهل',
				'please sign in to continue...': 'يُرجى تسجيل الدخول للمتابعة...',
				'country': 'البلد',
				'at': 'في',
				'let\'s start': 'لنبدأ',
				'adding some items': 'إضافة بعض العناصر ',
				'it\'s easy!': 'إنه سهل',
				'forgot password?': 'هل نسيت كلمة المرور؟',
				'confirm password': 'تأكيد كلمة المرور',
				'i agree to the': 'أوافق على',
				'terms & conditions': 'الشروط والأحكام',
				'policy_end': 'وأوافق على شروطهم',
				'please let me know about promotions': 'أؤكد استلام التحديثات والمزايا عبر البريد الإلكتروني والرسائل النصية (يمكن إيقاف تلقي التحديثات في أي وقت)',
				'reset': 'إعادة ضبط',
				'please check your email for the password reset information': 'يُرجى التحقق من بريدك الإلكتروني للحصول على معلومات إعادة ضبط كلمة المرور',
				'please check your inbox and follow the instructions': 'يرجى التحقق من صندوق الوارد الخاص بك واتباع التعليمات',
				'if you haven\'t received an email, please check if you mistyped your email address or contact customer service': 'إذا لم تتلقَ رسالة على البريد الإلكتروني، فيرجى التحقق مما إذا كنت قد أخطأت في كتابة عنوان بريدك الإلكتروني أو اتصل بخدمة العملاء على الرقم ',
				'open previous carts:': 'فتح العربات السابقة',
				'save and sign in': 'حفظ وتسجيل الدخول',
				'save for later': 'حفظ لوقت لاحق',
				'today\'s special deals': 'صفقات اليوم الخاصة',
				'no': 'لا',
				'yes': 'نعم',
				'delivery': 'التوصيل',
				'pickup': 'الاستلام',
				'pick and go': 'اختر وتابع',
				'pick & go': 'اختر وتابع',
				'undefined': 'غير معرف',
				'anything else': 'ملاحظة للتوصيل',
				'delivery date': 'تاريخ التوصيل',
				'pickup date': 'تاريخ الاستلام',
				'estimated total': 'الإجمالي',
				'estimated': 'المقدر',
				'allow substitutions': 'السماح باستبدال المنتج',
				'delivery date and time': 'تاريخ ووقت التوصيل',
				'next': 'التالي',
				'contact details & payment': 'تفاصيل الاتصال والدفع',
				'checkout contact details': 'تفاصيل شخصية',
				'summary & payment': 'الملخص والدفع',
				'delivery/pickup preferences': 'التوصيل / الاستلام',
				'how do you want to get your order?': 'كيف تريد استلام طلبك',
				'the site is active only for registered users': 'الموقع غير متاح إلا للمستخدمين المسجلين',
				'please login': 'يُرجى تسجيل الدخول',
				'sort by1': 'ترتيب حسب',
				'save as a shopping list': 'الحفظ كقائمة تسوق',
				'your shopping cart': 'عربة التسوق',
				'save to list': 'حفظ في القائمة',
				'product name': 'اسم المنتج',
				'order ': 'الطلب',
				'order': 'ترتيب',
				'unit price': 'سعر الوحدة',
				'units': 'الوحدات',
				'unit': 'الوحدة',
				'proceed to checkout': 'المتابعة لإتمام العملية',
				'est.': 'حوالي',
				'est. total weight': 'الوزن الإجمالي المقدر',
				'est. weight': 'الوزن المقدر',
				'mobile: required error': 'يُرجى إدخال الهاتف المحمول',
				'mobilephone: required error': 'يُرجى إدخال الهاتف المحمول',
				'phone: required error': 'يُرجى إدخال رقم هاتف',
				'phone: minlength error': 'يجب أن يحتوي رقم الهاتف على 7 أرقام على الأقل',
				'city: required error': 'يُرجى إدخال مدينة',
				'city: spautocompleterequirematch error': 'لم يتم العثور على المدينة',
				'cityinput: spautocompleterequirematch error': 'لم يتم العثور على المدينة',
				'street: required error': 'يُرجى إدخال شارع',
				'street: spautocompleterequirematch error': 'لم يتم العثور على الشارع',
				'streetinput: spautocompleterequirematch error': 'لم يتم العثور على الشارع',
				'housenum: required error': 'يُرجى إدخال رقم منزل',
				'housenum: min error': 'يُرجى إدخال رقم منزل',
				'text1: required error': 'يُرجى إدخال عنوان',
				'addresstext: required error': 'يُرجى إدخال عنوان',
				'fullname: required error': 'يُرجى إدخال اسم كامل',
				'email: required error': 'يُرجى إدخال عنوان البريد الإلكتروني',
				'email: email error': 'يُرجى إدخال عنوان بريد إلكتروني صالح',
				'firstname: required error': 'يُرجى إدخال الاسم الأول',
				'lastname: required error': 'يُرجى إدخال اسم العائلة',
				'password: required error': 'يُرجى إدخال كلمة المرور',
				'password: minlength error': 'يجب أن تشمل كلمة المرور 6 أحرف على الأقل',
				'password: maxlength error': 'طول كلمة المرور من 6 حتى 30 حرفًا',
				'password: pattern error': 'يجب أن تشمل كلمة المرور 6 أحرف ورقم واحد على الأقل',
				'confirmpassword: required error': 'يُرجى تأكيد كلمة المرور',
				'confirm_password: required error': 'يُرجى تأكيد كلمة المرور',
				'policyapproval: required error': 'يُرجى الموافقة على سياسة الموقع وشروطه',
				'please confirm that you accept the terms and conditions for using this site': 'يُرجى تأكيد قبولك لشروط وأحكام استخدام هذا الموقع',
				'zipcode: required error': 'يُرجى إدخال الرمز البريدي',
				'message: required error': 'يُرجى إدخال نص',
				'loyaltycardid: required error': 'يُرجى إدخال رقم عضو النادي',
				'ValidationKey: required error': 'يُرجى إدخال رقم بطاقة الهوية',
				'birthdate: date error': 'يُرجى إدخال تاريخ الميلاد',
				'confirmpassword: passwords do not match error': 'كلمتي المرور غير متطابقتين',
				'confirm_password: Passwords do not match error': 'كلمتي المرور غير متطابقتين',
				'confirmPassword: pattern error': 'كلمتي المرور غير متطابقتين',
				'confirm_password: pattern error': 'كلمتي المرور غير متطابقتين',
				'floor: step error': 'يُرجى إدخال رقم كامل',
				'please enter an amount': 'يُرجى إدخال قيمة',
				'select order': 'تحديد طلب',
				'create new list': 'إنشاء قائمة جديدة',
				'my account': 'حسابي',
				'continue shopping': 'متابعة التسوق',
				'homepagenavigation': 'العودة إلى الصفحة الرئيسية',
				'add comments': 'إضافة تعليقات',
				'add comment': 'إضافة تعليق',
				'what happens if something i ordered is not available?': 'ماذا يحدث لو كان المنتج الذي طلبته غير متاح؟',
				'read more': 'قراءة المزيد',
				'read less': 'قراءة أقل',
				'substitute_preference_1_label': 'استبدلوا المنتجات',
				'substitute_preference_1_description': 'سنقوم باستبدال المنتجات الناقصة دون الاتصال بك',
				'substitute_preference_2_label': 'اتصلوا بي',
				'substitute_preference_2_description': 'سنستبدل المنتجات بتنسيق هاتفي',
				'substitute_preference_3_label': 'لا تستبدلوا منتجات',
				'substitute_preference_3_description': 'لن نستبدل منتجات غير متواجدة',
				'substitute_preference_4_label': 'أرسلوا لي SMS',
				'substitute_preference_4_description': 'سنرسل لك SMS قبل الاستبدال',
				'substitute_preference_5_label': 'WhatsApp',
				'substitute_preference_5_description': 'We will send you a text message in WhatsApp before we replace a missing product',
				'substitute_preference__label': 'تجنب خطأ',
				'substitute_preference__description': 'تجنب خطأ',
				'zip code': 'الرمز البريدي',
				'zip': 'الرمز البريدي',
				'please type in your zip code': 'يُرجى كتابة الرمز البريدي الخاص بك',
				'find address': 'البحث عن العنوان',
				'you cannot edit this field before entering a zip code for your address': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'please choose your address': 'يُرجى اختيار العنوان الخاص بك',
				'you will be able to edit the address details': 'ستتمكّن من تعديل تفاصيل العنوان ',
				'you cannot edit this field': 'لا يمكنك تعديل هذا الحقل',
				'full name': 'الاسم الكامل',
				'payment details': 'تفاصيل الدفع',
				'your order will be delivered': 'سيتم توصيل طلبك',
				'your order will be ready for pickup': 'سيكون طلبك جاهزًا للاستلام',
				'your order will be delivered in': 'سيتم توصيل طلبك في',
				'your order will be ready for pickup in': 'سيكون طلبك جاهزًا للاستلام في',
				'substitution choice': 'استبدال المنتج',
				'address line 1': 'عنوان',
				'add another address line': 'إضافة سطر آخر للعنوان',
				'address line 2': 'عنوان إضافي',
				'remove another address': 'إزالة العنوان الإضافي',
				'almost there!': 'نحن على وشك الانتهاء!',
				'send the delivery sooner, if possible': 'أرسل الطلب في وقت أقرب إذا كان ذلك ممكنًا',
				'prepare the order sooner, if possible': 'قم بإعداد الطلب في وقت أقرب إذا كان ذلك ممكنًا',
				'sooner if possible': 'في وقت أقرب إذا كان ذلك ممكنًا',
				'checkout summary': 'ملخص العملية',
				'only': 'فقط',
				'day': 'اليوم',
				'free': 'مجانًا',
				'total save': 'إجمالي الخصم',
				'place order': 'تقديم طلب',
				'your email or password seems to be wrong': 'يبدو أن هناك خطأ في بريدك الإلكتروني أو كلمة المرور',
				'please try again': 'يُرجى إعادة المحاولة',
				'oops': 'عفوًا',
				'null': '',
				'select': 'تحديد',
				'show previous orders': 'عرض الطلبات السابقة',
				'hide previous orders': 'إخفاء الطلبات السابقة',
				'choose list': 'اختر قائمة',
				'choose order': 'اختر طلب',
				'choose shopping list': 'اختر قائمة تسوق',
				'we couldn\'t create your shopping list': 'لم نتمكن من إنشاء قائمة التسوق الخاصة بك',
				'list name': 'اسم القائمة',
				'create list': 'إنشاء قائمة',
				'floor': 'الطابق',
				'apartment': 'المنزل',
				'close details': 'غلق التفاصيل',
				'we just sent you an order confirmation by email': 'أرسلنا للتو تأكيد الطلب عبر البريد الإلكتروني',
				'thank you for shopping at': 'شكرًا على تسوقك في',
				'prutah_checkout_finish_thank_you_line_1': 'شكرًا على الشراء',
				'prutah_checkout_finish_thank_you_line_2': 'في',
				'please add your payment card': 'يُرجى إضافة بطاقة الدفع الخاصة بك',
				'my orders': 'طلباتي',
				'hours': 'ساعات',
				'ready for pickup': 'جاهز للاستلام',
				'we are sorry, but the following products are out of stock': 'معذرة، المنتجات التالية نفدت كميتها',
				'remove from cart & continue': 'الإزالة من العربة والمتابعة',
				'you save': 'لقد ادخرت',
				'can\'t find item?': 'لم تتمكن من العثور على عنصر؟',
				'can\'t find an item?': 'لم تتمكن من العثور على عنصر؟',
				'tax': 'ض.ق.م.',
				'unknown': 'غير معروف',
				'other': 'أخرى',
				'add it here': 'أضفه هنا',
				'choose category': 'اختر فئة',
				'if we are unfamiliar with the product or don\'t have it in stock, we will give you a call': 'إذا لم تكن على دراية بالمنتج أو لم يكن المنتج متوفرًا، سوف نتصل بك',
				'store info': 'معلومات المتجر',
				'stores info': 'فروع المتاجر',
				'store-details': 'تفاصيل المتجر',
				'store location': 'موقع المتجر',
				'price high-low': 'السعر من أكبر إلى أقل',
				'price low-high': 'السعر من أقل إلى أكبر',
				'my shopping lists': 'قوائم التسوق الخاصة بي',
				'remove shopping list': 'إزالة قائمة التسوق',
				'are you sure you want to remove the shopping list': 'هل أنت متأكد أنك تريد إزالة قائمة التسوق',
				'removed': 'تمت الإزالة',
				'add to shopping list': 'أضف إلى قائمة التسوق',
				'payments number': 'عدد الدفعات',
				'cvv: required error': 'يُرجى إدخال CVV',
				'enter cvv': 'أدخل cvv',
				'cvv: please enter cvv error': 'cvv: خطأ يُرجى إدخال cvv',
				'cvv: minlength error': 'cvv يجب أن يشمل 3 أرقام على الأقل',
				'cancel': 'إلغاء',
				'continue with this cart': 'المتابعة بهذه العربة',
				'merge carts': 'دمج العربات',
				'would you like to merge the two carts or continue using this cart': 'هل تريد دمج عربتين أم المتابعة بهذه العربة',
				'somewhere else': 'في مكان آخر',
				'you have an open cart with': 'لديك عربة مفتوحة مع',
				'total weight': 'الوزن الإجمالي',
				'account info': 'معلومات الحساب',
				'orders': 'الطلبات',
				'payment methods': 'طرق الدفع',
				'coupons/discounts': 'القسائم / الخصومات',
				'save changes': 'حفظ التغييرات',
				'credit cards': 'بطاقات الائتمان',
				'paypal accounts': 'حسابات paypal',
				'shopping list named': 'تمت تسمية قائمة التسوق',
				'your account info has been successfully updated': 'تم تحديث معلومات حسابك بنجاح',
				'was successfully created with': 'تم إنشاؤها بنجاح باستخدام',
				'items successfully added': 'تمت إضافة العناصر بنجاح',
				'items were successfully added to shopping list': 'تمت إضافة العناصر بنجاح إلى قائمة التسوق',
				'item was successfully added to shopping list': 'تمت إضافة العنصر بنجاح إلى قائمة التسوق',
				'items were not added to shopping list': 'لم تتم إضافة العناصر إلى قائمة التسوق',
				'item were not added to shopping list': 'لم تتم إضافة العناصر إلى قائمة التسوق',
				'saved in': 'تم الحفظ في',
				'we are not able to add the following products to your current order': 'لا يمكننا إضافة المنتجات التالية إلى طلبك الحالي',
				'opening hours': 'ساعات العمل',
				'additional': 'المزيد',
				'area map': 'خريطة',
				'contact us': 'اتصل بنا',
				'store information': 'معلومات المتجر',
				'how can we help you today': 'كيف يمكننا مساعدتك اليوم',
				'how can we help you': 'كيف يمكننا مساعدتك',
				'send': 'إرسال',
				'sent successfully': 'تم تحديث التفاصيل بنجاح',
				'successfully updated': 'تم التحديث بنجاح',
				'successful update': 'تحديث ناجح',
				'shopping list was successfully updated': 'تم تحديث قائمة التسوق بنجاح',
				'service provider blocker': 'محظور من قبل مزود الخدمة',
				'your service provider is blocking some of the website\'s functionality': 'يحظر مزود الخدمة الخاص بك جزءًا من الموقع ويمنع الموقع من العمل بشكل كامل',
				'once we complete picking your order, we adjust your final charge based on weight adjustments, substitutions & out of stock items': 'بمجرد الانتهاء من استلام طلبك، نقوم بتعديل الرسوم النهائية بناءً على تعديلات الوزن وعمليات الاستبدال و العناصر غير المتوفرة',
				'please contact your service provider': 'يُرجى الاتصال بمزود الخدمة الخاصة بك',
				'close': 'غلق',
				'passwords do not match': 'كلمتا المرور غير متطابقتين',
				'send reset code': 'إرسال رمز إعادة ضبط',
				'are you sure you want to clear your cart': 'هل أنت متأكد أنك تريد إخلاء عربتك',
				'e-mail': 'البريد الإلكتروني',
				'your message has been sent': 'تم إرسال رسالتك',
				'change zip code': 'تغيير الرمز البريدي',
				'change area': 'تغيير المنطقة',
				'hey there': 'مرحبًا',
				'let\'s make sure we\'re available in your area': 'لنتأكد من أننا متاحين في منطقتك',
				'please enter your zip code': 'يُرجى إدخال رمزك البريدي',
				'please enter your area': 'يُرجى إدخال منطقة سكنكم',
				'area: required error': 'يُرجى إدخال منطقة سكنكم',
				'the area you entered, is served by this store': 'هذا المتجر يخدم منطقتك',
				'pickup service only': 'خدمة الاستلام فقط',
				'we serve your area': 'نحن نخدم منطقتك',
				'delivery method in this area': 'طريقة التوصيل في هذه المنطقة',
				'check': 'فحص',
				'for ': 'لـ',
				'we offer': 'نعرض',
				'both': '',
				'and': 'و',
				'and the': 'و',
				'pick up': 'الاستلام',
				'options': '',
				'your store will be': 'متجرك سيكون',
				'by changing your zip code you will lose your shopping cart': 'بتغيير رمزك البريدي ستفقد عربة التسوق الخاصة بك',
				'by changing your area you will lose your shopping cart': 'بتغيير منطقتك ستفقد عربة التسوق الخاصة بك',
				'go back': 'عودة',
				'don\'t change zip code': 'غيروا الرمز البريدي',
				'don\'t change area': 'إلغاء تغيير المنطقة',
				'currently we do not deliver to your area': 'نحن لا نجري عمليات توصيل إلى منطقتك حاليًا',
				'change store': 'تغيير المتجر',
				'go to store': 'انتقل إلى المتجر',
				'the zipcode you entered, is served by this store': 'هذا المتجر يخدم الرمز البريدي الذي أدخلته',
				'next delivery regular': 'تاريخ التوصيل القادم',
				'thank you for contacting us': 'شكرًا على تواصلك معنًا',
				'delivery preferences': 'التوصيل',
				'pickup preferences': 'الاستلام',
				'privacy policy': 'سياسة الخصوصية',
				'about us': 'معلومات عنّا',
				'not active': 'غير نشط',
				'case of': 'عبوة',
				'case': 'عبوة',
				'single': 'واحد',
				'ordersfromposfieldtitle0': 'يُرجى إدخال رقم هاتفكم المحمول',
				'ordersfromposfielddescription0': 'سوف نرسل لكم رسالة للتأكيد',
				'phonenumber: required error': 'يُرجى إدخال رقم هاتفك المحمول',
				'orderfromposcodesenttitle0': 'أرسلنا رسالة نصية مع رمز التأكيد على رقم <%value%>',
				'confirmcode: required error': 'يُرجى إدخال رمز التأكيد',
				'hi': 'مرحبًا',
				'entrance': 'المدخل',
				'faq': 'الأسئلة الشائعة',
				'new password': 'كلمة المرور الجديدة',
				'resetnewpassword: required error': 'يُرجى إعادة إدخال كلمة المرور الجديدة',
				'resetconfirmpassword: required error': 'يُرجى إعادة تأكيد كلمة المرور الجديدة',
				'resetconfirmpassword: passwords do not match error': 'كلمتي المرور غير متطابقتين',
				'resetnewpassword: minlength error': 'يجب أن تحوي كلمة المرور الجديدة 6 حروف على الأقل',
				'floor: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'floor: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'floor: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'text1: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'text1: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'text1: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'text2: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'text2: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'text2: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'street: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'street: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'street: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'cityinput: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'cityinput: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'cityinput: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'city: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'city: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'city: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'countryinput: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'countryinput: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'countryinput: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'housenum: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'housenum: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'housenum: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'entry: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'entry: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'entry: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'entrance: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'entrance: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'entrance: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'apartment: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'apartment: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'apartment: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'apartmentinput: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'apartmentinput: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'apartmentinput: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'address1input: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'address1input: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'address1input: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'address2input: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'address2input: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'address2input: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'comments: not_editable error': 'لا يمكنك تعديل هذا الحقل قبل إدخال الرمز البريدي الخاص بعنوانك',
				'comments: not_editable_locked error': 'لتغيير العنوان يرجى الاختيار مرة أخرى من قائمة الرمز البريدي',
				'comments: not_editable_locked_empty error': 'الرجاء إدخال الرمز البريدي الخاص بك واختيار عنوانك من القائمة',
				'zipcode: not_editable error': 'لا يمكنك تعديل هذا الحقل',
				'zipinput: not_editable error': 'لا يمكنك تعديل هذا الحقل',
				'zipcodeinput: not_editable error': 'لا يمكنك تعديل هذا الحقل',
				'once we collected your order, we will call to collect your payment': 'بمجرد أن نستلم طلبك، سنتصل بك لتحصيل المدفوعات',
				'choose how you\'d like to pay': 'اختر الطريقة التي تود الدفع بها',
				'your credit cards': 'بطاقات الائتمان الخاصة بك',
				'your paypal accounts': 'حسابات PayPal الخاصة بك',
				'enter your credit on file': 'يُرجى إدخال رقم حساب العميل الخاصة بك',
				'subtotal': 'المجموع الفرعي',
				'substitutions choice': 'استبدال المنتج',
				'let\'s change your password...': 'اختاروا كلمة مرور جديدة...',
				'you must agree to the terms': 'يجب عليك الموافقة على الشروط',
				'registration completed': 'تم الانتهاء من التسجيل',
				'registration is closed right now': 'التسجيل مغلق الآن',
				'please enter cvv': 'يُرجى إدخال cvv',
				'pre auth: declined invld acct': 'رمز CVV لا يتلاءم مع بطاقة الائتمان. يُرجى المحاولة مجددا',
				'cvv is an anti-fraud security feature to help verify that you are in possession of your credit card': 'رقم cvv هو رقم للمساعدة على التحقق من حيازتك لبطاقة الائتمان الخاصة بك',
				'the three-digit cvv number is printed on the signature panel on the back of the card immediately after the card\'s account number': 'رقم cvv المكون من ثلاثة أرقام مطبوع على لوحة التوقيع على الجزء الخلفي من البطاقة',
				'select location': 'حدد الموقع',
				'pickup location': 'موقع الاستلام',
				'day0': 'الأحد',
				'day1': 'الاثنين',
				'day2': 'الثلاثاء',
				'day3': 'الأربعاء',
				'day4': 'الخميس',
				'day5': 'الجمعة',
				'day6': 'السبت',
				'week_day_0': 'الأحد',
				'week_day_1': 'الاثنين',
				'week_day_2': 'الثلاثاء',
				'week_day_3': 'الاربعاء',
				'week_day_4': 'الخميس',
				'week_day_5': 'الجمعة',
				'week_day_6': 'السبت',
				'ticket_status_1': 'جديد',
				'ticket_status_2': 'قيد المعالجة',
				'ticket_status_3': 'إنهاء',
				'ticket': 'بطاقة خدمة',
				'by': 'حسب',
				'back to tickets': 'العودة إلى بطاقات الخدمة',
				'me': 'أنا',
				'status': 'الحالة',
				'description': 'الوصف',
				'created date': 'تاريخ الإنشاء',
				'customer service': 'خدمة العملاء',
				'service': 'الخدمة',
				'inquiry': 'توجه رقم',
				'type in your comment': 'اكتب تعليقك',
				'ticket id': 'معرف البطاقة',
				'no previous inquiries': 'لا توجد استفسارات سابقة',
				'we didn\'t recognize the address you entered': 'لم نتعرف على العنوان الذي أدخلته',
				'sorry, currently we do not deliver to this area.': 'معذرة، نحن لا نقوم بعمليات توصيل إلى هذه المنطقة حاليًا',
				'sorry, currently we do not deliver to this area, for further inquiries call': 'معذرة، نحن لا نقوم بعمليات توصيل إلى هذه المنطقة حاليًا. للاستفسار، يُرجى الاتصال',
				'sorry, currently we do not deliver to this area, for further inquiries call our customer service': 'معذرة، نحن لا نقوم بعمليات توصيل إلى هذه المنطقة حاليًا. للاستفسار، يُرجى الاتصال بخدمة العملاء',
				'sorry, branch change required': 'معذرة، رقم الفرع مطلوب',
				'this address could only be delivered from branch': 'لا يمكن التوصيل إلى هذا العنوان إلا من الفرع',
				'try another address or choose pickup option': 'جرّب عنوان آخر أو اختر خيار الاستلام الشخصي',
				'in': 'في غضون',
				'hrs': 'ساعات',
				'review your order and process payment': 'ملخص الطلب والدفع',
				'what did you mean': 'ماذا تقصد',
				'please choose delivery time': 'يُرجى اختيار وقت التوصيل',
				'please choose pickup time': 'يُرجى اختيار وقت الاستلام',
				'delivery details': 'تفاصيل التوصيل',
				'your details': 'تفاصيلك',
				'pickup details': 'تفاصيل الاستلام',
				'street number and street name': 'شارع (إن وجد) ورقم منزل',
				'entrance, apartment, suite, building, floor, etc.': 'مدخل، طابق، رقم شقة إلخ',
				'apartment, suite, building, floor, etc.': 'طابق، رقم شقة إلخ',
				'type letter': 'اكتب حرفًا',
				'add products': 'إضافة منتجات',
				'add': 'إضافة',
				'choose exist list': 'اختر قائمة موجودة',
				'clear all': 'مسح الكل',
				'or': 'أو',
				'for': 'لـ',
				'to ': 'إلى',
				'your order total {{areaname}}does not meet the {{minimum | currency}} minimum order total': 'طلبك الإجمالي {{areaname}} لا يتطابق مع الحد الأدنى لإجمالي الطلب {{minimum | currency}}',
				'please add to your cart items worth {{difference | currency}} to proceed to checkout': 'يُرجى إضافة عناصر بقيمة {{difference | currency}} إلى عربة التسوق لإتمام العملية',
				'not including items on sale': 'باستثناء العناصر المعروضة للبيع',
				'delivery fee': 'رسوم التوصيل',
				'pickup fee': 'رسوم الاستلام',
				'pickup times & areas': 'أوقات وأماكن الاستلام',
				'service fee': 'رسوم الخدمة',
				'choose your pickup time': 'اختر وقت الاستلام',
				'delivery times': 'أوقات التوصيل',
				'delivery times & areas': 'أوقات ومناطق التوصيل',
				'delivery areas': 'مناطق التوصيل',
				'add your gift card': 'أضف بطاقة الهدايا الخاصة بك',
				'enter your gift card id': 'أدخل معرف بطاقة الهدايا الخاصة بك',
				'phone: pattern error': 'رقم هاتف غير صالح',
				'phone: maxlength error': 'رقم هاتف غير صالح',
				'phone: number error': 'رقم هاتف غير صالح',
				'homephone: pattern error': 'رقم هاتف غير صالح',
				'homephone: number error': 'رقم هاتف غير صالح',
				'workphone: pattern error': 'رقم هاتف غير صالح',
				'workphone: number error': 'رقم هاتف غير صالح',
				'mobilephone: pattern error': 'رقم هاتف غير صالح',
				'mobilephone: number error': 'رقم هاتف غير صالح',
				'pay by phone': 'الدفع عبر الهاتف',
				'get it within': 'سيتم الشحن في غضون',
				'next delivery': 'عملية التوصيل القادمة',
				'changes to your cart will be lost. are you sure you want to leave?': 'ستفقد التغييرات التي أجريتها على عربتك. هل أنت متأكد أنك تريد الخروج؟',
				'the following products were not saved correctly': 'لم يتم حفظ المنتجات التالية بطريقة صحيحة',
				'the following products will not be for sale at this given time': 'المنتجات التالية لن تكون متاحة للبيع في ذلك الوقت المحدد',
				'shoplistname: required error': 'يُرجى إدخال اسم لقائمة المشتريات',
				'coupon number': 'رقم القسيمة',
				'delivery & pickup': 'أوقات التوصيل والاستلام',
				'pickup times': 'أوقات الاستلام',
				'store map': 'خريطة',
				'none': 'لا يوجد',
				'look for the express pickup icon': 'ابحث عن رمز الاستلام السريع',
				'look for the express delivery icon': 'ابحث عن رمز التوصيل السريع',
				'invalid coupon code': 'رمز قسيمة غير صحيح',
				'we couldn\'t find a matching coupon code': 'لم نتمكن من العثور على رمز قسيمة مطابق',
				'where would you like to shop': 'أين تريد أن تتسوق',
				'drop files here to upload or click to browse...': 'اسحب الملفات هنا لرفعها أو انقر على استعراض...',
				'drag files or browse...': 'اسحب الملفات أو انقر على استعراض...',
				'attachments': 'الملفات المرفقة',
				'link': 'الرابط',
				'select the store closer to your home': 'حدد المتجر القريب من منزلك',
				'for this store we offer': 'لهذا المتجر الذي نقدمه',
				'buy from this store': 'اشترِ من هذا المتجر',
				'deliveries': 'عمليات التوصيل',
				'dear customer': 'عزيزي العميل',
				'please notice': 'يُرجى الانتباه',
				'pseudoproductname: required error': 'يُرجى إدخال اسم المنتج',
				'pseudoproductname: maxlength error': 'لا يجوز أن يحوي اسم المنتج أكثر من 200 حرف',
				'area': 'المنطقة',
				'recipe': 'الوصفة',
				'steps': 'الخطوات',
				'more info': 'المزيد من المعلومات',
				'product description': 'وصف المنتج',
				'ingredients': 'المكونات',
				'more information': 'المزيد من المعلومات',
				'directions': 'تعليمات',
				'nutrition facts': 'الحقائق الغذائية',
				'nutrition values': 'القيم الغذائية',
				'serving size': 'حجم الوجبة',
				'servings per container': 'حجم الوجبة في كل عبوة',
				'amount per serving': 'القيمة لكل وجبة',
				'calories': 'السعرات الحرارية (طاقة)',
				'calories from fat': 'السعرات الحرارية من الدهون',
				'daily value': 'القيمة اليومية',
				'total fat': 'إجمالي الدهون',
				'saturated fat': 'الدهون المشبعة',
				'trans fat': 'الدهون المتحولة',
				'cholesterol': 'الكوليسترول',
				'sodium': 'الصوديوم',
				'total carbohydrate': 'كربوهيدرات',
				'dietary fiber': 'الألياف الغذائية',
				'sugars': 'السكريات',
				'protein': 'البروتين',
				'vitamin a': 'فيتامين a',
				'vitamin c': 'فيتامين c',
				'calcium': 'الكالسيوم',
				'iron': 'الحديد',
				'g': 'غ',
				'monounsaturated fat': 'الأحماض الدهنية الأحادية غير المشبعة',
				'polyunsaturated fat': 'الأحماض الدهنية المتعددة غير المشبعة',
				'percent daily': 'تعتمد النسب المئوية للاستهلاك اليومي على نظام غذائي يومي يحتوي على 2000 سعرة حرارية. قد تكون قيمتك اليومية مرتفعة أو منخفضة حسب كمية السعرات الحرارية التي تتناولها.',
				'something went wrong': 'هناك خطأ ما',
				'total price is less then the minimum price': 'السعر الإجمالي أقل من الحد الأدنى للسعر',
				'not all of the given products were found': 'لم يتم العثور على جميع المنتجات',
				'the following products are not active': 'المنتجات الآتية غير نشطة',
				'add to shopping cart': 'أضف إلى عربة التسوق',
				'items to cart': ' العناصر إلى السلة',
				'min': 'دقيقة',
				'from': 'من',
				'edit order': 'تعديل الطلب',
				'do you want to remove / add items to your existing order': 'هل تريد إزالة / إضافة عناصر إلى طلبك الحالي',
				'note': 'ملاحظة',
				'prices and specials will change according to the update date': 'تختلف الأسعار والعروض الخاصة حسب تاريخ التحديث',
				'more': 'المزيد',
				'less': 'تقليص',
				'offer not found': 'لم يتم العثور على العرض',
				'product info disclaimer': 'لا تعتمد على التفاصيل التي تظهر على الموقع عن مكونات المنتج، فقد تكون هناك أخطاء أو تناقضات في المعلومات، تظهر البيانات الدقيقة على المنتج. تحقق من البيانات الموجودة على عبوة المنتج مرة أخرى قبل الاستخدام.',
				'delivery hours': 'أوقات التوصيل',
				'delivery area': 'منطقة التوصيل',
				'branch': 'الفرع',
				'quantity limitation': 'حدود الكمية',
				'is limited to ': 'تقتصر على',
				'items only': 'العناصر فقط',
				'cart not found': 'لم يتم العثور على العربة',
				'existing member': 'عضو حالي',
				'connect your {clubname} membership account': 'قم بربط حساب عضويتك في {clubname}',
				'join {clubname} membership account': 'انضم إلى حساب عضوية {clubname}',
				'to complete your purchase, join/connect to {clubname} membership account': 'لإتمام عملية الشراء، انضم إلى / سجل الدخول بحساب العضوية في {clubname}',
				'in order to complete your purchase, you must connect to the loyalty club': 'لإتمام عملية الشراء، يجب عليك تسجيل الدخول بحساب في نادي العملاء',
				'customer id': 'رقم العضوية',
				'connect': 'ربط',
				'connect_to_loyalty': 'تحديد هوية',
				'could save': 'يمكن توفير',
				'continue without membership': 'متابعة بدون عضوية',
				'continue without club discounts': 'متابعة بدون عضوية',
				'you are now logged in and connected to your membership': 'أنت الآن مسجل الدخول ومتصل بعضويتك',
				'membership club': 'نادي العملاء',
				'connect to our membership club': 'تسجيل الدخول بحساب نادي العملاء الخاص بنا',
				'membership club number': 'رقم العضوية',
				'membership club special deals': 'صفقات للأعضاء',
				'membership_club': 'نادي',
				'{clubname} special deals': 'الصفقات الخاصة لـ {clubname}',
				'expiration date': 'تاريخ انتهاء الصلاحية',
				'you already a registered member': 'أنت بالفعل عضو مسجل',
				'we did not find your membership club account': 'لم نجد حسابك في النادي',
				'sorry': 'معذرة',
				'you are now connected to your membership club account': 'تم الربط بحسابك في النادي',
				'you have successfully registered to {clubname}. you will need to connect as a club member, once you receive your credit card': 'لقد سجلت بنجاح في {clubname}. ستحتاج إلى الدخول كعضو في النادي عند استلامك لبطاقة الائتمان',
				'the registration process will end when you will pay a membership fee at checkout': 'ستنتهي عملية التسجيل عندما تدفع رسوم العضوية في نهاية عملية الشراء',
				'remove credit card info': 'إزالة معلومات بطاقة الائتمان',
				'are you sure you want to remove credit card information': 'هل أنت متأكد أنك تريد إزالة معلومات بطاقة الائتمان',
				'remove paypal account': 'إزالة حساب PayPal',
				'are you sure you want to remove this paypal account': 'هل أنت متأكد أنك تريد إزالة حساب PayPal',
				'are you sure you want to cancel your order update and return to your original order': 'هل أنت متأكد أنك تريد إلغاء تحديث طلبك والعودة إلى طلبك الأصلي',
				'print': 'طباعة',
				'track order': 'طلب رقم',
				'view order': 'عرض الطلب',
				'forgot something': 'هل نسيت شيئًا ما',
				'update order': 'تحديث الطلب',
				'update order failed': 'فشل تحديث الطلب',
				'order received': 'تم استلام الطلب',
				'order is in process, no changes can be made at this time': 'جارِ معالجة الطلب، لا يمكن إجراء أي تغييرات في هذا الوقت',
				'order in process': 'جارِ معالجة الطلب',
				'order ready': 'الطلب جاهز',
				'in process': 'جارِ المعالجة',
				'ready': 'جاهز',
				'need help': 'تحتاج إلى مساعدة',
				'edit your order': 'تعديل طلبك',
				'your order': 'طلبك',
				'has been updated': 'تم تحديثه',
				'please check your email for order details': 'يُرجى التحقق من بريدك الإلكتروني لمعرفة تفاصيل طلبك',
				'thank you': 'شكرًا لك',
				'we have started collecting your original order.': 'بدأنا في جمع طلبك الأصلي',
				'we have started collecting your order.': 'بدأنا في جمع طلبك',
				'we have finished collecting your original order.': 'انتهينا من جمع طلبك الأصلي',
				'this is a canceled order.': 'هذا الطلب ملغي',
				'we have finished collecting your order.': 'انتهينا من جمع طلبك',
				'your order can no longer be changed': 'لم يعد من الممكن تعديل طلبك',
				'your order cannot be changed at this time': 'لم يعد من الممكن تعديل طلبك',
				'cancel update': 'إلغاء التحديث',
				'canceling': 'جارِ الإلغاء',
				'update': 'تحديث',
				'replace shopping cart': 'استبدال عربة التسوق',
				'are you sure you want to replace your shopping cart with the order from': 'هل أنت متأكد أنك تريد استبدال عربة التسوق الخاصة بك بالطلب من',
				'replace': 'استبدال',
				'replace_2': 'استبدال',
				'you can still edit your order': 'لا يزال بإمكانك تعديل طلبك',
				'your original order is': 'طلبك الأصلي',
				'cancel order update': 'إلغاء تحديث الطلب',
				'in process ': 'طلبكم الأصلي',
				'continue as new cart': 'متابعة بعربة جديدة',
				'add your credit card': 'أضف بطاقة الائتمان الخاصة بك',
				'add your credit/debit card': 'أضف بطاقات الائتمان / الخصم الخاصة بك',
				'use my delivery address as my billing address*': 'اجعل عنوان التوصيل الخاص بي هو عنوان السداد الخاص بي*',
				'*by checking this option you approve transfer of your address data to the payment provider': 'عند تحديدكم لهذا الخيار، فإنّكم توافقون على نقل بيانات عنوانكم إلى مزود خدمة الدفع',
				'you can move the selected items into a new order': 'يمكنك نقل العناصر المحددة إلى طلبك الجديد',
				'paypal': 'paypal',
				'icreditmasterpass': 'Master pass',
				'cost': 'التكلفة',
				'please wait': 'يُرجى الانتظار',
				'checking your address': 'جارِ التحقق من عنوانك',
				'missing details': 'تفاصيل مفقودة',
				'the server check your address': 'يتحقق الخادم من عنوانك',
				'please enter your delivery address in order to see available delivery times': 'يُرجى إدخال عنوان التوصيل الخاص بك لمعرفة أوقات التوصيل المتاحة',
				'your cart is empty': 'عربتك خالية',
				'housenum: max error': 'يُرجى إدخال رقم أصغر من 3000',
				'personalid: required error': 'يجرى إدخال رقم بطاقة هويتكم',
				'customerid: required error': 'يُرجى إدخال رقم العميل الخاص بكم',
				'storecredit: required error': 'يُرجى إدخال تفاصيل الدفع الخاصة بكم',
				'special_exp': 'ينتهي في',
				'special_exp_new': 'ينتهي في',
				'close more delivery times': 'غلق المزيد من أوقات التوصيل',
				'en': 'en',
				'categories': 'الفئات',
				'enter specials department': 'أدخل قسم العروض الخاصة',
				'order by': 'ترتيب حسب',
				'start shopping': 'بدء التسوق',
				'choose an order': 'اختر طلب',
				'load more': 'تحميل المزيد',
				'restore': 'استعادة',
				'newsletter': 'النشرة الإخبارية',
				'show more': 'عرض المزيد',
				'show less': 'تقليص',
				'savings': 'الوفورات',
				'membership savings': 'وفورات العضوية',
				'potential membership savings': 'الوفورات الممكنة للعضوية',
				'select delivery date & time': 'حدد تاريخ ووقت التوصيل',
				'select pickup date & time': 'حدد تاريخ ووقت الاستلام',
				'the expiration of these': 'انتهاء صلاحية هذه',
				'sales will not be valid for this chosen time': 'لن تكون صالحة في هذا الوقت المحدد',
				'the special displayed below, will expire on the date you are supposed to receive your order': 'ستنتهي صلاحية العرض الخاص المبين أدناه في التاريخ الذي يُفترض فيه أن تستلم طلبك',
				'these items will be charged at their regular price': 'سيتم احتساب هذه العناصر بسعرها العادي',
				'this product will be charged the regular price, promotion will not be applied': 'سيتم احتساب هذا المنتج بالسعر العادي، ولن يتم تطبيق العرض الترويجي',
				'price of updated cart (est.)': 'سعر العربة المحدثة (تقديري)',
				'price of updated service fee (est.)': 'رسوم الخدمة المحدثة (تقديرية)',
				'welcome': 'مرحبًا',
				'please enter a zip code': 'يُرجى إدخال رمز بريدي',
				'please enter a city': 'يُرجى إدخال مدينة أو بلدة',
				'please enter a delivery address': 'يُرجى إدخال عنوان توصيل',
				'verify_1': 'حظر البيع',
				'verify_2': 'للقاصرين',
				'verify_3': 'بيع هذا المنتج لمن لم يبلغ سن',
				'verify_4': '',
				'verify_5': 'محظور بتاتًا',
				'verify_6': '',
				'verify_7': 'بلغت',
				'verify_8': 'سن',
				'verify_9': 'لم أبلغ',
				'years': 'سنوات',
				'sorry_2': 'نعتذر',
				'you cannot purchase': 'لا يمكنك الشراء',
				'this item': 'هذا العنصر',
				'login_tab_title': 'تسجيل دخول إلى الموقع',
				'register_tab_title': 'التسجيل للموقع',
				'club member name': 'اسم عضو النادي',
				'our top picks': 'أفضل المنتجات',
				'got it': 'ابدأ الشراء',
				'friend\'s email': 'البريد الإلكتروني للصديق',
				'or share your promo code': 'أو قدم رمز العرض الخاص بك',
				'copy': 'نسخ',
				'send invitation': 'إرسال دعوة',
				'invitation sent successfully': 'تم إرسال الدعوة بنجاح',
				'congratulations': 'خاصه بالنسبة لك',
				'shop now': 'تسوق الآن',
				'shop': 'تسوق',
				'your order is being edited by another device': 'تم تعديل طلبك من جهاز آخر',
				'main menu': 'القائمة الرئيسية',
				'invalid cart id': 'معرف السلة غير صحيح',
				'loyalty club member': 'عضو في النادي',
				'in this purchase': 'في عملية الشراء هذه',
				'save_1': 'وفروا',
				'not a member yet? join now!': 'لست عضوًا بعد؟ انضموا ووفروا!',
				'continue without loyalty': 'متابعة بدون عضوية',

				/*pele card errors*/
				'invalid card': 'بطاقة غير صالحة',
				'permitted transaction.': 'معاملة مصرح بها',
				'the card is blocked, confiscate it.': 'البطاقة محظورة، سنقوم بمصادرتها.',
				'the card is stolen, confiscate it.': 'البطاقة مسروقة، سنقوم بمصادرتها.',
				'contact the credit company.': 'اتصل بشركة الائتمان',
				'refusal by credit company.': 'الرفض من جانب شركة الائتمان',
				'the card is forged, confiscate it.': 'البطاقة مزورة، سنقوم بمصادرتها',
				'incorrect cvv/id.': 'رقم بطاقة هوية و/أو رقم ال-CVV لا يتطابق مع رقم بطاقة الائتمان.' /*"يجب الاتصال بشركة الائتمان."*/,
				'an error occurred while building access key for blocked card files.': 'وقع خطأ ما أثناء إنشاء مفتاح وصول لملفات البطاقة المحظورة',
				'no communication. please try again or contact system administration': ' لا يوجد اتصال، يُرجى المحاولة مرة أخرى أو الاتصال بإدارة النظام.',
				'the program was stopped by user`s command (esc) or com port can\'t be open (windows)': 'تم إيقاف البرنامج بأمر المشغل (ESC) أو من المستخدم أو COM PORT لا يمكن فتحهCOM ows (WINDOWS).',
				'the acquirer is not authorized for foreign currency transactions': 'غير مصرح للمشتري بإجراء معاملات صرف عملات',
				'this card is not permitted for foreign currency transactions': 'غير مصرح لهذه البطاقة بإجراء معاملات صرف عملات',
				'this card is not supported.': 'هذه البطاقة غير مدعومة',
				'track 2 (magnetic) does not match the typed data.': 'لا يوجد تطابق بين الشريط المغناطيسي والرقم المدخل',
				'additional required data was entered/not entered as opposed to terminal settings (z field).': 'تم إدخال / لم يتم إدخال البيانات الإضافية المطلوبة على عكس إعدادات الجهاز',
				'last 4 digits were not entered (w field).': 'لم يتم إدخال آخر 4 أرقام ',
				'entry in int_in file is shorter than 16 characters.': 'الإدخال في الحقل INT_IN أقصر من 16 حرفًا.',
				'the input file (int_in) does not exist.': 'ملف الإدخال (IN_INT) غير موجود.',
				'blocked cards file (neg) does not exist or has not been updated, transmit or request authorization for each transaction.': 'ملف البطاقة المحظورة (NEG) غير موجود أو لم يتم تحديثه، أرسل أو اطلب تصريحًا للمعاملة',
				'one of the parameter files/vectors does not exist.': 'أحد ملفات المعلمات / المتجهات غير موجود',
				'date file (data) does not exist.': 'ملف البيانات (DATA) غير موجود.',
				'format file (start) does not exist.': 'ملف التنسيق (START) غير موجود.',
				'the difference in days in the blocked cards input is too large, transmit or request authorization for each transaction.': 'الفرق في الأيام في مدخلات البطاقات المحظورة كبير جدًا، أرسل أو اطلب تصريحًا للمعاملة.',
				'the difference in generations in the blocked cards input is too large, transmit or request authorization for each transaction.': 'الفرق في الأجيال في مدخلات البطاقات المحظورة كبير جدًا، أرسل أو اطلب تصريحًا للمعاملة.',
				'when the magnetic strip is not completely entered, define the transaction as a telephone number or signature only.': 'عند عدم إدخال الشريط المغناطيسي بالكامل، حدد المعاملة كرقم هاتف أو توقيع فقط.',
				'the central terminal number was not entered into the defined main supplier terminal.': 'لم يتم إدخال رقم الجهاز المركزي في جهاز المورد الرئيسي.',
				'the beneficiary number was not entered into the defined main beneficiary terminal.': 'لم يتم إدخال رقم المستفيد في الجهاز المحدد للمستفيد الرئيسي.',
				'the supplier/beneficiary number was entered, however the terminal was not updated as the main supplier/beneficiary.': 'تم إدخال رقم المورد / المستفيد، ولكن لم يتم تحديث الجهاز كمورد / مستفيد رئيسي.',
				'the beneficiary number was entered, however the terminal was updated as the main supplier': 'تم إدخال رقم المستفيد، ولكن لم يتم تحديث الجهاز كمورد رئيسي',
				'old transactions, transmit or request authorization for each transaction.': 'معاملات قديمة، أرسل أو اطلب تصريحًا للمعاملة.',
				'defective card.': 'خطأ في إدخال بطاقة الائتمان، يُرجى المحاولة مجددًا.' /*"بطاقة غير صالحة."*/,
				'this card is not permitted for this terminal or is not authorized for this type of transaction.': 'هذه البطاقة غير مصرح باستخدامها في هذا الجهاز أو غير مصرح لها بإجراء هذا النوع من المعاملات.',
				'this card is not permitted for this transaction or type of credit.': 'هذه البطاقة غير مصرح لها بإجراء هذه المعاملة مع هذا النوع من الائتمان.',
				'expired card.': 'بطاقة منتهية الصلاحية',
				'expired token': 'رمز منتهي الصلاحية',
				'installment error, the amount of transactions needs to be equal to: first installment plus fixed installments times number of installments.': 'خطأ في الدفع، يجب أن يكون مبلغ المعاملات مساويًا للدفعة الأولى زائد الدفعات الثابتة مضروبةً في عدد المدفوعات.',
				'unable to execute a debit transaction that is higher than the credit card`s ceiling.': 'لا يمكن إجراء معاملة خصم أعلى من سقف بطاقة الائتمان.',
				'incorrect control number.': 'خطأ في إدخال بطاقة الائتمان، يُرجى المحاولة مجددًا.' /*"رقم تحكم غير صحيح."*/,
				'the beneficiary and supplier numbers were entered, however the terminal is defined as main.': 'الجهاز محدد كمتعدد المستفيدين، أدخل رقم المورد.',
				'the transaction`s amount exceeds the ceiling when the input file contains j1, j2 or j3 (contact prohibited).': ' يتجاوز مبلغ المعاملة الحد الأقصى عندما يحتوي ملف الإدخال على j1 أو j2 أو j3 (الاتصال محظور).',
				'the card is blocked for the supplier where input file contains j1, j2 or j3 (contact prohibited).': 'يتم حظر البطاقة للمورد عندما يحتوي ملف المدخلات على j1 أو j2 أو j3 (الاتصال محظور).',
				'random where input file contains j1 (contact prohibited).': 'يكون عشوائيًا عندما يحتوي ملف المدخلات على j1 (الاتصال محظور).',
				'the terminal is prohibited from requesting authorization without transaction (j5).': 'الجهاز ممنوع من طلب تصريح بدون معاملة (j5).',
				'the terminal is prohibited for supplier-initiated authorization request (j6).': 'الجهاز ممنوع من طلب تصريح من المورد (j6).',
				'the terminal must request authorization where the input file contains j1, j2 or j3 (contact prohibited).': 'يجب أن يطلب الجهاز تصريحًا عندما يحتوي ملف المدخلات على j1 أو j2 أو j3 (الاتصال محظور).',
				'secret code must be entered where input file contains j1, j2 or j3 (contact prohibited).': 'يجب إدخال الرمز السري عندما يحتوي ملف المدخلات على j1 أو j2 أو j3 (الاتصال محظور).',
				'incorrect vehicle number.': 'رقم المركبة غير صحيح.',
				'the number of the distance meter was not entered.': 'لم يتم إدخال رقم عداد المسافات.',
				'the terminal is not defined as gas station (petrol card or incorrect transaction code was used).': 'لم يتم تحديد الجهاز على أنه محطة غاز (تم استخدام بطاقة وقود أو رمز معاملة غير صحيح).',
				'an id number is required (for israeli cards only) but was not entered.': 'لم يتم إدخال رقم بطاقة الهوية.',
				'cvv is required but was not entered.': 'لم يتم إدخال cvv',
				'cvv and id number are required (for israeli cards only) but were not entered.': 'لم يتم إدخال cvv ورقم بطاقة الهوية.',
				'abs attachment was not found at the beginning of the input data in memory.': 'لم يتم العثور على مرفق ABS في بداية بيانات المدخلات في الذاكرة.',
				'the card number was either not found or found twice.': 'رقم البطاقة إما لم يتم العثور عليه أو تم العثور عليه مرتين.',
				'incorrect transaction type.': 'نوع المعاملة غير صحيح.',
				'incorrect transaction code.': 'رمز المعاملة غير صحيح.',
				'incorrect credit type.': 'نوع الائتمان غير صحيح.',
				'incorrect currency.': 'العملة غير صحيحة.',
				'the first installment and/or fixed payment are for non-installment type of credit.': 'هناك دفعة أولى و/أو دفعة ثابتة لنوع ائتمان غير مقسط.',
				'number of installments exist for the type of credit that does not require this.': 'يوجد عدد من الأقساط لنوع الائتمان الذي لا يتطلب ذلك.',
				'linkage to dollar or index is possible only for installment credit.': 'لا يمكن ربط الدولار أو المؤشر بائتمان مختلف عن الدفعات.',
				'the magnetic strip is too short.': 'طول الشريط المغناطيسي قصير جدًا.',
				'the pin code device is not defined.': 'لم يتم تعريف جهاز لإدخال رمز التعريف الشخصي.',
				'must enter the pin code number.': 'يجب إدخال رمز التعريف الشخصي.',
				'smart card reader not available - use the magnetic reader.': 'قارئ البطاقات الذكية غير متاح - استخدم القارئ المغناطيسي.',
				'must use the smart card reader.': 'يجب استخدام قارئ البطاقات الذكية.',
				'denied - locked card.': 'عملية مرفوضة - البطاقة مقفلة.',
				'denied - smart card reader action didn\'t end in the correct time.': 'عملية مرفوضة - لم ينته إجراء قارئ البطاقات الذكية في الوقت المناسب.',
				'denied - data from smart card reader not defined in system.': 'عملية مرفوضة - لم يتم تعريف قارئ البطاقات الذكية في النظام.',
				'incorrect pin code.': 'رمز التعريف الشخصي غير صحيح.',
				'currency does not exist in vector 59.': 'العملة غير موجودة في المتجه 59.',
				'the club code entered does not match the credit type.': 'رمز النادي الذي تم إدخاله لا يتطابق مع نوع الائتمان.',
				'cannot cancel charge transaction. please create a credit transaction.': 'لا يمكن إلغاء معاملة الرسوم. يُرجى إنشاء معاملة ائتمان.',
				'unable to read/write/open the tran file.': 'لا يمكن قراءة / كتابة / فتح ملف TRAN.',
				'no authorization from credit company for clearance.': 'البطاقة التي تم إدخالها غير معتمدة من قبل المصلحة التجارية.' /* لا يوجد تصريح من شركة الائتمان للتسوية."*/,
				'the terminal is not permitted to send queries for immediate debit cards.': 'غير مصرح للجهاز بإرسال استفسارات بشأن الخصم الفوري.',
				'the transaction amount is too large, divide it into a number of transactions.': 'قيمة المعاملة كبيرة جدًا، قسّمها على عدد من المعاملات.',
				'the terminal is not authorized to execute forced transactions.': 'غير مصرح للجهاز بتنفيذ معاملات قسرية.',
				'the terminal is not authorized for cards with service code 587.': 'غير مصرح للجهاز بالتعامل مع بطاقات برمز خدمة 587.',
				'the terminal is not authorized for immediate debit cards.': 'غير مصرح للجهاز بالتعامل مع بطاقات الخصم الفوري.',
				'the terminal is not authorized for installment transactions.': 'غير مصرح للجهاز بتنفيذ معاملات دفع بأقساط.',
				'the terminal is authorized for installment transactions only, not telephone/signature.': 'غير مصرح للجهاز بتنفيذ معاملات هاتفية / توقيع إلا بأقساط.',
				'the terminal is not authorized for telephone transactions.': 'غير مصرح للجهاز بتنفيذ معاملات هاتفية.',
				'the terminal is not authorized for signature-only transactions.': 'غير مصرح للجهاز بتنفيذ معاملات "التوقيع فقط"',
				'the terminal is not authorized for foreign currency transactions, or transaction is not authorized.': 'غير مصرح للجهاز بتنفيذ معاملات بالدولار',
				'the terminal is not authorized for club transactions.': 'غير مصرح للجهاز بتنفيذ معاملات النادي.',
				'the terminal is not authorized for star/point/mile transactions.': 'غير مصرح للمحطة بتنفيذ معاملات نجوم / نقاط / أميال.',
				'the terminal is not authorized for isracredit credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات إيسراكريديت.',
				'the terminal is not authorized for amex credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات أميكس.',
				'the terminal is not authorized for dollar linkage.': 'غير مصرح للجهاز بربط الدولار.',
				'the terminal is not authorized for index linkage.': 'غير مصرح للجهاز بربط المؤشرات.',
				'the terminal is not authorized for index linkage with foreign cards.': 'غير مصرح للجهاز بربط المؤشرات ببطاقات أجنبية.',
				'the terminal is not authorized for star/point/mile transactions for this type of credit.': 'غير مصرح للمحطة بتنفيذ معاملات نجوم / نقاط / أميال لهذا النوع من الائتمان.',
				'the terminal is not authorized for isra 36 credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات يسرا 36.',
				'the terminal is not authorized for amex 36 credit.': 'غير مصرح للجهاز بالتعامل مع بطاقات أميكس 36.',
				'the terminal is not authorized for this club code.': 'غير مصرح للجهاز بالتعامل مع رمز النادي هذا.',
				'the terminal is not authorized for immediate debit transactions (except for immediate debit cards).': 'غير مصرح للجهاز بتنفيذ معاملات الخصم الفوري (باستثناء بطاقات الخصم الفوري).',
				'the terminal is not authorized to accept visa card staring with 3.': 'غير مصرح للجهاز بقبول بطاقات فيزا تبدأ برقم 3.',
				'the terminal is not authorized to execute credit transactions above the ceiling.': 'غير مصرح للجهاز بتنفيذ معاملات ائتمان أعلى من الحمد الأقصى.',
				'the card is not permitted to execute club transactions.': 'غير مصرح للبطاقة بتنفيذ معاملات النادي.',
				'the card is not permitted to execute star/point/mile transactions.': 'غير مصرح للبطاقة بتنفيذ معاملات النجوم / النقاط / الأميال.',
				'the card is not permitted to execute dollar transactions (regular or telephone).': 'غير مصرح للبطاقة بتنفيذ معاملات الدولار (العادية أو الهاتفية).',
				'the card is not valid according to isracard`s list of valid cards.': 'بطاقة غير صالحة وفقًا لقائمة إيسراكارد للبطاقات الصالحة.',
				'defective card according to system definitions (isracard vector1), error in the number of figures on the card.': 'بطاقة معيبة وفقًا لتعريفات النظام (VECTOR1 إيسراكارد)، خطأ في عدد الخانات في البطاقة.',
				'the card is not permitted to execute dollar transactions according to system definitions (isracard vector1).': 'غير مصرح للبطاقة بتنفيذ معاملات بالدولار وفقًا لتعريفات النظام (إVECTOR1 إيسراكارد).',
				'the card belongs to a group that is not permitted to execute transactions according to system definitions (visa vector 20).': 'تنتمي البطاقة لمجموعة غير مصرح لها بتنفيذ معاملات وفقًا لتعريفات النظام (VECTOR20 فيزا).',
				'the card`s prefix (7 figures) is invalid according to system definitions (diners vector21).': 'بادئة البطاقة (7 أرقام) غير صالحة وفقًا لتعريفات النظام (21VECTOR التابعة لداينرز).',
				'the card is not permitted to carry out installment transactions according to isracard`s list of valid cards.': 'غير مصرح للبطاقة بتنفيذ معاملات دفع وفقًا لقائمة إيسراكارد للبطاقات الصالحة.',
				'the number of installments is too large according to isracard`s list of valid cards.': 'عدد المدفوعات طويل جدًا وفقًا لقائمة إيسراكارد للبطاقات الصالحة.',
				'visa and diners cards are not permitted for club installment transactions.': 'غير مصرح لبطاقات فيزا وداينرز بتنفيذ معلومات مدفوعات النادي.',
				'series of cards are not valid according to system definition (isracard vector5).': 'سلسلة البطاقات غير صالحة وفقًا لتعريف النظام (VECTOR5 إيسراكارد).',
				'invalid service code according to system definitions (isracard vector6).': 'رمز الخدمة غير صالح وفقًا لتعريفات النظام (VECTOR6 إيسراكارد).',
				'the card`s prefix (2 figures) is invalid according to system definitions (isracard vector7).': 'بادئة البطاقة (رقمان) غير صالحة وفقًا لتعريفات النظام (VECTOR6 إيسراكارد).',
				'invalid service code according to system definitions (visa vector12).': 'رمز الخدمة غير صالح وفقًا لتعريفات النظام (VECTOR12 فيزا).',
				'invalid service code according to system definitions (visa vector13).': 'رمز الخدمة غير صالح وفقًا لتعريفات النظام (VECTO13 فيزا).',
				'immediate debit card is prohibited for executing credit transaction.': 'بطاقات الخصم الفوري ممنوعة من تنفيذ معاملات ائتمانية.',
				'the card is not permitted to execute installment transactions according to alpha vector no. 31.': 'غير مصرح للبطاقة بتنفيذ معاملات دفع وفقًا لألفا فيكتور 31.',
				'the card is not permitted for telephone and signature-only transactions according to alpha vector no. 31.': 'غير مصرح للبطاقة بتنفيذ المعاملات الهاتفية ومعاملات التوقيع فقط وفقًا لألفا فيكتور 31.',
				'the card is not permitted for telephone transactions according to alpha vector no. 31.': 'غير مصرح للبطاقة بتنفيذ معاملات هاتفية وفقًا لألفا فيكتور 31.',
				'credit is not approved for immediate debit cards.': 'غير مصرح بالائتمان لبطاقات الخصم الفوري.',
				'credit is not approved for foreign cards.': 'غير مصرح بالائتمان للبطاقات الأجنبية.',
				'incorrect club code.': 'رمز النادي غير صحيح.',
				'the card is not permitted to execute flexible credit transactions (adif/30+) according to system definitions (diners vector21).': 'غير مصرح للبطاقة بتنفيذ معاملات ائتمانية مرنة (أديف / 30+) وفقًا لتعريفات النظام (21VECTOR التابعة لداينرز).',
				'the card is not permitted to execute immediate debit transactions according to system definitions (diners vector21).': 'غير مصرح للبطاقة بتنفيذ معاملات الخصم الفوري وفقًا لتعريفات النظام (21VECTOR التابعة لداينرز).',
				'the payment amount is too low for credit transactions.': 'المبلغ المطلوب دفعه صغير جدًا للمعاملات الائتمانية.',
				'incorrect number of installments for credit transaction.': 'رقم غير صحيح لمدفوعات المعاملات الائتمانية.',
				'zero ceiling for this type of card for regular credit or credit transaction.': 'الحد الأقصى 0 لهذا النوع من البطاقات في الائتمان العادي أو المعاملات الائتمانية.',
				'zero ceiling for this type of card for immediate debit credit transaction.': ' الحد الأقصى 0 لهذا النوع من البطاقات في معاملات بطاقات الخصم الفوري.',
				'zero ceiling for this type of card for immediate debit in dollars.': ' الحد الأقصى 0 لهذا النوع من البطاقات في الخصم الفوري بالدولار.',
				'zero ceiling for this type of card for telephone transaction.': ' الحد الأقصى 0 لهذا النوع من البطاقات في المعاملات الهاتفية.',
				'zero ceiling for this type of card for credit transaction.': ' الحد الأقصى 0 لهذا النوع من البطاقات في المعاملات الائتمانية.',
				'zero ceiling for this type of card for installment transaction.': ' الحد الأقصى 0 لهذا النوع من البطاقات في معاملات الدفع.',
				'american express card issued abroad not permitted for instalments transaction.': 'غير مصرح لبطاقات أمريكان إكسبريس الصادرة في الخارج بتنفيذ معاملات دفع.',
				'jcb cards are only permitted to carry out regular credit transactions.': 'غير مصرح لبطاقات JCB إلا بتنفيذ المعاملات الائتمانية العادية.',
				'the amount in stars/points/miles is higher than the transaction amount.': 'القيمة بالنجوم / النقاط / الأميال أكبر من قيمة المعاملة.',
				'the club card is not within terminal range.': 'بطاقة النادي ليست في حدود نطاق الجهاز.',
				'star/point/mile transactions cannot be executed.': 'لا يمكن تنفيذ معاملات النجوم / النقاط / الأميال بالدولارات.',
				'dollar transactions cannot be executed for this type of card.': 'لا يمكن تنفيذ المعاملات بالدولار لهذا النوع من البطاقات.',
				'credit transactions cannot be executed with other than regular credit.': 'لا يمكن تنفيذ معاملات ائتمانية بائتمان غير عادي.',
				'amount of discount on stars/points/miles is higher than the permitted.': 'قيمة الخصم بالنجوم / النقاط / الأميال أكبر من القيمة المصرح بها.',
				'forced transactions cannot be executed with credit/immediate debit card.': 'لا يمكن تنفيذ معاملات قسرية ببطاقة ائتمان / خصم فوري.',
				'the previous transaction cannot be cancelled (credit transaction or card number are not identical).': 'لا يمكن إلغاء المعاملة السابقة (المعاملة الائتمانية أو رقم البطاقة غير مطابقين).',
				'double transaction.': 'معاملة مزدوجة.',
				'the terminal is not permitted for index linkage of this type of credit.': 'غير مصرح للجهاز بربط المؤشرات لهذا النوع من الائتمان.',
				'the terminal is not permitted for dollar linkage of this type of credit.': 'غير مصرح للجهاز بربط الدولار لهذا النوع من الائتمان.',
				'the card is invalid according to system definitions (isracard vector1).': 'البطاقة غير صالحة وفقًا لتعريفات النظام (إيسراكارد فيكتور 1).',
				'unable to execute the self-service transaction if the gas station does not have self service.': 'لا يمكن تنفيذ معاملة "خدمة ذاتية" إذا لم يكن لدى محطة الوقود خدمة ذاتية\.',
				'credit transactions are forbidden with stars/points/miles.': 'المعاملات الائتمانية ممنوعة بالنجوم / النقاط / الأميال.',
				'dollar credit transactions are forbidden on tourist cards.': 'المعاملات الائتمانية بالدولار ممنوعة بالبطاقات السياحية.',
				'phone transactions are not permitted on club cards.': 'لا يمكن إجراء معاملات هاتفية ببطاقات النادي.',
				'application error.': 'خطأ في التطبيق.',
				'transaction amount missing or zero.': 'مبلغ المعاملة مفقود أو صفر.',
				'no communication to pelecard.': 'لا يوجد اتصال لبطاقة بيلي كارد.',
				'doubled transaction.': 'معاملة مزدوجة.',
				'terminal number does not exist.': 'رقم الجهاز غير موجود.',
				'terminal executes broadcast and/or updating data. please try again later.': 'الجهاز ينفذ إرسال و/أو تحديث بيانات. يُرجى إعادة المحاولة لاحقًا.',
				'user name and/or password not correct. please call support team.': 'اسم المستخدم و/أو كلمة المرور غير صحيحة. يُرجى الاتصال بفريق الدعم.',
				'user password has expired. please contact support team.': 'انتهت صلاحية كلمة مرور المستخدم. يُرجى الاتصال بفريق الدعم.',
				'locked user. please contact support team.': 'مستخدم محظور. يُرجى الاتصال بفريق الدعم.',
				'blocked terminal. please contact account team.': 'جهاز محظور. يُرجى الاتصال بفريق الحسابات.',
				'token number abnormal.': 'رقم الرمز غير صحيح.',
				'user is not authorized in this terminal.': 'غير مصرح للمستخدم باستخدام هذا الجهاز.',
				'validity structure invalid. use mmyy structure only.': 'هيكل الصلاحية غير صحيح. استخدم هيكل MMYY فقط.',
				'ssl verifying access is blocked. please contact support team.': 'تم حظر التحقق من البطاقة الأمنية. يُرجى الاتصال بفريق الدعم.',
				'data not exist.': 'لا توجد بيانات.',
				'general error. please contact support team.': 'خطأ عام. يُرجى الاتصال بفريق الدعم.',
				'necessary values are blocked/wrong.': 'القيم الضرورية مفقودة / غير صحيحة.',
				'general error. repeat action.': 'خطأ عام. أعد المحاولة.',
				'necessary values missing to complete installments transaction': 'القيم الضرورية مفقودة لإتمام معاملة الدفع.',
				/*end pele card errors*/

				'video': 'فيديو',
				'user not found': 'مستخدم أو كلمة مرور غير صحيحة',
				'in {name} dep.': 'في قسم {name}',
				'in specials': 'في العروض الخاصة',
				'coupons': 'القسائم',
				'my coupons': 'قسائمي',
				'no coupons available at this time. check again later or check our specials!': 'لا توجد قسائم متاحة الآن. يُرجى التحقق لاحقًا أو تحقق من عروضنا الخاصة.',
				'giftcards.0: gift card must be up to 255 characters error': 'giftcards.0: خطأ يجب أن تحتوي بطاقة الهدايا على ما يصل إلى 255 حرفًا.',
				'your membership has expired': 'لقد انتهت عضويتك',
				'hello': 'مرحبًا',
				'your club membership has expired on': 'لقد انتهت عضويتك في النادي في',
				'membership number': 'رقم العضوية',
				'renew you membership and enjoy exclusive benefits and discounts': 'جدد عضويتك واستمتع بالمزايا والخصومات الحصرية منذ هذا الشراء',
				'please call customer service for renewal': 'يُرجى الاتصال بخدمة العملاء للتجديد',
				'loyalty registration was not fully completed, registration voucher will be sent by mail': 'لم تكتمل عملية التسجيل في الولاء، سيتم إرسال قسيمة التسجيل عن طريق البريد الإلكتروني',
				'phone number does not match the existing phone number on the system': 'رقم الهاتف غير مطابق لرقم الهاتف الحالي على النظام',
				'pay attention': 'انتبه',
				'enter a valid number': 'أدخل رقم صحيح',
				'paying with different payment method will': 'الدفع بطريقة دفع مختلفة سيؤدي إلى',
				'discard your loyalty club discounts': 'الدفع بوسيلة دفع أخرى يؤدي إلى إلغاء المزايا التي تلقيتها',
				'do you want to continue': 'هل تريد المتابعة',
				'return and pay with loyalty credit card': 'العودة والدفع ببطاقة ائتمان النادي',
				'no thanks, continue shopping': 'تابع التسوق بدون مزايا العضوية',
				'renew membership': 'جدد العضوية في النادي',
				'loyalty membership renewal': 'تجديد عضوية النادي',
				'your membership has been renewed successfully': 'تم تجديد عضويتك بنجاح',
				'you can enjoy from the club benefits in this order': 'يمكنك الاستمتاع بمزايا النادي في هذا الطلب',
				'continue': 'متابعة',
				'loyalty_club_continue': 'متابعة',
				'loyalty card id': 'معرف بطاقة النادي',
				'clip & add': 'إضافة',
				'clip': 'إضافة',
				'personal id': 'رقم الهوية الشخصية',
				'we are not able to register your membership club account': 'لم نتمكن من تسجيل عضويتك في النادي',
				'please make sure your details are correct or try again later': 'يُرجى التأكد من أن معلوماتك صحيحة أو أعد المحاولة لاحقًا',
				'new member': 'عضو جديد',
				'{retailername} membership offers exclusive': 'تمنحك العضوية في نادي {retailername}',
				'promotions in-store and online': 'عروض ترويجية حصرية في المتجر وعلى الإنترنت',
				'join our membership and enjoy exclusive benefits and promotions': 'انضم لنادينا واستمتع بالمزايا والعروض الحصرية',
				'the items in your shopping cart will be cleared if you': 'الانتقال إلى تعديل الطلب',
				'choose to edit your previous order': 'سيزيل جميع العناصر التي أضفتها إلى عربة المشتريات',
				'edit order & clear cart': 'تعديل الطلب وإخلاء العربة',
				'it seems you are editing your order on a different device/browser.': 'يبدو أنك تقوم بتعديل طلبك من جهاز / متصفح مختلف.',
				'it seems you have finished editing your order on a different device/browser.': 'يبدو أنك انتهيت من تعديل طلبك من جهاز / متصفح مختلف.',
				'do you want to add the product to this new order or to the order that is being updated?': 'هل تريد إضافة منتج إلى هذا الطلب الجديد أو إلى الطلب الذي يتم تحديثه؟',
				'note! you in the middle of editing order': 'ملاحظة! أنت في منتصف عملية تعديل الطلب',
				'you in the middle of editing order. changing store is impossible': 'أنت في منتصف عملية تعديل الطلب. لا يمكن تغيير المتجر',
				'note! it seems you have finished editing your order on a different device/browser.': 'ملاحظة! يبدو أنك انتهيت من تعديل طلبك من جهاز / متصفح مختلف.',
				'add to new order': 'أضف إلى طلب جديد',
				'add to updated order': 'أضف إلى طلب محدث',
				'join our membership club': 'انضم إلى النادي',
				'join': 'انضم',
				'join the club': 'انضم إلى النادي',
				'join now': 'انضم الآن',
				'join / connect to club': 'انضم إلى / سجل الدخول إلى النادي',
				'connect / join to club': 'انضم إلى / سجل الدخول إلى النادي',
				'connect to club': 'اتصل بالنادي',
				'this email already exists': 'البريد الإلكتروني هذا موجود بالفعل',
				'unable to create user': 'تعذر إنشاء المستخدم',
				'delivery_type_1_times': 'مواعيد التوصيل',
				'delivery_type_2_times': 'مواعيد الاستلام الذاتي',
				'packed': 'معبأ',
				'frombranch': 'من',
				'special type': 'نوع العرض الخاص',
				'select from variety': 'اختر من بين مجموعة متنوعة',
				'forpackquantity': 'في',
				'pack': 'عبوة توفير',
				'forbidden action': 'عملية غير مصرح بها',
				'internal server error': 'خطأ في الخادم الداخلي',
				'please leave the order outside the door if nobody\'s home': 'يُرجى ترك الطلب خارج الباب إذا لم يكن هناك أحد في المنزل',
				'identify': 'الهوية',
				'show full details in {clubname} club page': 'انظر جميع التفاصيل في صفحة نادي {clubName}',
				'enter your promo code': 'أدخل رمز العرض الترويجي الخاص بك',
				'promo code unidentified': 'لم يتم التعرف على رمز العرض الترويجي',
				'promocode: promo code unidentified error': 'رمز العرض الترويجي: خطأ لم يتم التعرف على رمز العرض الترويجي',
				'promo code': 'رمز العرض الترويجي',
				'for {clubname} full details': 'للحصول على التفاصيل الكاملة عن {clubname}',
				'click here': 'انقر هنا',
				'home phone number': 'رقم هاتف المنزل',
				'work phone number': 'رقم هاتف العمل',
				'mobile phone number': 'رقم الهاتف الجوال',
				'special': 'عرض خاص',
				'check all | clicking the action buttons above as "add to cart" refers to selected items': 'وضع علامة على الكل | يشير النقر على أزرار الإجراءات في الجزء العلوي مثل "أضف إلى العربة" إلى العناصر المحددة',
				'back to previous orders': 'العودة إلى الطلبات السابقة',
				'save changes before leaving page?': 'هل تريد حفظ التغييرات قبل مغادرة الصفحة؟',
				'don\'t save': 'لا تقم بالحفظ',
				'back to my shopping lists': 'العودة إلى قوائم التسوق الخاصة بي',
				'add file': 'إضافة ملف',
				'no attachments': 'لا توجد مرفقات',
				'buy': 'شراء',
				'no purchases have been made yet previous orders will be saved on this screen': 'لم يتم إجراء أي عمليات شراء حتى الآن، سيتم حفظ الطلبات السابقة في هذه الشاشة',
				'no shopping list created yet': 'لم يتم إنشاء أي قائمة تسوق حتى الآن',
				'all departments': 'جميع الأقسام',
				'{retailer} club members can save time and upload their shopping cart from their last visit to the store': 'يمكن لأعضاء نادي {retailer} توفير الوقت وتحميل عربة التسوق الخاصة بهم من زيارتهم الأخيرة إلى المتجر',
				'upload my cart': 'تحميل عربتي',
				'no, thanks': 'لا شكرًا',
				'approve & register': 'الموافقة والتسجيل',
				'not approve': 'عدم الموافقة',
				'i agree to receive promotional content & offers from {company_name} and {hubname}': 'أوافق على استلام محتوى ترويجي وعروض من {company_name} و {hubname}',
				'unfortunately, your cart cannot be uploaded': 'للأسف لا يمكن تحميل عربتك',
				'please try again later or continue shopping': 'يُرجى إعادة المحاولة لاحقًا أو متابعة التسوق',
				'areaquery: spautocompleterequirematch error': 'يُرجى اختيار عنوان من القائمة',
				'areaquery: required error': 'يُرجى اختيار عنوان من القائمة',
				'pickup address': 'عنوان الاستلام',
				'the pickup will be picked from': 'سيتم الاستلام من',
				'additional special\'s variety': 'مجموعة متنوعة من العروض الخاصة',
				'password: invalid password error': 'يجب أن تحوي كلمة المرور الجديدة بين 6 و20 حرفًا',
				'customer service order': 'طلب خدمة العملاء',
				'logo': 'الشعار',
				'copy completed': 'تم النسخ',
				'edit comment': 'تعديل التعليق',
				'filters': 'أيقونة فلاتر',
				'close filters': 'غلق الفلاتر',
				'cant find item image': 'لم نتمكن من العثور على صورة المنتج',
				'plus': 'زائد',
				'minus': 'ناقص',
				'open recipe': 'افتح الوصفة',
				'banner': 'اللافتة',
				'play video': 'تشغيل الفيديو',
				'remove from cart': 'الإزالة من العربة',
				'express delivery': 'التوصيل السريع',
				'express pickup': 'الاستلام السريع',
				'paypal logo': 'شعار PayPal',
				'masterpass logo': 'شعار MasterPass',
				'language': 'اللغة',
				'account': 'الحساب',
				'open store info': 'فتح معلومات المتجر',
				'close store info': 'غلق معلومات المتجر',
				'open/close cart': 'فتح / غلق العربة',
				'open ticket': 'فتح التذكرة',
				'close ticket': 'غلق التذكرة',
				'paypal account': ' حساب PayPal',
				'previous product': 'المنتج السابق',
				'next product': 'المنتج التالي',
				'disclaimer': 'إخلاء مسؤولية',
				'the previous items': 'العناصر السابقة',
				'the next items': 'العناصر التالية',
				'open filter options': 'فتح خيارات الفلتر',
				'close filter options': 'غلق خيارات الفلتر',
				'clear': 'مسح',
				'open product page': 'فتح صفحة المنتج',
				'open': 'فتح',
				'close our top picks': 'غلق أفضل المنتجات',
				'clarification': 'توضيح',
				'show category': 'عرض الفئة',
				'show order': 'عرض الطلب',
				'close order': 'غلق الطلب',
				'on credit payment': 'الدفع عن طريق الائتمان',
				'please enter order confirmation number': 'يُرجى إدخال رقم تأكيد الطلب',
				'user not connected to organization': 'المستخدم غير متصل بالمؤسسة',
				'no delivery time slots available': 'لا توجد أوقات توصيل متاحة',
				'no pick up time slots available': 'لا توجد أوقات استلام متاحة',
				'invalid phone number': 'رقم الهاتف غير صحيح',
				'phones - invalid phone number.': 'الهواتف - رقم الهاتف غير صحيح',
				'invalid city': 'المدينة غير صحيحة',
				'invalid address': 'العنوان غير صحيح',
				'invalid street': 'الشارع غير صحيح',
				'invalid address 1': 'شارع & رقم منزل غير صحيح',
				'invalid address 2': 'طابق & شقة غير صحيح',
				'invalid zip code': 'الرمز البريدي غير صحيح',
				'shopping cart': 'عربة التسوق',
				'main manu': 'القائمة الرئيسية',
				'home page banner': 'لافتة الصفحة الرئيسية',
				'promoted specials': 'نوع العرض',
				'filters & sorting': 'الفلاتر والفرز',
				'product list': 'قائمة المنتجات',
				'checkout footer': 'تذييل الخروج',
				'delivery method': 'طريقة التوصيل',
				'delivery method & details': 'طريقة وتفاصيل التوصيل',
				'navigation': 'التنقل',
				'main display': 'الشاشة الرئيسية',
				'shortcuts': 'الاختصارات',
				'filters_region': 'مرشحات المنتجات',
				'languages': 'اللغة',
				'settings': 'الإعدادات',
				'next slide': 'الشريحة التالية',
				'home page link': 'رابط الصفحة الرئيسية',
				'newsletter bubble': 'النشرة الإخبارية',
				'number of items': 'عدد العناصر',
				'cart_total': 'الإجمالي',
				'items_in_cart': 'عناصر',
				'review our last minute recommendations, just before you checkout': 'لحظة قبل الإنهاء. راجع أحدث توصياتنا قبل الخروج',
				'thank you, you are now logged in': 'شكرًا لك، تم تسجيل دخولك بنجاح',
				'thank you, registration completed successfully': 'شكرًا لك، تم الانتهاء من تسجيلك بنجاح',
				'delivery method options': 'خيارات طريقة التوصيل',
				'substitute preference options': 'خيارات تفضيلات الاستبدال',
				'payment method options': 'خيارات طريقة الدفع',
				'add coupon': 'إضافة قسيمة',
				'remove product': 'إزالة منتج',
				'remove coupon': 'إزالة قسيمة',
				'all specials': 'جميع العروض الخاصة',
				'sorry, we do not deliver to your area': 'معذرة، حاليًا نحن لا نقوم بعمليات توصيل إلى منطقتك',
				'please type in your email and we will let you know when we expand to your location': 'يُرجى ترك بريدك الإلكتروني وسنخبرك عندما نتوسع ونصل إلى منطقتك',
				'email address': 'عنوان البريد الإلكتروني',
				'keep in touch': 'ابقوا على اتصال',
				'try a different location': 'جرّب عنوان مختلف',
				'try a different address': 'جرّب عنوان مختلف',
				'try a different city': 'جرّب مدينة مختلفة',
				'try a different zip code': 'جرّب رمز بريدي مختلف',
				'please enter a more accurate address to continue': 'يُرجى إدخال عنوان أكثر دقةً للمتابعة',

				'express delivery is allowed only with products that contain express delivery tag': 'التوصيل السريع غير مسموح به إلا مع المنتجات التي تحتوي على بطاقة التوصيل السريع',
				'this service is available for express items only. removing non express items from your cart will enable using this option': 'هذه الخدمة غير متاحة إلا للعناصر التي تحتوي على بطاقة توصيل سريع فقط. ستسمح إزالة العناصر التي لا تحتوي على بطاقة التوصيل السريع باستخدام هذا الخيار',
				'express delivery products limit exceeded': 'تم تجاوز حد منتجات التوصيل السريع',
				'express delivery is limited to': 'يقتصر التسليم السريع على',
				'items. to enable this service, remove items from your cart': 'عناصر فقط. لتمكين هذه الخدمة، قم بإزالة العناصر من عربتك',

				'apologies': 'اعتذارات',

				'view all': 'عرض الكل',

				'products limit': 'حد المنتجات',
				'this delivery has a limit of {deliveryitemslimit} products': 'هذا التوصيل لديه حد يبلغ {deliveryitemslimit} من المنتجات',
				'limited to {deliveryitemslimit} products': 'يقتصر على {deliveryitemslimit} من المنتجات ',
				'limited to products marked with "{tagname}" tag only': 'يقتصر على المنتجات الموضوع عليها علامة "{tagname}" فقط',
				'has no items limit': 'ليس لديها حد للعناصر',
				'choose the pickup location': 'اختر موقع الاستلام',
				'if you wish to add another product, remove one from the cart first': 'إذا كنت تريد إضافة منتج آخر، قم بإزالة منتج من العربة أولًا',
				'or change the delivery method': 'أو قم بتغيير طريقة التوصيل',
				'change delivery method': 'تغيير طريقة التوصيل',
				'are you sure you want to change the delivery type': 'هل أنت متأكد أنك تريد تغيير نوع التوصيل',
				'changes in delivery times may apply': 'قد يتم إجراء تغييرات على أوقات التوصيل',
				'changes in inventory between the stores may apply': 'قد يكون اختلاف بالمخزون بين المتاجر',
				'yes, change delivery method': 'نعم، قم بتغيير طريقة التوصيل',
				'no, stay in current delivery': 'لا، تابع في طريقة التوصيل الحالية',
				'or select a store for pickup': 'أو حدد متجر للاستلام منه',
				'select a store for pickup': 'تحديد متجر للاستلام منه',
				'choose a convenient time & pickup your order at one of our stores': 'اختر وقتًا مناسبًا واستلم طلبك من أحد متاجرنا',
				'choose': 'اختيار',
				'branch logo': 'أيقونة الفرع',
				'unfortunately we didn\'t find any stores in this area': 'للأسف لم نجد أي متاجر في هذه المنطقة',
				'try a different area name': 'جرّب اسم منطقة أخرى',
				'please enter a valid address': 'يُرجى إدخال عنوان صحيح',
				'please add street and house number': 'يُرجى إضافة الشارع ورقم المنزل',
				'delivery_type_1': 'توصيل',
				'delivery_type_2': 'توصيل سريع',
				'delivery_type_5': 'استلام ذاتي',
				'see you soon': 'نراك قريبًا',
				'the order will wait for you at the store': 'سيكون الطلب في انتظارك في المتجر',
				'from branch': 'من الفرع',
				'chametz': 'حميتز',
				'passoverkosher': 'كوشير عيد الفصح طوال السنة',
				'passoverkoshertemporarily': 'كوشير عيد الفصح مؤقت',
				'passoverkitniyotkosher': 'كوشير عيد الفصح طوال السنة لمن يتناولون البقوليات',
				'passoverkitniyotkoshertemporarily': 'كوشير عيد الفصح مؤقت لمن يتناولون البقوليات',
				'meat': 'كوشير لحم',
				'milky': 'كوشير حليبي',
				'pareve': 'كوشير بارفيه',
				'israelmilk': 'كوشير حليبي إسرائيلي',
				'israelcooking': 'طبخ إسرائيلي',
				'sabbathobservingfactory': 'مصنع لا يعمل السبت',
				'noshviitconcern': 'بدون تخوف شفيعيت',
				'noorlaconcern': 'بدون تخوف عورلا',
				'notevelconcern': 'بدون تخوف تفيل',
				'noovernightconcern': 'حظر أكل ما بات ليلة',
				'nogeniza': 'بدون تخوف جنيزا',
				'recipes and allergies': 'الوصفات والمواد المسببة للحساسية',
				'list of ingredients': 'قائمة المواد المسببة للحساسية',
				'allergies contained': 'المنتج يحتوي على مواد مسببة للحساسية',
				'possible allergies': 'المواد المسببة للحساسية التي قد تكون موجودة',
				'about the product': 'عن المنتج',
				'extra info and instructions': 'المزيد من المعلومات والتعليمات',
				'supervisions': 'الإشراف',
				'usage warnings': 'تحذيرات الاستخدام',
				'storage instructions': 'تعليمات التخزين',
				'usage instructions': 'تعليمات تحضير واستخدام المنتج',
				'fat percentage': 'نسبة الدهون',
				'cream percentage': 'نسبة الكريم',
				'fruit percentage': 'نسبة الفاكهة',
				'alcohol percentage': 'نسبة الكحول',
				'ph': 'الرقم الهيدروجيني (pH)',
				'country of origin': 'بلد المنشأ',
				'date taken into effect': 'تاريخ النفاذ',
				'height': 'الارتفاع',
				'depth': 'العمق',
				'width': 'العرض',
				'net width': 'العرض الصافي',
				'gross width': 'العرض الإجمالي',
				'general information': 'معلومات عامة',
				'diet type': 'نوع النظام الغذائي',
				'the waiting period will not exceed two hours': 'لن تتجاوز فترة الانتظار ساعتين',
				'at_hour': 'في الساعة',
				'special_reminder_bubble_title': 'وفروا',
				'special_reminder_awarded_bubble_title': 'لقد وفرتم!',
				'special_reminder_button_title': 'انقروا ووفروا!',
				'add products to earn this promotion': 'أضف منتجات للحصول على هذا العرض الترويجي',
				'done': 'تم',
				'select your benefit': 'حدد الميزة',
				'more specials': 'المزيد من العروض الخاصة',
				'show more specials': 'عرض المزيد من العروض الخاصة',
				'show less specials': 'عرض عروض خاصة أقل',
				'coupon': 'القسيمة',
				'coupon_requirements_error': 'لا تتوفر بكم شروط القسيمة',
				'remaining credit': 'الرصيد المتبقي',
				'cart amount higher': 'مبلغ العربة أكبر',
				'than your credit': 'من رصيدك',
				'edit cart': 'تعديل العربة',
				'cart total': 'الإجمالي في العربة',
				'to complete the purchase, remove products from the cart valued at': 'لإتمام عملية الشراء، قم بإزالة منتجات من العربة بقيمة',
				'payment by credit': 'الدفع عن طريق الائتمان',
				'this user is not set as credit customer': 'لم يتم تحديد هذا المستخدم على أنه عميل ائتمان',
				'cart amount higher than your credit. to proceed please remove at least': 'مبلغ العربة أكبر من رصيدك. للمتابعة، يُرجى إزالة ما لا يقل عن',
				'valid until': 'صالح حتى',
				'please verify that you are not a robot': 'يُرجى تأكيد أنك لست روبوت',
				'attachment.size - invalid attachment, file bigger then 2mb': 'خطأ: الملف المرفق غير صحيح، الملف أكبر من 2 ميجابايت',
				'attachment.originalname - invalid attachment name': 'خطأ: اسم الملف المرفق غير صحيح',
				'attachment.mimetype - invalid attachment, file type not allowed': 'خطأ: نوع الملف المرفق غير صحيح',
				'all conditions must be marked in order to complete the order': 'يجب وضع علامة على جميع الشروط لإتمام الطلب',
				'employee discount': 'خصم الموظف',
				'card id': 'رقم البطاقة',
				'discount': 'الخصم',
				'remaining obligo': 'الميزانية المتبقية',
				'monthly obligo': 'الميزانية الشهرية',
				'club name': 'اسم النادي',
				'obligo type': 'نوع الميزانية',
				'monthly discount obligo': 'ميزانية الخصم الشهري',
				'cardid: required error': 'رقم البطاقة: خطأ عدم الإدخال',
				'pin: required error': 'يُرجى إدخال رقم Pin',
				'employee discount card holder?': 'هل تحمل بطاقة خصم موظف؟',
				'employee discount card data': 'بيانات بطاقة خصم موظف',
				'employee discount account not found': 'لم يتم العثور على حساب خصم موظف',
				'estimated employee savings': 'التوفيرات المقدرة للموظف',
				'getting data...': 'جارِ الحصول على البيانات...',
				'error': 'خطأ',
				'what\'s your lifestyle?': 'ما هو نمط حياتك؟',
				'filter entire store': 'فلترة المتجر بأكمله',
				'filter the entire store according to your preferred lifestyle or diet!': 'فلترة المتجر بأكمله حسب نمط حياتك المفضل أو نظامك الغذائي!',
				'clear all filters': 'مسح جميع الفلاتر',
				'all filters': 'جميع الفلاتر',
				'filtering store': 'فلترة المتجر',
				'the entire store is now filtered by': 'تمت فلترة المتجر بأكمله حسب',
				'store is filtered': 'تمت فلترة المتجر',
				'back to filters': 'العودة إلى الفلاتر',
				'we couldn\'t find any products': 'لم نتمكن من العثور على أي منتجات',
				'we couldn\'t find any specials': 'لم نتمكن من العثور على أي عروض خاصة',
				'clear_all_filters_2': 'امسحوا جميع الفلاتر',
				'try editing your filters': 'جرّب تعديل فلاترك',
				'permanent': 'دائم',
				'permanent_filters_legal_text': 'عند استخدام خيارات الفلترة المختلفة المعروضة على الموقع، لا تضمن الشركة عدم وقوع أعطال وأخطاء في الموقع بشكل عام وفي إجراء الفلترة بشكل خاص. لا تضمن الشركة أن يكون تصنيف المنتجات إلى فئات دقيقًا وألا يشمل المنتجات التي لا تلبي المعايير المطلوبة. استخدام المعلومات التي تم الحصول عليها على عاتقك يشمل أي ضرر ناتج عن استخدام المعلومات أو الاعتماد عليها. يجب فحص كل منتج، بما في ذلك مكوناته، على حدة. ستكون مسؤولًا عن التحقق من جميع المعلومات مقابل بيانات الشركة المصنعة لهذا المنتج وفي حالة وجود تناقض بين المعلومات المعروضة على الموقع والمعلومات المعروضة من قبل الشركة المصنعة، يجب التطرق للمعلومات المعروضة من قبل الشركة المصنعة. لا يُنشئ ما ورد أعلاه مسؤولية للشركة، سواء عن الأضرار غير المباشرة أو المباشرة، بشأن المعلومات المقدمة من قبل الشركة المصنعة لمنتج أو آخر.',
				'pause': 'إيقاف مؤقت',
				'play': 'تشغيل',
				'required fields are marked with *': 'الحقول المطلوبة محددة بعلامة * *',
				'select language': 'حدد اللغة',
				'retailer data': 'بيانات المتجر',
				'banner link': 'رابط',
				'map': 'الخريطة',

				'verify account': 'تأكيد الحساب',
				'you are now registered': 'تم التسجيل بنجاح',
				'please verify your account': 'يُرجى تأكيد حسابك',
				'before your first purchase, a verification code will be sent to your mobile phone': 'قبل أول عملية شراء لك، سيتم إرسال رمز تحقق إلى هاتفك المحمول',
				'send sms': 'أرسل',
				'enter the code that was sent to your phone': 'أدخل الرمز الذي تم إرساله إلى هاتفك',
				'change number': 'تغيير الرقم',
				'verify': 'تحقق',
				'i didn\'t receive the code (resend)': 'لم أستلم الرمز (أعد الإرسال)',
				'the code is invalid or expired': 'الرمز غير صحيح أو منتهي الصلاحية',
				'your account is verified': 'تم التحقق من حسابك',
				'invalid verification code': 'رمز التحقق غير صحيح',
				'you have successfully registered': 'لقد قمت بالتسجيل بنجاح',
				'for the customer club': 'في نادي العملاء',
				'for the customer club and the website': 'في نادي العملاء والموقع الإلكتروني',
				'loyalty club details': 'تفاصيل حساب نادي الولاء',
				'loyalty_terms_confirm': 'أصادق أنني قرأت',
				'loyalty terms': 'شروط نادي الولاء',
				'website registration': 'التسجيل في الموقع الإلكتروني',
				'join club': 'انضم إلى النادي',
				'loyaltypolicyapproval: required error': 'يُرجى المصادقة على سياسة النادي وشروطه',
				'personalid: minlength error': 'يُرجى إدخال رقم بطاقة هوية بطول 9 أرقام',
				'personalid: maxlength error': 'يُرجى إدخال رقم بطاقة هوية بطول 9 أرقام',
				'personalid: number error': 'يجب إدخال ارقام فقط',
				'please let me know about promotions on site and club': 'يُرجى إخباري بالعروض الترويجية على الموقع والنادي',
				'phonenum: required error': 'يُرجى إدخال رقم الهاتف المحمول',
				'phonenum: pattern error': 'رقم هاتف غير صحيح',
				'organization': 'المؤسسة',
				'organization management': 'إدارة المؤسسة',
				'detach branch': 'فصل الفرع',
				'go to organization': 'انتقل إلى المؤسسة',
				'user added to organization': 'تمت إضافة المستخدم إلى المؤسسة',
				'user removed from organization': 'تمت إزالة المستخدم من المؤسسة',
				'organization created': 'تم إنشاء المؤسسة',
				'organization data changed': 'تم تغيير بيانات المؤسسة',
				'organization activated': 'تم تنشيط المؤسسة',
				'organization deactivated': 'تم تعطيل المؤسسة',
				'organization obligo changed': 'تم تغيير ميزانية المؤسسة',
				'role': 'الدور',
				'remaining budget': 'الميزانية المتبقية',
				'select branch': 'حدد الفرع',
				'users': 'المستخدمون',
				'admin': 'المشرف',
				'max length of phone number is 10': 'الحد الأقصى لطول رقم الهاتف هو 10 أرقام',
				'payment by organization credit': 'الدفع عن طريق رصيد المؤسسة',
				'sorry, your organization account is inactive': 'معذرة، حساب مؤسستك غير نشط',
				'sorry, there are no branches assigned to this organization': 'معذرة، لا توجد فروع مرتبطة بهذه المؤسسة',
				'please contact your organization admin': 'يُرجى الاتصال بمشرف مؤسستك',
				'total assigned': 'الإجمالي المخصص',
				'credit cards can not be added. you are assigned to an organization': 'لا يمكن إضافة بطاقات ائتمان. لقد تم ربطك بمؤسسة',
				'associated branch': 'الفرع المرتبط',
				'budget history': 'تاريخ الميزانية',
				'organization budget': 'ميزانية المؤسسة',
				'remaining organization budget': 'الميزانية المتبقية للمؤسسة',
				'overall budget for users': 'الميزانية الإجمالية للمستخدمين',
				'user': 'المستخدم',
				'changed by user': 'تم تغييرها بواسطة المستخدم',
				'action': 'الإجراء',
				'action sum': 'قيمة الإجراء',
				'remaining user credit': 'الرصيد المتبقي للمستخدم',
				'remaining organization credit': 'الرصيد المتبقي للمؤسسة',
				'user credit update': 'تحديث رصيد المستخدم',
				'credit update': 'تحديث الرصيد',
				'data update': 'تحديث البيانات',
				'set as credit customer': 'تحديد كعميل ائتماني',
				'unset credit customer': 'إزالة التحديد كعميل ائتماني',
				'order canceled in backend': 'تم إلغاء الطلب في الخلفية',
				'order changed in backend': 'تم تغيير الطلب في الخلفية ',
				'order renewed in backend': 'تم تجديد الطلب في الخلفية ',
				'user checkout': 'دفع المستخدم',
				'user order edit': 'تعديل طلب المستخدم',
				'collection app edit': 'تعديل تطبيق التحصيل',
				'collection app canceled': 'تم إلغاء تطبيق التحصيل',
				'collection app finished': 'تم إنهاء تطبيق التحصيل',
				'changed by pos': 'تم التغيير بواسطة الصندوق',
				'order refunded': 'تم رد المدفوعات',
				'remove selection': 'إزالة التحديد',
				'payment cancelled': 'تم إلغاء الدفع',
				'payment expired': 'انتهت صلاحية الدفع',
				'bit logo': 'شعار bit',

				'cancel order': 'إلغاء الطلب',
				'are you sure you want to cancel this order': 'هل أنت متأكد أنك تريد إلغاء هذا الطلب',
				'order #': 'الطلب رقم',
				'keep the order': 'احتفظ بالطلب',
				'your order was cancelled': 'تم إلغاء طلبك',
				'this order will not be charged': 'لن يتم تحصيل رسوم على هذا الطلب',
				'your payment method will not be charged': 'لن يتم تحصيل رسوم من طريقة الدفع الخاصة بك',
				'a confirmation email was sent to {email}': 'تم إرسال رسالة تأكيد عبر البريد الإلكتروني {email}',
				'no available deliveries in the upcoming days': 'لا توجد عمليات توصيل متاحة في الأيام القادمة',
				'home page': 'الصفحة الرئيسية',
				'days': 'الأيام',
				'business days': 'أيام العمل',
				'delivery within': 'التوصيل في غضون',
				'within': 'في غضون',
				'delivery price': 'سعر التوصيل',
				'we will contact you in order to': 'سنتصل بك من أجل',
				'schedule the delivery hours': 'تحديد ساعات التوصيل',
				'remove item from cart': 'إزالة العنصر من العربة',
				'to the selected delivery area we only send products marked with "{tagname}" tag': 'فيما يتعلق بمنطقة التوصيل المحددة، فإننا لا نرسل إلا المنتجات الموضوع عليها علامة "{tagname}"',
				'you can either remove the product from the cart or change the delivery area': 'يمكنك إما إزالة المنتج من العربة أو تغيير منطقة التوصيل',
				'or change the delivery area': 'أو تغيير منطقة التوصيل',
				'ordered': 'تم الطلب',
				'accessibility statement': 'بيان الإتاحة',
				'accessibility arrangements': 'ترتيبات الإتاحة',
				'accessibility_statement_p1': 'نحن في {retailerName} ندرك أهمية الإتاحة في الحيز الرقمي والمادي لذوي الاحتياجات الخاصة ونرى بذلك جزء لا يتجزأ من مفهوم خدمتنا ومسؤوليتنا الاجتماعية. تم إتاحة هذا الموقع بعناية كبيرة وفقًا للمعيار الإسرائيلي 5568 - بمستوى AA، المستوى المطلوب بموجب القانون.',
				'accessibility_statement_p2': 'من الجدير بالذكر أنه على الرغم من جهودنا لإتاحة جميع صفحات الموقع، قد تظهر أجزاء من الموقع الغير متاحة بعد، نحن نواصل جهودنا لتحسين إتاحة الموقع كجزء من التزامنا لتمكين عامة السكان ومنهم ذوي الاحتياجات الخاصة من استخدامها',
				'accessibility_statement_p3': 'تنعكس إتاحة الموقع في المواضيع التالية',
				'accessibility_point_1': 'تم تكييف الموقع للتصفح باستخدام أجهزة كمبيوتر ثابتة ومحمولة والهاتف المحمول',
				'accessibility_point_2': 'يمكن تكبير خط النص بالضغط على مفتاحي "Ctrl" و "+" (كونترول وبلاس) في نفس الوقت. ستؤدي كل ضغطة على زوج المفاتيح هذי إلى زيادة العرض بنسبة 10٪. لتقليص العرض، اضغط على مفاتيح "Ctrl" و "-" في نفس الوقت (كونترول وماينوس)',
				'accessibility_point_3': 'يمكن للمتصفحين الذين يجدون صعوبة في تشغيل الفأرة تصفح الموقع باستخدام لوحة المفاتيح. يؤدي الضغط على مفتاح Tab بشكل متكرر إلى الانتقال بين الروابط والأزرار الموجودة على الصفحة والحقول المختلفة في النموذج. سيؤدي الضغط على Enter إلى تشغيل الرابط / الزر المميز',
				'accessibility_point_4': 'بنية الصفحات دلالية وقياسية (تقسيم إلى عناوين، وسم المناطق، الروابط والصور، إلخ)',
				'accessibility_point_5': 'دعم التقنيات المساعدة - هذا الموقع متاح وهو موقع مكيف أيضًا لاستخدامه من قبل ذوي الاحتياجات الخاصة ويسمح بدعم برنامج قراءة الشاشة. للإتاحة الأمثل على موقعنا نوصي باستخدام قارئ NVDA أو Voice Over.',
				'accessibility_point_6': 'تقسيم هرمي إلى العناوين',
				'accessibility_point_7': 'وضع علامات على الصور والعناصر غير النصية والغير مستخدمة للتزيين فقط',
				'accessibility_point_8': 'تركيز مرئي - يمكن العثور على تركيز أفضل بشكل ملحوظ في مكون الإتاحة، شغلوا خيار التنقل باستخدام لوحة المفاتيح',
				'accessibility_point_9': 'تنبيهات إتاحة حول مشكلات في الحقول بواسطة نموذج الاتصال',
				'accessibility_point_10': '',
				'please enter your house number': 'يُرجى إدخال رقم منزلك',
				'so we can assign you to the right branch': 'لكي نتمكن من ربطك بالفرع المناسب',

				'your products from a website': 'منتجاتك من موقع إلكتروني',
				'inventory availability may vary according to the delivery area': 'قد يختلف توافر المخزون حسب منطقة التوصيل',
				'please note - we could not identify {number}': 'برجاء العلم - لم نتمكن من تحديد {number}',
				'more products': 'المزيد من المنتجات',
				'more product': 'منتج آخر',
				'from the {name} website': 'من الموقع الإلكتروني {name}',
				'out of stock': 'نفد من المخزون',
				'the site is active only for organization users': 'الموقع غير نشط إلا لمستخدمي المؤسسة',
				'please login or register': 'يُرجى تسجيل الدخول أو التسجيل',
				'sorry, this website is for organization users only': 'معذرة، هذا الموقع الإلكتروني مخصص لمستخدمي المؤسسة فقط',
				'you user is not assigned to an organization': 'المستخدم الخاص بك غير مرتبط بمؤسسة',
				'sorry, you can not register with this email address': 'معذرة، لا يمكنك التسجيل بعنوان البريد الإلكتروني هذا',
				'your account has not yet been verified via email - please verify by clicking on the link sent to your email inbox': 'لم يتم التحقق من حسابك عبر البريد الإلكتروني حتى الآن - يُرجى التحقق من خلال النقر على الرابط المرسل إلى بريدك الإلكتروني',
				'send again': 'أعد الإرسال',
				'didn\'t receive an email': 'لم أستلم رسالة بريد إلكتروني',
				'user email address not found': 'لم يتم العثور على عنوان البريد الإلكتروني',
				'a verification email will be sent to your email box. the link is valid for {link_ttl} minutes': 'سيتم إرسال رسالة تحقق إلى بريدك الإلكتروني. سيكون الرابط صالحًا لمدة {link_ttl} دقائق',
				'you account requires activation, email with activation link have been sent to your email. the link is valid for {link_ttl} minutes': 'يجب تنشيط حسابك، لقد تم إرسال رسالة تحتوي على رابط التنشيط إلى بريدك الإلكتروني. سيكون الرابط صالحًا لمدة {link_ttl} دقائق',
                'your account requires activation.': 'يجب تنشيط حسابك.',
                'email with activation link have been sent to your email.': 'لقد تم إرسال رسالة تحتوي على رابط التنشيط إلى بريدك الإلكتروني.',
                'the link is valid for {link_ttl} minutes': 'سيكون الرابط صالحًا لمدة {link_ttl} دقائق',
				'activation code expired': 'انتهت صلاحية رمز التنشيط',
				'website login': 'تسجيل الدخول على الموقع الإلكتروني',
				'send a new link': 'أرسل رابط جديد',
				'sorry, this link has expired': 'معذرة، انتهت صلاحية هذا الرابط',
				'a new link can be sent': 'يمكن إرسال رابط جديد',
				'you have successfully registered for the site': 'لقد قمت بالتسجيل بنجاح في هذا الموقع',
				'this account has already been verified': 'تم التحقق بالفعل من هذا الحساب',
				'you may enter the trade site': 'يمكنك الدخول إلى الموقع التجاري',
				'this account is an organizational account. you may need to verify it similarly from time to time': 'هذا الحساب هو حساب مؤسسة. قد تحتاج إلى التحقق من ذلك بطريقة مماثلة من وقت لآخر',
				'please verify your identity': 'يُرجى التحقق من هويتك',
				'to verify your identity, please enter a phone number': 'للتحقق من هويتك، يُرجى إدخال رقم هاتف',
				'continue to registration': 'متابعة التسجيل',
				'phone number does not match': 'رقم الهاتف غير مطابق',
				'user invitation not found': 'لم يتم العثور على دعوة المستخدم',
				'user already registered': 'المستخدم مسجل بالفعل',
				'phone number hint': 'ملاحظة بخصوص رقم الهاتف',
				'sorry, there was an authentication error': 'معذرة، هناك خطأ في المصادقة',
				'please try again or contact the person in charge of the organization': 'يُرجى إعادة المحاولة أو الاتصال بالشخص المسؤول عن المؤسسة',

				'add users': 'أضف مستخدمين',
				'adding users': 'إضافة مستخدمين',
				'remove users': 'قم بإزالة مستخدمين',
				'remove user': 'قم بإزالة مستخدم',
				'please select a file': 'يُرجى تحديد ملف',
				'search for a user by phone or email': 'ابحث عن مستخدم برقم الهاتف أو البريد الإلكتروني',
				'download csv example': 'تنزيل نموذج csv',
				'uploading a csv file': 'رفع ملف csv',
				'file upload complete. a summary email will be sent to your inbox': 'تم رفع الملف. سيتم إرسال الملخص إلى بريدك الإلكتروني',

				'in order to add users to the organization, a csv file must be uploaded with the users information': 'من أجل إضافة مستخدمين إلى المؤسسة، يجب رفع ملف csv يحتوي على معلومات المستخدمين',
				'once added to the system, an identity verification email will be sent to each of the users': 'بمجرد الإضافة إلى النظام، سيتم إرسال رسالة تحقق من الهوية إلى البريد الإلكتروني لكل مستخدم',
				'only after identity verification, users will be able to complete the registration process for the site': 'لن يتمكن المستخدمون من إتمام عملية التسجيل في هذا الموقع إلا بعد التحقق من الهوية',
				'in order to remove users from the organization, a csv file must be uploaded with the users details': 'لإزالة المستخدمين من المؤسسة، يجب رفع ملف csv يحتوي على معلومات المستخدمين',
				'pay attention! these users will no longer be able to access the organization website': 'انتبه! لن يتمكن هؤلاء المستخدمون بعد الآن من الوصول إلى الموقع الإلكتروني للمؤسسة',
				'the file must contain at least one user with a phone number and email': 'يجب أن يحتوي الملف على مستخدم واحد على الأقل لديه رقم هاتف وبريد إلكتروني',
				'user_verification_status_1': 'لم يتم التحقق منه',
				'user_verification_status_2': 'لم يتم التحقق منه',
				'user_verification_status_3': 'تم التحقق منه',
				'please confirm removing user from the organization': 'يُرجى تأكيد إزالة المستخدم من المؤسسة',
				'user removed from the organization': 'تمت إزالة المستخدم من المؤسسة',
				'order_organization_confirm: required error': 'يُرجى إدخال رقم التأكيد على الطلب',
				'account not verified': 'لم يتم التحقق من الحساب',
				'this account is an organizational account. you account verification has expired': 'هذا الحساب هو حساب مؤسسة. انتهت صلاحية التحقق من حسابك',
				'account verification succeed': 'تم التحقق من الحساب بنجاح',

				'this user may already be on the system': 'قد يكون هذا المستخدم موجودًا بالفعل على النظام',
				'please try to login': 'يُرجى محاولة تسجيل الدخول',

				'user products list not found': 'لم يتم للعثور على قائمة منتجات المستخدم',
				'user products list is disabled for this retailer': 'قائمة منتجات المستخدم معطلة لتاجر التجزئة هذا',
				'my lists': 'قائمتي',
				'my products': 'منتجاتي',
				'my products list': 'قائمة منتجاتي',
				'the products i usually purchase': 'المنتجات التي أشتريها عادة',
				'your time is precious': 'وقتك ثمين',
				'we have prepared a list of your personal products': 'جهّزنا قائمة بمنتجاتك الشخصية',
				'we have prepared a list of products you usually purchase': 'جهّزنا قائمة بمنتجاتك الشخصية',
				'add products from the list': 'أضف منتجات من ألقائمة',
				'in cart': 'في عربة التسوق',

				'we use cookies to improve your experience': 'نستخدم ملفات تعريف الارتباط لتحسين تجربتك',
				'we use cookies or other trackers to facilitate the use of the site, improve the performance and security of the site, personalize the content offered, including personalized advertising content, and compile traffic and navigation statistics. some cookies, other than functional and security cookies, require your consent to be placed.': ' نستخدم ملفات تعريف الارتباط أو غيرها من أدوات التعقب لتسهيل استخدامك لهذا الموقع الإلكتروني وتحسين أدائه وأمانه، ولتخصيص المحتوى المعروض، وهذا يشمل تخصيص محتوى الإعلانات وجمع إحصائيات استخدام الموقع والتنقل عليه. تتطلب بعض ملفات تعريف الارتباط موافقتك على تثبيتها، خلافًا لملفات تعريف الارتباط الوظيفية والمتعلقة بالأمان.',
				'accept': 'اقبل',
				'accept all cookies': 'قبول جميع ملفات تعريف الارتباط',
				'setup my cookies': 'إعداد ملفات تعريف الارتباط',
				'managing cookies': 'إدارة ملفات تعريف الارتباط',
				'these cookies allow you to benefit from the services offered by the site, to optimize its use and personalization (in particular personalization of offers and advertisements) depending on your profile.': 'تسمح لك هذه الملفات بالاستفادة من الخدمات التي يقدمها الموقع الإلكتروني، وتحسين استخدامه وتخصيصه (لا سيما تخصيص العروض والإعلانات) بناء على إعدادات ملفك الشخصي.',
				'marketing': 'التسويق',
				'marketing cookies are used to improve the relevance of the ads you see and to make sure that you do not see the same ad multiple times.': 'تُستخدَم ملفات تعريف ارتباط التسويق لتحسين الإعلانات التي تراها وتضمن ألا ترى الإعلان أكثر من مرة.',
				'additional features': 'الخصائص الإضافية',
				'these cookies make it possible to activate additional features on the site, for example to facilitate your purchases.': 'تتيح هذه الملفات تفعيل الخصائص الإضافية على الموقع الإلكتروني، ومنها تسهيل عمليات الشراء على سبيل المثال.',
				'audience measurement': 'حصر الزائرين',
				'these cookies allow us to obtain navigation statistics on our site. we use this data to identify possible malfunctions of the site, to improve the site, for marketing purposes, and in order to personalize your experience on the site.': 'تتيح لنا هذه الملفات جمع إحصائيات التنقل على موقعنا الإلكتروني. نستخدم هذه البيانات لتحديد أي أعطال بالموقع الإلكتروني، ولتحسين الموقع، ولأغراض التسويق، ولتخصيص تجربتك على الموقع الإلكتروني.',
				'google analytics': 'تحليلات جوجل',
				'these cookies allow us to obtain statistics on the number of visits to our site (e.g., number of visits, most viewed pages, etc.). we use this data to understand our users better, check the performance of our content, get insights, and identify possible malfunctions of the site to make improvements.': 'تسمح لنا هذه الملفات بجمع الإحصائيات المتعلقة بعدد الزيارات في الموقع الإلكتروني (مثل عدد الزيارات، وأكثر الصفحات التي يزورها المستخدمون، إلخ). نستخدم هذه البيانات في تحسين فهمنا للمستخدمين، والتحقق من أداء المحتوى، واستجماع الأفكار، وتحديد الأعطال المحتملة بالموقع الإلكترونية لإدخال التحسينات عليه.',
				'functional (not configurable)': 'ملفات تعريف الارتباط الوظيفية (لا يمكن تكوينها)',
				'these cookies allow you to remember your choices and preferences on your account and to provide features that facilitate the use of the site. they are essential for the proper functioning of the site and are strictly necessary for the provision of a service that you have expressly requested.': 'تسمح هذه الملفات بأن يتذكر الموقع الإلكتروني اختياراتك وتفضيلاتك في حسابك وتقديم الخصائص التي تسهّل استخدام الموقع الإلكتروني. وهي ضرورية لعمل الموقع على النحو الصحيح وضرورية جدًا لتقديم الخدمات التي طلبتَها صراحة.',
				'time slot unavailable': 'الفترة الزمنية غير متاحة',
				'we\'re sorry, this time slot is no longer available. please select a different time': 'معذرةً ، هذه الفترة الزمنية لم تعد متوفرة. يرجى اختيار وقت مختلف.',
				'return to schedule': ' العودة إلى الجدول الزمني',
				'sitemap': 'خريطة الموقع',
				'pages': 'الصفحات',
				'products marked with': 'المنتجات المميزة ب',
				'limited quantity': 'كمية محدودة',
				'tag are limited to': 'العلامة تقتصر على',
				'items only.': 'العناصر فقط.',
				'the {product_count} products listed below will be': ' المدرجة أدناه  {product_count} المنتجات',
				'removed from your cart': 'تم إزالته من عربة التسوق الخاصة بك',
				'out of stock products': 'منتجات غير متوفرة في المخزون',
				'the next items will be removed from your cart': 'سيتم إزالة العناصر القادمة من عربة التسوق',
				'back to cart': 'العودة إلى عربة التسوق',
				'confirm': 'أكد',
				'expired':'منتهية الصلاحية',
				'{product_count} out of stock products will not be supplied': ' منتجات غير متوفر بالمخزون {product_count} لن يتم توفير ',
				'{coupons_count} expired coupons cannot be redeemed': 'قسائم منتهية الصلاحية لا يمكن استعمالها {coupons_count}',
                'are you sure you want to remove all products from your shopping cart?': 'هل أنت متأكد أنك تريد إزالة جميع المنتجات من عربة التسوق الخاصة بك؟',
                'yes, clear cart': 'نعم ، امسح السلة',
                'clear cart?': 'إخلاء العربة',
				'registration attempt has been blocked, please contact support': 'تم حظر محاولة التسجيل ، يرجى الاتصال بالدعم',
				'account has been suspended, please contact support': 'تم تعليق الحساب ، يرجى الاتصال بالدعم',
				'delivery-fee-explain-default': ' .ةيفاضإ تاموصخ يأ وأ ميلستلا تقو/ةقطنم ببسب تارييغتلل عضخي دقو ،جورخلا ليجست دنع يئاهنلا ليصوتلا موسر غلبم ثيدحت عقوتملا نم',
				'delivery_type_1_fee': 'موسر مالتسالل ءاقتلالا',
				'delivery_type_2_fee': 'موسر ميلستلا',
				'includes_delivery_type_1_fee': 'موسر مالتسالل ءاقتلالا لماش',
				'includes_delivery_type_2_fee': 'موسر ميلستلا لماش',
				'not_includes_delivery_fee': '(غير شامل رسوم التوصيل)',

				'your points': 'نقاطك',
				'buy with points': 'شراء بالنقاط',
				'almost expired': 'أوشكت نهاية الصلاحية',
				'coupon clipped': 'تم قص الكوبون',
                'add product': 'أضف منتج',
                'product in cart': 'المنتج في السلة',
                'clip coupon': 'قص الكوبون',
				'clipped externally': 'المستلَم من جهة خارجية',
				'personal coupons': 'القسائم الشخصية',
				'may interest you': 'ربما يعجبكم أيضا',
				'clip for {x} points': 'إضافه مقابل {x} نقطة/نقاط',
				'not enough points': 'نقاط غير كافية',
				'coupon expires today': 'تنتهي صلاحية القسيمة اليوم',
				'coupon expires in {x} days': 'تنتهي صلاحية القسيمة في {x} يوم/أيام',
				'clip for': 'إضافة مقابل',
				'valid': 'صالحة',
				'can be redeemed once': 'يمكن استبدالها مرة واحدة',
				'can be redeemed {x} times': 'يمكن استبدالها {x} مرات',

				'last_minute_recommendations_headline': 'لدينا توصيات مختارة خصيصًا لكِ.',
				'last_minute_recommendations_title_coupons': 'قسائم شراء ستوفر لكِ المال',
				'last_minute_recommendations_title_products_usually': 'ربما تكوني قد نسيتي هذه المنتجات؟',
				'last_minute_recommendations_title_products_complementary': 'ربما يعجبكِ أيضا...',
				'last_minute_recommendations_title_specials_offers': 'عروض خاصة لا تفوتيها',
				'last_minute_recommendations_title_products_offers': 'لا يمكنك تفويت هذه العروض الخاصة',

				'add_units_special': 'برجاء إضافة {remaining} وحدة للحصول على العرض الخاص',
				'add_currency_special': 'برجاء إضافة {remaining} للحصول على العرض الخاص',
				'add_measure_special': 'برجاء إضافة {remaining} {unit} للحصول على العرض الخاص',
				'there are no available items for this promotion': 'لاتوجدعناصرمتاحةلهذاالعرضالترويجي',
				'no_coupons_for_filter': 'معذرة لم نتمكن من العثور على أي قسيمة "{coupon_filter}" ',
				'search coupon': 'ابحث عن قسيمة',

				'add_units_coupon': 'أضف {remaining} المزيد من المنتجات للاستفادة من التخفيض',
				'add_currency_coupon': 'أضف {remaining} المزيد من المنتجات للاستفادة من التخفيض',
				'add_measure_coupon': 'أضف {remaining} {unit} المزيد من المنتجات للاستفادة من التخفيض',
				'add_units_cart_coupon': 'أضف {remaining} من المنتجات',
				'add_unit_cart_coupon': 'أضف منتجًا {remaining}',
				'add_currency_cart_coupon': 'أضف {remaining}',
				'add_measure_cart_coupon': 'أضف {remaining} {unit}',
				'add_units_special_purchase': 'أضف {remaining} آخر',
				'buy_units_total': 'اشْتَرِ {total} من المنتجات',
				'buy_unit_total': 'اشْتَرِ {total} من المنتجات',
				'buy_currency_total': 'اشْتَرِ بـ {total} شيكل',
				'buy_measure_total': 'اشْتَرِ {total} {unit}',

				'suggestions_dialog_header_1': 'توصية للبدائل',
				'suggestions_dialog_header_2': 'يُرجى اختيار بديل من مجموعة منتجاتنا المماثلة',
				'suggestions_dialog_header_3': 'غير متوفر في المخزون',
				'suggestions_dialog_header_4': 'توصية للبدائل:',
				'ok, continue' : 'حسنًا، استمرار',
                'change chosen time': 'تغيير تاريخ التسليم',
				'hover description for cvv': 'رقم التحقّق من البطاقة (CVV) الموجود على بطاقة الائتمان أو بطاقة الخصم المباشر الخاصة بك، وهو عبارة عن رقم مكون من 3 أرقام على بطاقة الائتمان وبطاقة الخصم فيزا وماستر كارد وديسكفر، بينما يوجد رمز رقمي مكون من 4 أرقام على بطاقة الائتمان أو الخصم التي تحمل علامة أمريكان إكسبريس.',
				'enter cvv number': '',
				'select pickup time': 'حدد وقت الاستلام',
				'select delivery time': 'حدد وقت التسليم',
				'please notice, the expiration of these {count} {type} will not be valid for this chosen time': 'يرجى الملاحظة، إن انتهاء صلاحية هذه {count} {type} لن يكون صالحًا لهذا الوقت المحدد',
				'the coupon displayed below, will not be valid for this chosen time': 'الكوبون المعروض أدناه، لن يكون صالحًا لهذا الوقت المحدد',
				'these {count} coupons will not be valid for this chosen time': 'هذه القسائم {count} لن تكون صالحة لهذا الوقت المحدد',
				'please notice, the coupon displayed below, will not be valid for this chosen time': 'يرجى الملاحظة، القسيمة المعروضة أدناه لن تكون صالحة لهذا الوقت المحدد',
				'please notice, the special displayed below, will not be valid for this chosen time': 'يرجى الملاحظة، العرض الخاص المعروض أدناه لن يكون صالحًا لهذا الوقت المحدد',
				'the special displayed below, will not be valid for this chosen time': 'العرض الخاص المعروض أدناه لن يكون صالحًا لهذا الوقت المحدد',
				'price does not include bottle deposit': 'السعر لا يشمل رسم الإيداع النقدي مقابل زجاجة المشروبات المعبأة',
				'price includes bottle deposit': 'السعر يشمل رسم الإيداع النقدي مقابل زجاجة المشروبات المعبأة',
				'gift_card_error_message': 'لم نتمكن من معالجة بطاقة الهدايا الخاصة بك، يرجى المحاولة مرة أخرى أو تجربة بطاقة أخرى',
				'wrong_gift_card_amount_error': 'القيمة تتجاوز قيمة سلة التسوق',
				'amount_exceeds_balance': 'المبلغ يتجاوز الرصيد',
				"sorry, currently we don't deliver to this address for further inquiries call to the customer service.": 'عذرا ، حاليا لا نقوم بالتوصيل إلى هذا العنوان لمزيد من الاستفسارات اتصل بخدمة العملاء',
				'gg_map_dialog_title': 'اختر عنوانًا من الخريطة',
				'gg_map_dialog_hint': 'إنّتا نواجه مشكلة في تحديد موقعك بدقة. يُرجى التأكّد من وضع الدبوس بدقة كبيرة. سيساعدنا هذا في تحديد عنوانك بدقة',
				'gg_map_alert_need_location': 'يلزم تفعيل إذن الوصول إلى موقعك لتتمكن من متابعة اختيار خرائط Google',
				'gg_map_alert_error': 'هناك بعض المشاكل عند عرض خرائط Google',
				'gg_map_alert_unsupport': 'الجغرافية غير مدعومة من قبل هذا المتصفح',
				'autocomplete_adrs_didnt_find': 'لم تجد عنوانك؟',
				'autocomplete_adrs_choose_location': 'اختر موقعك على الخريطة',
				'sorry, your city was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'عذرًا، لم يتم العثور على مدينتك. يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
				'sorry, your address was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'عذرًا، لم يتم العثور على عنوانك. يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
				'sorry, your zip code was not found. please select an address from auto-complete suggestions or pin your address using the google maps option.': 'عذرًا، لم يتم العثور على الرمز البريدي الخاص بك، لذا يُرجى تحديد عنوان من اقتراحات الإكمال التلقائي أو تثبيت عنوانك باستخدام خيار خرائط جوجل.',
				'country of country_of_origin_title': 'بلد المنشأ',
				'origin_country_legal_text': 'يُرجى العلم أنه على الرغم من أن بلد المنشأ المذكور دقيق اعتبارًا من تاريخ الطلب، إلا أن بلد المنشأ الفعلي للمنتج الذي تم تسليمه قد يختلف بناءً على المخزون المتوفر في نقطة الاستلام أثناء التسليم',
				'after package deduction': 'بعد خصم تكاليف التعبئة',
				'country: required error': 'الرجاء إدخال البلد',
				'january': 'يناير',
				'february': 'شهر فبراير',
				'march': 'يمشي',
				'april': 'أبريل',
				'may': 'يمكن',
				'june': 'يونيو',
				'july': 'يوليو',
				'august': 'أغسطس',
				'september': 'سبتمبر',
				'october': 'اكتوبر',
				'november': 'شهر نوفمبر',
				'december': 'ديسمبر',
				'redeemed': 'مسترد',
				'points gained': 'النقاط المكتسبة',
				'source': 'المصدر',
				'when': 'التاريخ',
                'retrieving data. wait a few seconds and try to cut or copy again.': 'هل هذا العنوان صحيح؟ يمكنك تعديله وتحسينه الآن',
                'is this address correct? you can now edit and refine it': 'هل هذا العنوان صحيح؟ يمكنك تعديله وتحسينه الآن',
                'edit your address': 'اكتب عنوانك',
				'searchtext_storename_placeholder': '{اسم المتجر}',
				'contact_us': 'اتصل بنا',
                'how_can_we_help_you_today': 'كيف يمكننا مساعدتك اليوم؟',
                'leave_us_a_message': 'اترك لنا رسالة، وسنتواصل معك قريبًا',
                'fullname': 'الاسم الكامل',
                'phone': 'رقم الهاتف',
                'email': 'البريد الإلكتروني',
                'message': 'كيف يمكننا مساعدتك؟',
                'send': 'إرسال',
                'your_message_has_been_sent': 'تم إرسال رسالتك',
                'thank_you_for_contact_us': 'أشكركم على الاتصال بنا',
				'balance to be paid': 'الرصيد المستحق الدفع',
				'redeem': 'استرداد',
				'please save the voucher or gift card until you receive your order and are charged': 'يرجى حفظ القسيمة أو بطاقة الهدايا حتى تتلقى طلبك ويتم تحصيل رسومه',
				'balance': 'الرصيد',
				'choose gift card/s': 'اختر بطاقة/بطاقات الهدايا',
				'add gift card': 'أضف بطاقة الهدايا',
				'redeem gift card & discount cards': 'لاسترداد بطاقة هدايا',
				'card number': 'رقم البطاقة',
				'please enter the card number to check the balance': 'يُرجى إدخال رقم البطاقة للتحقق من الرصيد',
				'customer support phone number': 'رقم هاتف دعم العملاء',
				'complete_and_proceed_to_checkout': 'أكمل وانتقل إلى الدفع',
				'please notice, the following products are not available on this chosen time': 'يُرجى ملاحظة أن المنتجات التالية غير متوفرة في الوقت الذي تم تحديده',
				'if you continue, they will be removed from your cart': 'إذا فمت بالمتابعة، سيتم إزالتها من سلة التسوق الخاصة بك',
				'verification challenge expired. check the checkbox again.': 'انتهت صلاحية التحقق. حدّد مربّع الاختيار مرة أخرى.',
				'result for “{title}”': 'النتيجة لـ “{title}”',
				'find a product': 'ابحث عن منتج',
				'brand Results:': 'النتائج حسب العلامة التجارية:',
				'please enter cvv to continue': 'الرجاء إدخال CVV للمتابعة',
				'checkout is opened on another screen': 'الدفع مفتوح على شاشة أخرى',
				'please wait, we are loading the products': 'يُرجى الانتظار، نحن نقوم بتحميل المنتجات.',
				'loading coupons, please wait...': 'جاري تحميل القسائم، يُرجى الانتظار...',
                'receipt': 'إيصال',
				'address_form_field_label_city': 'المدينة',
				'address_form_field_label_address': 'العنوان',
				'address_form_field_label_zip_code': 'الرمز البريدي',
				'address_form_field_label_postal_code': 'الرمز البريدي',
				'address_form_field_label_entrance': 'المدخل',
				'address_form_field_label_apartment_number': 'رقم الشقة',
				'address_form_field_label_building_code': 'رمز المبنى',
				'address_form_field_label_state': 'الولاية',
				'address_form_field_label_comments': 'التعليقات',
				'address_form_field_label_apartment_suite_unit': 'شقة/جناح/وحدة',
                'address_form_field_label_providence': 'التدابير',
                'address_form_field_label_more_details': 'المزيد من التفاصيل',
                
                'address_drop_pin_message_1': 'تم تثبيت الموقع بنجاح. عدل إذا لزم الأمر.',
                'address_drop_pin_message_cancel': 'إلغاء العنوان المثبت',

				'autocomplete_city_not_found_non_droppin': 'عذرًا، لم يتم العثور على مدينتك، لذا يُرجى تحديد عنوان من القائمة أو الاتصال بالدعم للحصول على المساعدة.',
				'autocomplete_address_not_found_non_droppin': 'عذرًا، لم يتم العثور على عنوانك، لذا يُرجى تحديد عنوان من القائمة أو الاتصال بالدعم للحصول على المساعدة.',
				'autocomplete_zip_code_not_found_non_droppin': 'عذرًا، لم يتم العثور على الرمز البريدي الخاص بك، لذا يُرجى تحديد عنوان من القائمة أو الاتصال بالدعم للحصول على المساعدة.',	

                'address_form_field_error_city: required error': 'يرجى إدخال المدينة صالح',
                'address_form_field_error_address: required error': 'يرجى إدخال العنوان صالح',
                'address_form_field_error_zip_code: required error': 'يرجى إدخال الرمز البريدي صالح',
                'address_form_field_error_postal_code: required error': 'يرجى إدخال الرمز البريدي صالح',
                'address_form_field_error_entrance: required error': 'يرجى إدخال المدخل صالح',
                'address_form_field_error_apartment_number: required error': 'يرجى إدخال رقم الشقة صالح',
                'address_form_field_error_building_code: required error': 'يرجى إدخال رمز المبنى صالح',
                'address_form_field_error_state: required error': 'يرجى إدخال الولاية صالح',
                'address_form_field_error_comments: required error': 'يرجى إدخال التعليقات صالح',
                'address_form_field_error_apartment_suite_unit: required error': 'يرجى إدخال شقة/جناح/وحدة صالح',
                'address_form_field_error_providence: required error': 'يرجى إدخال التدابير صالح',
                'address_form_field_error_more_details: required error': 'يرجى إدخال المزيد من التفاصيل صالح',

				'api_error_message': 'لقد حدث خطأ ما{error_message} (خطأ {error_code}). يُرجى المحاولة مرة أخرى بعد بضع دقائق، وإذا تكررت المشكلة فاتصل بخدمة العملاء',
				'payment error': 'خطأ الدفع',
				'error received': 'الخطأ المستلم',
				'please check you credit card details or contact your credit card provider': 'برجاء مراجعة تفاصيل بطاق  الائتمان الخاصة بك أو اتصل بمقدِّم بطاقة الائتمان',

				'pickup time:': 'وقت الجلب:',
                'pickup address:': 'عنوان الجلب:',
                'delivery time:': 'وقت التوصيل:',
                'delivery address:': 'عنوان التسليم:',
                'on_day': '',
                'wish_to_change_delivery':'هل ترغب في تغيير وقت التسليم؟',
                'wish_to_change_pickup':'هل ترغب في تغيير وقت الجلب؟',
                'select date & time':'تحديد التاريخ والوقت',
                'confirm update':'برجاء تأكيد التحديث',
                'save & change delivery time':'حفظ وتغيير وقت التسليم',
                'save & change pickup time':'حفظ وتغيير وقت الجلب',
                'back to home page':'العودة إلى الصفحة الرئيسية',
                'your order has been updated':'لقد تم تحديث طلبك',

				'checkout_finish_last_update_time_delivery': 'يمكنك تحديث وقت تسليم طلبك حتى',
				'checkout_finish_last_update_time_pickup': 'يمكنك تحديث وقت جلب طلبك حتى',
				'forgot_sth_want_to_change_delivery': 'هل تريد تغيير تاريخ الاستلام؟',
				'forgot_sth_want_to_change_pickup': 'هل تريد تغيير تاريخ الجلب؟',

				'update_order_popup_title_def': 'يمكنك تحديث عناصر طلبك أو وقت {delivery method}',
				'update_order_popup_order_item_def': 'تحديث عناصر الطلب',
				'update_order_popup_delivery_time_def': 'تحديث وقت الاستلام',
				'update_order_popup_pickup_time_def': 'تحديث وقت الجلب',
				'update_order_popup_cancel_def': 'هل تريد إلغاء طلبك؟ انقر هنا لإلغائه {cancel order}',
				'update_order_popup_lastest_time_def': 'يمكنك تحديث وقت طلبك {delivery method} حتى {time calculate}',
				'update_order_popup_err_cant_update_time': 'تحديث وقت {delivery method} الخاص بك غير متاح',

				'irre_sales_collect_one': 'لن يكون {saleType} هذا صالحًا لهذا الوقت المحدّث وسيتم تحصيله بسعره العادي',
				'irre_sales_collect_many': 'لن تكون {count} {saleType} هذه صالحة لهذا الوقت المحدّث وسيتم تحصيلها بسعرها العادي',
				'irre_sales_checkout_one': 'عند تحديث هذا الطلب، لن يكون {saleType} التالي صالحًا بعد الآن، وسيتم احتساب ثمن المنتج بسعره العادي',
				'irre_sales_checkout_many': 'عند تحديث هذا الطلب، لن يكون {count} {saleType} التالية صالحة بعد الآن، وسيتم احتساب ثمن العناصر بسعرها العادي',
				'irre_sales_oos_one': 'ستتم إزالة المنتجات غير المتوفرة حاليًا من سلة التسوق الخاصة بك.',
				'irre_sales_oos_many': 'ستتم إزالة المنتجات غير المتوفرة حاليًا من سلة التسوق الخاصة بك.',
				'irre_sales_btn_continue': 'حسنًا، التحديث',
				'irre_sales_btn_change_slot': 'حدّث وقت {delivery method} ',
				'irre_sales_btn_cancel_update': 'إلغاء التحديث',
				'thank you for your order!': 'شكرًا لك على طلبك!',

				"add your credit card": "أضف بطاقتك الإئتمانية",
				"payment methods": "طرق الدفع",
				"your credit cards": "بطاقتك الإئتمانية",
				"add a credit card": "أضف بطاقة الإئتمانية",
				"gift cards": "بطاقات الهدايا",
				"add gift cards": "أضف بطاقات الهدايا",
				"choose gift card/s": "اختر بطاقة/بطاقات الهدايا",
				"please enter the card number to check balance": "يُرجى إدخال رقم البطاقة للتحقق من الرصيد",
				"{gift card name} details": "تفاصيل {Gift card name}",
				"on the next screen, you can select your payment amount": "في الشاشة التالية، يمكنك تحديد مبلغ الدفع الخاص بك",
				"balance:": "الرصيد:",
				"please enter amount to pay": "يُرجى إدخال المبلغ المطلوب دفعه",
				"amount exceeds balance": "المبلغ يتجاوز الرصيد",
				"please save the voucher or gift card until you receive your order and are charged.": "يُرجى حفظ القسيمة أو بطاقة الهدايا حتى تتلقى طلبك وتحصيل الرسوم.",
				"remove card": "إزالة البطاقة",
				"see more": "مشاهدة المزيد",
				"show less": "إظهار أقل",
				"amount exceeds shopping cart value": "القيمة تتجاوز قيمة سلة التسوق",
				'delivery_disclaimer_default_text':'يمكن اختيار نوافذ التسليم فقط أثناء الدفع',
				'missing premise or street number': 'فقدان رقم الشارع أو المبنى',
				'amount to pay': 'المبلغ المطلوب دفعه'
			};
		}]);

})(angular, app);
