(function (angular, app) {
    'use strict';

    app.service('Util', [
        '$rootScope', '$state', '$location', '$timeout', '$filter', '$q', '$interpolate', '$injector', '$window',
        'LocalStorage', 'Config', 'Dialog', 'User', 'SP_SERVICES', 'spUnitsConfig', '$sce', 'Api', 'SpDialogUrlManager',
        'ChooseAreaDialog', 'PaymentsService', 'ORDER_STATUS_STAGES', 'RETAILER_STATUSES', 'ROUTE_ACCESS', 'GOOGLE_ADDRESS_TYPES',
        'LOYALTY_CLUB_DRIVERS', 'CATEGORIES_TYPES', 'ORGANIZATION_TYPES', 'DataLayer', 'AdobeAnalytics', 'SP_PAYMENTS', 'ORDER_UPDATE_POLICY', 'Toast', 'TAG_DATE_CONFIGURATION_TYPE', 'DELIVERY_TIMES_TYPES', 'CHARGE_SPECIALS_CALCULATION_TIME', 'LocalStorage','ORDER_STATUSES',
        function ($rootScope, $state, $location, $timeout, $filter, $q, $interpolate, $injector, $window,
                  localStorageService, config, dialog, user, SP_SERVICES, spUnitsConfig, $sce, api, SpDialogUrlManager,
                  ChooseAreaDialog, PaymentsService, ORDER_STATUS_STAGES, RETAILER_STATUSES, ROUTE_ACCESS, GOOGLE_ADDRESS_TYPES,
                  LOYALTY_CLUB_DRIVERS, CATEGORIES_TYPES, ORGANIZATION_TYPES, DataLayer, AdobeAnalytics, SP_PAYMENTS, ORDER_UPDATE_POLICY, toast, TAG_DATE_CONFIGURATION_TYPE, DELIVERY_TIMES_TYPES, CHARGE_SPECIALS_CALCULATION_TIME, LocalStorage, ORDER_STATUSES) {
            var self = this,
                Cart,
                Orders,
                _forceReload = false,
                _translateFilter = $filter('translate'),
                _nameFilter = $filter('name'),
                _regularPrice = $filter('regularPrice'),
                _currencyFilter = $filter('currency'),
                _previousMetaTag = {},
                dateFilter = $filter('date');

            angular.extend(self, {
                addCouponToCart: addCouponToCart,
                getProductUnit: getProductUnit,
                updateMetaByProduct: updateMetaByProduct,
                changeMetaTag: changeMetaTag,
                openProduct: openProduct,
                openRecipeDialog: openRecipeDialog,
                openLoginDialog: openLoginDialog,
                showUserMigrationDialog: showUserMigrationDialog,
                showCommonDialog: showCommonDialog,
                showRetailerMessageDialog: showRetailerMessageDialog,
                showSpecialDetailsDialog: showSpecialDetailsDialog,
                showCouponDetailsDialog: showCouponDetailsDialog,
                isWeightQuantity: isWeightQuantity,
                isCartLineAdded: isCartLineAdded,
                isCartLineWithSale: isCartLineWithSale,
                isIrrelevantSale: isIrrelevantSale,
                isUnitsWeighable: isUnitsWeighable,
                productSoldBy: productSoldBy,
                pushAll: pushAll,
                currentScopeListener: currentScopeListener,
                destroyListeners: currentScopeListener,
                trim: trim,
                getMessagesAlertText: getMessagesAlertText,
                getCurrentStyleProp: getCurrentStyleProp,
                getDocumentScrolledElement: getDocumentScrolledElement,
                getCategory: getCategory,
                setCurrentCategory: setCurrentCategory,
                loadImage: loadImage,
                setFirstErrorInput: setFirstErrorInput,
                setServerErrorToForm: setServerErrorToForm,
                setClientErrorToForm: setClientErrorToForm,
                getTwoCharNum: getTwoCharNum,
                showMultiDomainsOnCartActivity: showMultiDomainsOnCartActivity,
                showLoadCartOnCartActivity: showLoadCartOnCartActivity,
                checkForPopupsOnStart: checkForPopupsOnStart,
                showNoDeliveriesTodayPopup: showNoDeliveriesTodayPopup,
                getActiveLines: getActiveLines,
                removeCookie: removeCookie,
                getRegularPrice: getRegularPrice,
                clearStorage: clearStorage,
                getProductCartLine: getProductCartLine,
                openTemp: openTemp,
                openContactUs: openContactUs,
                showLoyaltyClubDialog: showLoyaltyClubDialog,
                showLoyaltyIFrameDialog: showLoyaltyIFrameDialog,
                showUsersUploadDialog: showUsersUploadDialog,
                showUsersUploadCompleteDialog: showUsersUploadCompleteDialog,
                showLoyaltyRegisterIFrameDialog: showLoyaltyRegisterIFrameDialog,
                filterProductSpecials: filterProductSpecials,
                productHasClubSpecial: productHasClubSpecial,
                trustSrc: trustSrc,
                trustYoutubeSrc: trustYoutubeSrc,
                validateMinimumCost: validateMinimumCost,
                getCashbackLabel: getCashbackLabel,
                validateLoyaltyExpirationDate: validateLoyaltyExpirationDate,
                openPromotionDialog: openPromotionDialog,
                copyToClipboard: copyToClipboard,
                isRetailerPromotionActive: isRetailerPromotionActive,
                isUserLoggedIn: isUserLoggedIn,
                openUserPromotionDialog: openUserPromotionDialog,
                isOrderInStatuses: isOrderInStatuses,
                editOrder: editOrder,
                isOrderEditable: isOrderEditable,
                uniqueSelectorByElement: uniqueSelectorByElement,
                firePromotionEvent: firePromotionEvent,
                divideArray: divideArray,
				showEditOrderWarning: showEditOrderWarning,
                getCategoryActualTypeByProductData: getCategoryActualTypeByProductData,
                showOrganizationInactivePopup: showOrganizationInactivePopup,
                reload: reload,
                loyaltyClubAutoRenew: loyaltyClubAutoRenew,
                loyaltyClubAutoConnect: loyaltyClubAutoConnect,
                showDeliveryNoAvailableSlotsPopup: showDeliveryNoAvailableSlotsPopup,
                createSearchList: createSearchList,
                goToHref: goToHref,
                validateRegisteredOnly: validateRegisteredOnly,
                showCookieWallDialog: showCookieWallDialog,
                showCouponReminderDialog: showCouponReminderDialog,
                getTimeFormat: getTimeFormat,
                getDateFormat: getDateFormat,
                getDateTimeFormat: getDateTimeFormat,
                isProductOutOfStock: isProductOutOfStock,
                isNeedToShowOutOfStockLabel: isNeedToShowOutOfStockLabel,
                openOutOfStockDialog: openOutOfStockDialog,
                openSuggestionsDialog: openSuggestionsDialog,
                openClearCartDialog: openClearCartDialog,
                showEbtErrorDialog: showEbtErrorDialog,
                showPaymentErrorDialog: showPaymentErrorDialog,
                checkAndRemoveErrorMessage: checkAndRemoveErrorMessage,
                editOrderWIthValidationIfOrderEditable: editOrderWIthValidationIfOrderEditable,
                isAdditionalPaymentRequired: isAdditionalPaymentRequired,
                isPaymentAmountRemaining: isPaymentAmountRemaining,
                showEditOrderPaymentChooseDialog: showEditOrderPaymentChooseDialog,
                getCurrentRoute: getCurrentRoute,
                compiledUserAddress: compileUserAddress,
                isSingleItemSpecial: isSingleItemSpecial,
                getCountries: getCountries,
                getCountryAutocompleteOptions: getCountryAutocompleteOptions,
                getAddressFromAutocomplete: getAddressFromAutocomplete,
                checkAddressFields: checkAddressFields,
                removeErrorsFromInputs: removeErrorsFromInputs,
                getAddressByZipCode: getAddressByZipCode,
                checkForDeliveryPickupServiceFee: checkForDeliveryPickupServiceFee,
                getUserAddress: getUserAddress,
                getCountryCode: getCountryCode,
                showCouponDescription: showCouponDescription,
                convertArrayToObject: convertArrayToObject,
                cleanUserAddressObject: cleanUserAddressObject,
                getUserCashbackPoints: getUserCashbackPoints,
                isLoyaltyPremiumPackageEnabled: isLoyaltyPremiumPackageEnabled,
                setGlobalCouponRedemptions: setGlobalCouponRedemptions,
                checkPopupDisabledUrls: checkPopupDisabledUrls,
                setDefaultBranch: setDefaultBranch,
                setReplacements: setReplacements,
                setReplacement: setReplacement,
                setProductIndexPosition: setProductIndexPosition,
                isSellOnDate: isSellOnDate,
                roundNumber: roundNumber,
                sendFailureNotification: sendFailureNotification,
                openEditOrderDialog: openEditOrderDialog,
                checkIsPremiumEditOrderToggled: checkIsPremiumEditOrderToggled,
                calculateLastUpdateOrderDateTime: calculateLastUpdateOrderDateTime,
                getDeliveryTypeText: getDeliveryTypeText,
                checkCanUpdateTimeSlot: checkCanUpdateTimeSlot,
                getTextByLangAndReplaceParams: getTextByLangAndReplaceParams,
                checkIsCalculateCheckoutTime: checkIsCalculateCheckoutTime,
                calculatePostponeUpdateDateTime: calculatePostponeUpdateDateTime,
                _doNotRemoveTemporarilyMissingItems: _doNotRemoveTemporarilyMissingItems,
                isEnableUpdateTimeSlot: isEnableUpdateTimeSlot,
                showFinishUpdateDialog: showFinishUpdateDialog,
                getNewTimeSlotData: getNewTimeSlotData,
                closeUpdateDialog: closeUpdateDialog,
                updateOrderV2backToHomePage: updateOrderV2backToHomePage,
                getTranslatedFullDateTime: getTranslatedFullDateTime,
                isInternalUrl: isInternalUrl,
                showNotEditAbleDialog: showNotEditAbleDialog,
                setFocusForElement: setFocusForElement
            });

			function _isPersonalCouponCode(couponCode) {
				var couponCodeParts;
				if (couponCode.indexOf('-') > -1) {
					couponCodeParts = couponCode.split('-');
				} else {
					couponCodeParts = couponCode.split('_');
				}
				return couponCodeParts.length > 1 && couponCodeParts[0].length > 0 && couponCodeParts[1].length === 8;
			}

            function _connectCouponToUser(couponCode) {
                return api.request({
                    method: 'PATCH',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons/connectCouponToUser',
                    data: {uniqueCode: couponCode}
                }).then(function (products) {
                    if (!products || !products.length) {
                        return _couponNotValidDialog();
                    }
                    return products;
                });
            }

            function _getCouponProduct(couponCode) {
                return api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/products',
                    params: {
                        filters: {
                            must: {
                                term: {
                                    isCoupon: true,
                                    'localBarcode.raw': couponCode
                                }
                            }
                        },
                        from: 0,
                        size: 1
                    }
                }, {
                    fireAndForgot: true
                }).then(function (result) {
                    if (!result ||
                        (result.products[0].localBarcode !== couponCode && result.products[0].barcode !== couponCode) ||
                        !result.products[0].branch.isActive ||
                        !result.products[0].branch.specials ||
                        result.products[0].isGeneralCouponBlocked === true) {
                        return _couponNotValidDialog();
                    }

                    return result.products;
                });
            }

            function setProductIndexPosition(products) {
                var i = 1;
                angular.forEach(products, function(product) {
                    product.indexPosition = i;
                    i++;
                });
            }

            function setReplacements(chunks) {
    
                var productIdMap = {};
                user.getReplacementSuggestions(chunks.map(function(chunk) {
                    // this is currently creating display issues, so disable for now
                    // line.product._suggestions = [{}];
                    productIdMap[chunk.id] = chunk;
                    return chunk.id;
                }), []).then(function(data) {
                    angular.forEach(data, function(product) {
                        var chunk = productIdMap[product.id];
                        if (chunk && product.suggestions && product.suggestions.length) {
                            chunk.showReplacementLabel = true
                            chunk._suggestions = product.suggestions;
                       }
                    });

                })
            }

            function setReplacement(scope) {
                if (scope.product && scope.product.branch && scope.product.branch.isOutOfStock) {
                    user.getReplacementSuggestions([scope.product.id], []).then(function(data) {
                        if (data.length > 0 && data[0].suggestions) {
                            scope.showReplacementLabel = true
                            scope.product._suggestions = data[0].suggestions
                        }
                    })
                }
            }

            function _couponNotValidDialog() {
                showCommonDialog({
                    title: '{{\'Invalid Coupon Code\' | translate}}',
                    content: '<div>{{\'We couldn\\\'t find a matching coupon code\' | translate}}.</div>'
                });
            }

            function addCouponToCart(couponCode) {
                if (!couponCode) {
                    return $q.resolve();
                }

                var isPersonalCoupon = _isPersonalCouponCode(couponCode),
                    metaData;
                return $q.resolve().then(function() {
                    if (!isPersonalCoupon || user.session.userId) {
                        return;
                    }

                    return openLoginDialog();
                }).then(function () {
                    if (isPersonalCoupon && !user.session.userId) {
                        return;
                    }

                    if (isPersonalCoupon) {
                        return _connectCouponToUser(couponCode);
                    }

                    // set the coupon line meta data to -1,
                    // to mark this coupon as a product coupon rather than a personal coupon
                    // tells the server to not select from the RetailerUsersCoupons table
                    metaData = '-1';

                    return _getCouponProduct(couponCode);
                }).then(function(products) {
                    if (!products || !products.length) {
                        return;
                    }

                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Add Coupon'}});
                    return _getCart().addLine({
                        product: products[0],
                        type: SP_SERVICES.CART_LINE_TYPES.COUPON,
                        metaData: metaData,
                        isCouponPriceZero: $rootScope.config.retailer.isCouponPriceZero
                    });
                }).catch(function () {
                    _couponNotValidDialog();
                }).finally(function () {
                });
            }

            function getProductUnit(product, soldBy) {
                soldBy = self.productSoldBy(product, soldBy);
                if (soldBy === $rootScope.PRODUCT_DISPLAY.UNIT.name || (!soldBy && !self.isWeightQuantity(product))) return;
                if (!product.unitResolution || product.unitResolution > 0.2 || $rootScope.defaultWeightUnit.unit.group === 'us') {
                    return $rootScope.defaultWeightUnit.unit;
                }
                //should change to grams in europe, if unit resolution is under 200 gram
                var units = spUnitsConfig.getGroup().units.mass.units;
                for (var i = 0; i < units.length; i++) {
                    if (units[i].size >= 1000 || units.length - 1 === i) {
                        return units[i - 1] || units[0];
                    }
                }
            }

            function updateMetaByProduct(product) {
                if (!product) {
                    $rootScope.MetaTags.productKeywords = undefined;
                    $rootScope.MetaTags.productImage = undefined;
                    $rootScope.MetaTags.productDescription = undefined;
                    $rootScope.MetaTags.productTitle = undefined;
                    $rootScope.MetaTags.productCanonical = undefined;
                    return;
                }

                // set title meta
                var productNames = (_nameFilter(product.names) || {});
                $rootScope.MetaTags.productTitle = productNames.short + ' | ' + (config.retailer.metaTitle || config.retailer.title);
                $rootScope.MetaTags.productCanonical = product.productId && config.retailer.domain ? 'https://' + config.retailer.domain + '?catalogProduct=' + product.productId : '';

                // set extra info
                if (product.family) {
                    $rootScope.MetaTags.familyName = (_nameFilter(product.family.names) || {}).name || null;
                    if (product.family.categoriesPaths && product.family.categoriesPaths[0]) {
                        angular.forEach(product.family.categoriesPaths[0], function (category, key) {
                            var catName = ('subCategory'+ (key + 1));
                            $rootScope.MetaTags[catName] = (_nameFilter(category.names) || null);
                        });

                    }
                }

                AdobeAnalytics.newPageEvent();

                // do not show missing-image
                var imageUrl = (product.image || {}).url;
                $rootScope.MetaTags.productImage = imageUrl && $filter('image')(imageUrl, 'large') || '';

                var productMetaTag = _nameFilter(product.data) || {};
                if (typeof productMetaTag !== "object" || !Object.keys(productMetaTag).length) {
                    $rootScope.MetaTags.productKeywords = (product.keywords || []).toString().replace(/,/g, ', ') || _previousMetaTag.keywords || $rootScope.MetaTags.keywords;
                    $rootScope.MetaTags.productDescription = productNames.long || _previousMetaTag.description || $rootScope.MetaTags.description;
                } else {
                    // update description and keywords meta
                    $rootScope.MetaTags.productKeywords = productMetaTag.keywords || $rootScope.MetaTags.keywords;
                    $rootScope.MetaTags.productDescription = productMetaTag.description || $rootScope.MetaTags.description;
                }
            }

            function changeMetaTag(options) {
                options = angular.extend({
                    product: {},
                    metaName: false,
                    productName: false,
                    updatePreviousMeta: true
                }, options);

                // handle empty meta tag
                if (typeof $rootScope.MetaTags !== 'object' || !Object.keys($rootScope.MetaTags || {}).length) return;

                // handle rollback
                if (typeof options.product !== 'object' || !Object.keys(options.product).length || !options.metaName) {
                    $rootScope.MetaTags[options.metaName] = _previousMetaTag[options.metaName] || $rootScope.MetaTags[options.metaName];
                    _previousMetaTag = {};
                }

                // set meta
                var productMetaTag = _nameFilter(options.product.data) || {};
                if (typeof productMetaTag !== "object" || !Object.keys(productMetaTag).length) return;

                // save the previous meta tags
                if (options.updatePreviousMeta) _previousMetaTag[options.metaName] = $rootScope.MetaTags[options.metaName];

                // update current meta
                $rootScope.MetaTags[options.metaName] = options.productName ? (_nameFilter(options.product.names) || {})[options.productName] : (productMetaTag[options.metaName] || $rootScope.MetaTags[options.metaName]);
            }

            function openProduct(product, itemsData) {
                if (!product || !product.productId) {
                    return SpDialogUrlManager.backClose();
                }
                $rootScope.$emit('productPage',product.id);

                SpDialogUrlManager.saveData('catalogProduct', {
                    product: product,
                    itemsData: itemsData
                });
                return SpDialogUrlManager.setDialogParams({catalogProduct: product.productId});
            }

            function openRecipeDialog(recipe) {
                return SpDialogUrlManager.setDialogParams({recipe: recipe.id, events: recipe.events});
            }

            function firePromotionEvent(adObject, type, focusElementId) {
                var adId = null;

                if(!isNaN(adObject)) { //== Old type backward
                    adId = adObject;
                } else {
                    adObject = adObject || {};
                    adId = adObject.adId || adObject.id;
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_PROMOTION, {promotion: adObject, data: {type: type}});

                if (!adId) {
                    return;
                }

                if (focusElementId) { //for video accessibility support
                    $timeout(function () {
                        var focusElement = document.getElementById(focusElementId);
                        focusElement && focusElement.focus();
                    });
                }

                api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/native-promotion/fire',
                    params: {
                        adId: adId
                    }
                });
            }

            function openLoginDialog(fromLoyaltyDialog) {
                if ($rootScope.config.retailer.externalLoginSettings && $rootScope.config.retailer.externalLoginSettings.isActive) {
                    $rootScope.isLoading = true;
                    SpDialogUrlManager.backClose().then(function() {
                        $timeout(function() {
                            return $rootScope.ExternalRegistrationIFrameDialog.show($rootScope.config.retailer.externalLoginSettings.registrationIframeURL, {retailerId:$rootScope.config.retailer.id});
                        }, 0);
                    });
                    $timeout(function() {
                        if (!$rootScope.iframeIsReady) {
                            sendFailureNotification();
                            return toast.show({
                                timeout: 3000,
                                content: '{{\'iframe source not loaded\'| translate}}'
                            });
                        }
                    }, 5000);
                } else {
                    return SpDialogUrlManager.setDialogParams({
                        loginOrRegister: '1',
                        loginDialogFromLoyalty: fromLoyaltyDialog ? '1' : null
                    });
                }
            }

            function sendFailureNotification() {
                $rootScope.iframeIsReady = true;
                return api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/global/iframe-failure'
                }).then(function (res) {
                });
            }

            function showUserMigrationDialog(email) {
                return SpDialogUrlManager.backClose().then(function() {
                    $timeout(function() {
                        return $rootScope.UserMigrationDialog.show(email);
                    }, 0);
                });
            }

            function showCommonDialog(options) {
                if (angular.isString(options)) {
                    options = {content: options};
                }

                var dialogToShow = dialog.commonDialog()
                    .title('<span class="exclamation-icon">!</span><span>' + (options.title || '{{\'Dear Customer\' | translate}}') + '</span>')
                    .content(options.content);
                if (options.footer) {
                    dialogToShow.footer(options.footer);
                }

                if (options.ok) {
                    dialogToShow.ok(options.ok);
                }

                if (options.cancel) {
                    dialogToShow.cancel(options.cancel);
                }

                if (options.buttons) {
                    dialogToShow.buttons(options.buttons);
                }

                if (!options.buttons && !options.ok && !options.cancel && !options.disableClosing) {
                    dialogToShow.buttons([{text: '{{\'OK\' | translate}}', click: dialog.hide}]);
                }

                return dialogToShow.show(options);
            }

            function showRetailerMessageDialog(message, disableClosing, customCssClass) {
                var messageContentId = 'retailer_message_content',
                    defaultTabindex = 0,
                    correctMessage = [
                        '<div id="' + messageContentId + '">',
                        message,
                        '</div>'
                    ].join('');

                var retailerDialog = dialog.commonDialog().content(correctMessage);
                customCssClass = customCssClass || '';

                if (!disableClosing) {
                    retailerDialog = retailerDialog.buttons([{
                        text: '{{\'OK\' | translate}}',
                        click: retailerDialog.hide
                    }]);
                }

                return retailerDialog.show({
                    styleClass: 'retailer-custom-dialog ' + customCssClass,
                    disableClosing: disableClosing,
                    ariaLabelledby: messageContentId,
                    tabindex: defaultTabindex, 
                    onShow: function(dialogElement) {
                        dialogElement.setAttribute('role', 'dialog');
                        dialogElement.setAttribute('aria-modal', 'true');
                        dialogElement.setAttribute('tabindex', defaultTabindex);
                    }
                });
            }

            function showSpecialDetailsDialog(specialId, showProductsFrom) {
                self.specialDetailsDialogShown = true;
                return SpDialogUrlManager.setDialogParams({
                    special: specialId,
                    showFrom: showProductsFrom
                }).finally(function () {
                    self.specialDetailsDialogShown = false;
                });
            }

            function showCouponDetailsDialog(coupon, terms) {
                if (!coupon.special) {
                    coupon.special = coupon.branch.specials.find(function(special) {
                        return special.isCoupon;
                    });
                }
                return SpDialogUrlManager.backClose().then(function() {
                    $timeout(function() {
                        return $rootScope.CouponDetailsDialog.show(coupon, terms);
                    }, 0);
                });
            }

            function showOrganizationInactivePopup() {
                return user.getData().then(function (userData){
                    var isOrganization = userData.organization && userData.organization.id,
                        isInactive = isOrganization && !userData.organization.isActive,
                        isMissingBranches = isOrganization && userData.organization.organizationType === ORGANIZATION_TYPES.OBLIGO && (!userData.organization.branches || userData.organization.branches.length === 0),
                        messageType = 0;

                    if(isOrganization && (isInactive || isMissingBranches)) {
                        messageType = isInactive ? 1 : 2;
                    } else if(!isOrganization && $rootScope.config.retailer.status === RETAILER_STATUSES.ORGANIZATION_ONLY) {
                        messageType = 3;
                    }


                    if( messageType ) {
                        return dialog.show({
                            templateUrl: 'template/dialogs/organization-inactive/index.html',
                            styleClass: 'organization-inactive-main',
                            controller: ['$scope', 'Dialog', function ($scope, dialog) {
                                $scope.messageType = messageType;
                                $scope.username = userData.firstName + ' ' + userData.lastName;
                                $scope.organizationName = userData.organization && userData.organization.name || '';
                                $scope.hide = function () {
                                    dialog.hide();
                                };
                            }]
                        }).then(function () {
                            user.logout();
                        });
                    }
                });
            }
            
            function isWeightQuantity(product) {
                return product.isWeighable && !product.weight;
            }

            function isCartLineAdded(product) {
                return !product.originalTotalPrice && !product.originalPrice && !product.substituteId && product.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT
            }

            function isCartLineWithSale(item) {
                return (item.regularPrice * item.actualQuantity).toFixed(2) === item.totalPrice.toFixed(2)
           }

           /**
            * Check if special, coupons are expired
            * @param {Line} line 
            * @param {boolean} isCalculateAtCheckoutTime 
            * @returns 
            */
            function isIrrelevantSale(line, isCalculateAtCheckoutTime) {
              var isLineCanCheckSales =
                line.type === SP_SERVICES.CART_LINE_TYPES.DELIVERY ||
                line.type === SP_SERVICES.CART_LINE_TYPES.PRODUCT;

              /**
               * For checkout time, finalPrice is price at the momment, potential price is price at the past (first checkout)
               * For collectiont ime, finalPrice is price at the momment, potential price is price in the future (delivery date)
               */
              var isPriceIncreased = isCalculateAtCheckoutTime
                ? line.finalPriceForViewPotential < line.finalPriceForView
                : line.finalPriceForView !== line.finalPriceForViewPotential;

              return isLineCanCheckSales && isPriceIncreased;
            }

            function isSellOnDate(line, deliveryDate){
                // ignore removed line
                if(line.quantity == 0 || line.removed){
                    return true;
                }
                var sellDateTags = (line.product && line.product.productTagsData || []).filter(function (tag) {
                    return  tag.typeId == 9; // TODO: move to sp-product-tag
                });
                var checkSellDateValid = true;
                if(sellDateTags.length  === 0){
                    return checkSellDateValid;
                }
                if(!deliveryDate){
                    deliveryDate = new Date();
                }
                var isThisLineIsUnavailable = null;
                sellDateTags.forEach(function(sellDateTag){
                    if(!isThisLineIsUnavailable && sellDateTag.sellDaysType === TAG_DATE_CONFIGURATION_TYPE.DATE_RAGE && sellDateTag.sellDaysUnavailableDateRange && sellDateTag.sellDaysUnavailableDateRange.start && sellDateTag.sellDaysUnavailableDateRange.end){
                        var timeStart = new Date(sellDateTag.sellDaysUnavailableDateRange.start).getTime(),
                            timeEnd =  new Date(sellDateTag.sellDaysUnavailableDateRange.end).getTime(),
                            deliveryTime = deliveryDate.getTime();

                        if (deliveryTime >= timeStart && deliveryTime <= timeEnd) {
                            checkSellDateValid = false;
                            isThisLineIsUnavailable = true;
                        }

                    }

                    else if(!isThisLineIsUnavailable && sellDateTag.sellDaysType === TAG_DATE_CONFIGURATION_TYPE.WEEK_DAYS && sellDateTag.sellDaysAvailableWeekdays && sellDateTag.sellDaysAvailableWeekdays.length > 0){
                        var deliveryDay = deliveryDate.getDay();
                        var checkDeliveryDay = sellDateTag.sellDaysAvailableWeekdays.find(function (item) {
                            return item === deliveryDay;
                        })
                        if(checkDeliveryDay == null) {
                            checkSellDateValid = false;
                            isThisLineIsUnavailable = true;
                        }
                    }

                });
                return checkSellDateValid;
            }

            function isUnitsWeighable(product) {
                return product.isWeighable && !!product.weight;
            }

            function productSoldBy(product, soldBy) { 
                /* 
                    Condition for unit/weight toggle mode is on:
                    If the product is weighable and has est. weight and weight unit (in case has no weight unit, kg is default)
                    Then product can be sold by unit/ weight
                */ 
                return self.isUnitsWeighable(product) && soldBy;
            }

            function pushAll(to, from) {
                if (to && from && angular.isArray(to) && angular.isArray(from)) {
                    to.push.apply(to, from);
                }
                return to;
            }

            function currentScopeListener(scope, listener) {
                scope.$on('$destroy', function () {
                    if (Array.isArray(listener)) {
                        angular.forEach(listener, function(l) {
                            l();
                        });
                    } else {
                        listener();
                    }
                });
            }

            function trim(str) {
                if (str) {
                    if (String.prototype.trim) {
                        str = str.trim();
                    } else {
                        var firstChar = str[0];
                        while (firstChar === ' ') {
                            str = str.substring(1);
                            firstChar = str[0];
                        }
                        var lastChar = str[str.length - 1];
                        while (lastChar === ' ') {
                            str = str.substring(0, str.length - 1);
                            lastChar = str[str.length - 1];
                        }
                    }
                }
                return str;
            }

            function getMessagesAlertText(message) {
                if (message) {
                    return self.trim(message.replace(/\\n/g, '<br/>'));
                } else {
                    return message;
                }
            }

            function getCurrentStyleProp(element, prop, isNumber) {
                element = angular.element(element)[0];
                var res = '';
                if (window.getComputedStyle && angular.isFunction(window.getComputedStyle)) {
                    res = window.getComputedStyle(element).getPropertyValue(prop);
                } else if (element.currentStyle && element.currentStyle[prop]) {
                    res = element.currentStyle[prop];
                }
                if (isNumber && res) {
                    var resNumber = Number(res.replace('px', ''));
                    if (angular.isNumber(resNumber) && !isNaN(resNumber)) {
                        res = resNumber;
                    }
                }
                return res;
            }

            function getDocumentScrolledElement(event) {
                var res = event.target || event.srcElement || event.currentTarget;
                if (res.documentElement && res.documentElement.scrollTop) {
                    return res.documentElement;
                } else {
                    return res.body || res;
                }
            }

            function getCategory(categoryId) {
                return config.initPromise.then(function() {
                    var categories = config.tree.categories;
                    for (var i = 0; i < categories.length; i++) {
                        var level1 = categories[i];
                        if (level1.id == categoryId) {
                            return [level1];
                        }

                        for (var ii = 0; ii < level1.subCategories.length; ii++) {
                            var level2 = level1.subCategories[ii];
                            if (level2.id == categoryId) {
                                return [level2, level1];
                            }

                            for (var iii = 0; iii < level2.subCategories.length; iii++) {
                                var level3 = level2.subCategories[iii];
                                if (level3.id == categoryId) {
                                    return [level3, level2, level1];
                                }
                            }
                        }
                    }
                });
            }

            function getCategoryActualTypeByProductData(product) {
                if(! (product && product.family && product.family.categories && product.family.categories[2]) ) {
                    return $q.resolve(CATEGORIES_TYPES.REGULAR);
                }

                return getCategory(product.family.categories[2].id).then(function(categories) {
                    categories = categories || [];

                    for (var i = 0; i < categories.length; i++) {
                        if (categories[i].type && categories[i].type !== CATEGORIES_TYPES.INHERIT) {
                            return categories[i].type;
                        }
                    }

                    return CATEGORIES_TYPES.REGULAR;
                });
            }

            function setCurrentCategory(value, scope) {
                $rootScope.currentCategory = angular.isArray(value) ? value : [value];
                if (scope && scope.$on) {
                    var destroyListener = scope.$on('$destroy', function () {
                        destroyListener();
                        $rootScope.currentCategory = null;
                    });
                }
            }

            function loadImage(url, callback) {
                var image = new Image();
                image.onload = callback;
                image.src = url;
            }

            function setFirstErrorInput(formElement) {
                if (!formElement || !formElement.length) return;
                var firstErrorElement = angular.element(formElement)[0].querySelector('.ng-invalid');
                if (!firstErrorElement) return;

                if (firstErrorElement.id === 'captcha_hidden_input') {
                    var captchaHolderElement = angular.element(formElement)[0].querySelector('.captcha-holder');
                    if (captchaHolderElement) {
                        var captchaIframe = captchaHolderElement.getElementsByTagName('iframe');
                        if (captchaIframe && captchaIframe.length) {
                            captchaIframe[0].focus();
                            return;
                        }
                    }
                }

                if (firstErrorElement.id === 'termsAndConditionsText') {
                    firstErrorElement.focus();
                }

                var errorElement = angular.element(firstErrorElement).triggerHandler('blur');
                setTimeout(function () {
                    errorElement[0].focus();
                });
            }

            function setFocusForElement(element) {
                if (!element) return;
                setTimeout(function () {
                    element.focus();
                });
            }

            function setServerErrorToForm(form, formElement, serverRes) {
                if (typeof serverRes === 'string') {
                    serverRes = {
                        response: {
                            error: serverRes
                        }
                    };
                }

                formElement = angular.element(formElement);
                var serverErrMsgElement = angular.element(formElement[0].querySelector('.server-err-msg')).removeClass('shown');
                angular.element(formElement[0].querySelectorAll('.err-input-wrapper')).removeClass('show-err-msg').removeClass('server-invalid');
                if (!serverRes.response || (serverRes.response && !serverRes.response.error)) {
                    serverErrMsgElement.addClass('shown').html('');
                    return;
                }

                if (serverRes.response.error) {
                    var phone = config.retailer.contactPhone,
                        error = $filter('translate')(serverRes.response.error);
                    if (phone) {
                        error += '<div class="err-contact-phone"> ' + $filter('translate')('Customer service') + ' ' + phone + '</div>';
                    }
                    serverErrMsgElement.addClass('shown').html(error);
                }

                if (serverRes.response.errors) {
                    angular.forEach(serverRes.response.errors, function (error) {
                        if (!form[error.param]) return;

                        form[error.param].$setValidity(error.msg, false);

                        angular.element(formElement[0].querySelector('*[name="' + error.param + '"]'))
                            .triggerHandler('blur');

                        form[error.param].$setValidity(error.msg, true);
                    });
                }
            }

            function checkAndRemoveErrorMessage(formElement) {
                var formElement = angular.element(formElement);
                angular.element(formElement[0].querySelector('.server-err-msg')).removeClass('shown');
                angular.element(formElement[0].querySelectorAll('.err-input-wrapper')).removeClass('show-err-msg').removeClass('server-invalid');
            }

            function setClientErrorToForm(form, formElement, message, extension) {
                formElement = angular.element(formElement);
                var errMsgElement = angular.element(formElement[0].querySelector('.server-err-msg')).removeClass('shown');
                angular.element(formElement[0].querySelectorAll('.err-input-wrapper')).removeClass('show-err-msg').removeClass('server-invalid');
                if (!message) {
                    return;
                }

                if (message) {
                    errMsgElement.addClass('shown').html($filter('translate')(message) + extension);
                }

            }

            function getTwoCharNum(num) {
                if (angular.isNumber(num)) {
                    var res = Number(num);
                    if (res < 10) {
                        return '0' + res.toString();
                    } else {
                        return res.toString();
                    }
                }
                return num;
            }

            function uniqueSelectorByElement(element) {
                var selector = [],
                    current = element;
                do {
                    if (current.getAttribute('id')) {
                        selector.unshift('#' + current.getAttribute('id'));
                        break;
                    }

                    var currentSelector = current.tagName.toLowerCase();

                    if (current.parentElement) {
                        for (var i = 0; i < current.parentElement.children.length; i++) {
                            if (current.parentElement.children[i] == current) {
                                currentSelector += ':nth-child(' + (i + 1) + ')';
                                break;
                            }
                        }
                    }

                    selector.unshift(currentSelector);
                    current = current.parentElement;
                } while (current);

                return selector.join('>');
            }

            function divideArray(array, number) {
                var copy = angular.copy(array),
                    divided = [];
                while (copy.length) {
                    divided.push(copy.splice(0, number));
                }

                return divided;
            }

            function showMultiDomainsOnCartActivity(defer) {
                var listeners = [];
                defer = defer || $q.defer();

                function onCartActivity() {
                    if (self.fetchingUserArea) {
                        var watcher = $rootScope.$on('user.fetchArea.finish', function() {
                            watcher();
                            onCartActivity();
                        });
                        return;
                    }

                    angular.forEach(listeners, function(listener) {
                        listener();
                    });

                    //== If branch and branchAreaId are known we don't need to show Choose Area Dialog
                    if (config.branchAreaId && config.branch) {
                        return defer.resolve();
                    }

                    //== When order is in edit mode we will set the edit order branch and area from the order data
                    if (_getCart().editOrderId) {
                        return defer.resolve();
                    }

                    //Set the items-wrapper element as active element
                    var activeElement = document.activeElement,
                    itemsWrapperElement;
                    do {
                        if (activeElement.classList.contains('items-wrapper')) {
                            itemsWrapperElement = activeElement;
                            break;
                        }
                        activeElement = activeElement.parentElement;
                    } while (activeElement.parentElement);

                    ChooseAreaDialog.show(false, true, null, itemsWrapperElement || document.activeElement).then(function () {
                        if (!config.branchAreaId) {
                            _getCart().clear();
                            return showMultiDomainsOnCartActivity(defer);
                        }

                        defer.resolve();
                    }).catch(defer.reject);
                }

                listeners.push($rootScope.$on('cart.lines.add', onCartActivity));

                return defer.promise;
            }

            function showLoadCartOnCartActivity(defer) {
                var listeners = [];
                defer = defer || $q.defer();

                function onCartActivity() {
                    if (!config.branchAreaId) {
                        return;
                    }

                    angular.forEach(listeners, function(listener) {
                        listener();
                    });

                    dialog.show({
                        controller: 'LoadLastCartCtrl as loadLastCartCtrl',
                        templateUrl: 'template/dialogs/load-last-cart/index.html',
                        styleClass: 'load-last-cart',
                        ariaLabelledby: 'load_last_cart_dialog_title',
                        resolve: {
                            fields: ['Api', function (Api) {
                                return Api.request({
                                    method: 'GET',
                                    url: '/retailers/:rid/loyalty/fields'
                                }).then(function (resp) {
                                    return resp.lookup;
                                });
                            }]
                        }
                    }).finally(function() {
                        sessionStorage.setItem('loadCartDialogShown', 1);
                    });
                }

                listeners.push($rootScope.$on('cart.lines.add', onCartActivity));
                listeners.push($rootScope.$on('config.branchAreaId.change', onCartActivity));

                return defer.promise;
            }

            function checkForPopupsOnStart() {
                var text;
                if (!config.retailer.status || config.retailer.status === RETAILER_STATUSES.DISABLED) {
                    text = self.getMessagesAlertText(config.retailer.settings.closedSiteText);
                    if (text && self.trim(text)) {
                        self.showRetailerMessageDialog(text, true, 'closed-site-container');
                        angular.element(document.body).addClass('closed-shopo-site');
                    } else {
                        var message = '{{\'The site is disabled\' | translate}}';
                        if (!config.retailer.status) {
                            message = '{{\'The site is undergoing maintenance\' | translate}}.<br/>{{\'Please try again later\' | translate}}';
                        }
                        self.showCommonDialog({
                            content: message,
                            disableClosing: true
                        });
                    }
                } else if(config.retailer.status === RETAILER_STATUSES.LP_ONLY) {
                    window.location.replace(window.location.origin + '/lp/loyalty' + window.location.search)
                } else if (validateRegisteredOnly()) {
                    //== Action is inside if statement
                } else {
                    _showOnOpenSiteAlertText();
                }
            }

            function setDefaultBranch() {
                var defaultBranch = config.retailer.branches.find(function (branch) {
                    return branch.isDefault;
                });
                config.branch = defaultBranch || Config.retailer.branches[0];
                config.branchAreaName = defaultBranch.areas[0];
            }

            function _showOnOpenSiteAlertText() {
                var search = $location.search();
                if (config.preventDialogsOnLoad || search.emailVerification || search.invitedUser || search.resetPasswordCode) {
                    return;
                }
                var onOpenSiteText = self.getMessagesAlertText(config.retailer.settings.onOpenSiteAlertText);
                if (onOpenSiteText &&  _convertToHtmlToCheckEmpty(onOpenSiteText) !== '') {
                    //use normal common dialog to keep retailer design
                    self.showRetailerMessageDialog(onOpenSiteText, false, 'on-open-site-alert-container');
                }
            }

            /**
             * Show cookie wall dialog
             * @public
             *
             * @return {promise}
             */
            function showCookieWallDialog() {
                if (config.retailer.isCookieWallEnabled && !config.approvedCookies && !config.preventDialogsOnLoad) {
                    var forceApproveDialog = false;
                    return dialog.show({
                        templateUrl: 'template/dialogs/cookie-wall/index.html',
                        disableClosing: forceApproveDialog,
                        controller: ['$scope', '$rootScope', '$window', function($scope, $rootScope, $window) {
                            var cookieWallCtrl = this;
                            cookieWallCtrl.isMainView = true;
                            cookieWallCtrl.allowClosing = !forceApproveDialog;
                            cookieWallCtrl.showError = false;
                            cookieWallCtrl.cancel = cancel;
                            cookieWallCtrl.accept = accept;
                            cookieWallCtrl.acceptAll = acceptAll;
                            cookieWallCtrl.configure = configure;
                            cookieWallCtrl.true = true;

                            var _default = false;

                            cookieWallCtrl.cookies = {
                                marketing: _default,
                                additionalFeatures: _default,
                                audienceMeasurement: _default,
                                googleAnalytics: true
                            }

                            function _handleCookies() {
                                $rootScope.$emit('tracking.permissions.updated');
                            }

                            function accept() {
                                cookieWallCtrl.cookies._time = new Date();
                                config.setApprovedCookies(cookieWallCtrl.cookies);
                                $rootScope.$emit('util.cookieWall.event');
                                _handleCookies();
                                cancel();
                            }

                            function acceptAll() {
                                angular.forEach(cookieWallCtrl.cookies, function(val, key) {
                                    cookieWallCtrl.cookies[key] = true;
                                });
                                accept();
                            }

                            function configure() {
                                cookieWallCtrl.isMainView = false;
                            }

                            function cancel() {
                                return  dialog.hide();
                            }
                        }],
                        controllerAs: 'cookieWallCtrl',
                        styleClass: 'cookie-wall',
                        ariaLabelledby: 'cookie_wall_dialog_title'
                    });
                } else {
                    return $q.resolve();
                }
            }

            function showCouponReminderDialog(coupon) {
                dialog.show({
                    templateUrl: 'template/dialogs/coupon-reminder/index.html',
                    controller: ['$scope', function($scope) {
                        var couponReminderCtrl = this;
                        couponReminderCtrl.coupon = coupon;

                        couponReminderCtrl.isInCart = !!Object.values(_getCart().lines).find(function (line) {
                            return line.product && line.product.id === coupon.id;
                        })
                    }],
                    controllerAs: 'couponReminderCtrl',
                    styleClass: 'coupon-reminder',
                    ariaLabelledby: 'coupon_reminder_title'
                });
            }

            function validateRegisteredOnly() {
                var search = $location.search();
                if ( !search.emailVerification && !search.invitedUser && !search.resetPasswordCode && !user.session.userId &&
                    (config.retailer.status === RETAILER_STATUSES.REGISTERED_ONLY || config.retailer.status === RETAILER_STATUSES.ORGANIZATION_ONLY)) {
                    _setRegisteredOnlyRouteAccess();
                    openLoginDialog().then(function(){

                        //== After organization user login the website is refreshed, this is preventing of popup to be displayed twice (after login and after refresh)
                        if(config.retailer.status !== RETAILER_STATUSES.ORGANIZATION_ONLY) {
                            _showOnOpenSiteAlertText();
                        }
                    });

                    return true;
                }
                return false;
            }

            function _setRegisteredOnlyRouteAccess() {
                if (!$state.current || !$state.current.name) {
                    var listener = $rootScope.$on('$stateChangeSuccess', function() {
                        listener();
                        _setRegisteredOnlyRouteAccess();
                    });
                    return;
                }

                angular.forEach($state.get(), function(state) {
                    state.data = state.data || {};
                    state.data.routeAccess = state.data.routeAccess || [];
                    if (!Array.isArray(state.data.routeAccess)) {
                        state.data.routeAccess = [state.data.routeAccess];
                    }
                    var hasLoggedOut = false;
                    angular.forEach(state.data.routeAccess, function(access) {
                        hasLoggedOut = hasLoggedOut || access === ROUTE_ACCESS.LOGOUT;
                    });
                    if (!hasLoggedOut) {
                        state.data.routeAccess.push(ROUTE_ACCESS.LOGIN);
                    }
                });
            }

            var noDeliveriesPopupShown = false;

            function showNoDeliveriesTodayPopup() {
                if (noDeliveriesPopupShown) return;

                noDeliveriesPopupShown = true;
                var noDeliveriesTodayText = self.getMessagesAlertText(config.retailer.settings.noDeliveriesTodayText);
                if (noDeliveriesTodayText) {
                    //use normal common dialog to keep retailer design
                    self.showRetailerMessageDialog(noDeliveriesTodayText, false, 'on-no-deliveries-today-container');
                }
            }

            function _isProductActive(product, isCase) {
                return product && product.branch && product.branch.isActive && product.branch.isVisible && product.family && product.family.categoriesPaths &&
                    product.family.categoriesPaths.length && (!isCase || product.branch.case && product.branch.case.price);
            }

            function _isProductOutOfStock(cartLine) {
                return cartLine.type == SP_SERVICES.CART_LINE_TYPES.PRODUCT && (!_isProductActive(cartLine.product, cartLine.isCase) || !!cartLine.product.branch.isOutOfStock) && !cartLine.isPseudo;
            }

            function isProductOutOfStock(cartLine) {
                return _isProductOutOfStock(cartLine) && !_doNotRemoveTemporarilyMissingItems();
            }

            function isNeedToShowOutOfStockLabel(cartLine) {
                return _isProductOutOfStock(cartLine) && _doNotRemoveTemporarilyMissingItems();
            }

            function _doNotRemoveTemporarilyMissingItems() {
                return config.retailer.settings.doNotRemoveTemporarilyMissingItems === 'true';
            }

            function getActiveLines(lines, onlyViewNotActive, allowLoyaltyItems, allowDeliveryItems, fromEditOrder) {
                var toAdd = [],
                    notActive = [];

                Orders = Orders || $injector.get('Orders');

                lines = angular.isArray(lines) ? lines : [lines];
                angular.forEach(lines, function (line) {
                    //prevent adding delivery and register loyalty products from old orders or shopping lists
                    if ((line.type && line.type != SP_SERVICES.CART_LINE_TYPES.PRODUCT && (line.type != SP_SERVICES.CART_LINE_TYPES.COUPON && !fromEditOrder) &&
                        (!allowDeliveryItems || line.type != SP_SERVICES.CART_LINE_TYPES.DELIVERY)) ||
                        (line.type == SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY && !allowLoyaltyItems)) {
                        return;
                    }

                    if (!line.product || (!Orders.orderInEdit &&
                        (!line.type || line.type == SP_SERVICES.CART_LINE_TYPES.PRODUCT) &&
                        !line.isPseudo && !_isProductActive(line.product, line.isCase) && !_doNotRemoveTemporarilyMissingItems(line))) {
                        return notActive.push(line);
                    }

                    //to prevent set values to line (for example in _getWeighableProductUnits)
                    if (onlyViewNotActive) {
                        return;
                    }

                    toAdd.push({
                        isPseudo: line.isPseudo,
                        isCase: line.isCase,
                        product: angular.copy(line.product),
                        comments: line.comments,
                        adminComments: line.adminComments,
                        quantity: self.isUnitsWeighable(line.product) ? _getWeighableProductUnits(line) : line.quantity,
                        soldBy: _getSoldBy(line),
                        type: line.type,
                        metaData: line.metaData,
                        productPropertyValue: line.productPropertyValue
                    });
                });

                if (notActive.length) {
                    self.showCommonDialog({
                        title: '{{\'Cannot Add To Cart\' | translate}}'.replace('Cart', $rootScope.config.retailer.settings.setCartNameToBasket ? $rootScope.config.retailer.settings.setCartNameToBasket : 'Cart'),
                        content: '' +
                        '{{\'We are not able to add the following products to your current order\' | translate}}:<br/>' +
                        '<div ng-repeat="line in notActiveLines">' +
                        '   {{$index+1}}. ' +
                        '   <span ng-if="!!line.product">' +
                        '       {{line.product | productName:line.isCase}}' +
                        '   </span>' +
                        '   <span ng-if="!line.product">' +
                        '       {{(line.name ? (\'name\' | translate) + \': \' + line.name : null) ||' +
                        '       (line.barcode ? (\'barcode\' | translate) + \': \' + line.barcode : null) ||' +
                        '       (line.productId ? (\'id\' | translate) + \': \' + line.productId : null) ||' +
                        '       (line.retailerProductId ? (\'id\' | translate) + \': \' + line.retailerProductId : null)}}' +
                        '   </span>' +
                        '</div>',
                        controller: ['$scope', function (innerScope) {
                            innerScope.notActiveLines = notActive;
                        }]
                    });
                }

                if (onlyViewNotActive) {
                    return;
                }

                return toAdd;
            }

            function removeCookie(name) {
                // This function will attempt to remove a cookie from all paths.
                var pathBits = location.pathname.split('/');
                var pathCurrent = ' path=';

                // do a simple pathless delete first.
                document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;';

                for (var i = 0; i < pathBits.length; i++) {
                    pathCurrent += ((pathCurrent.substr(-1) !== '/') ? '/' : '') + pathBits[i];
                    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT;' + pathCurrent + ';';
                }
            }

            function getRegularPrice(prod, isCase) {
                return _regularPrice(prod, isCase);
            }

            function clearStorage(keys) {
                $timeout(function () {
                    if (!keys) {
                        localStorageService.clear();
                        self.removeCookie('retailerId');
                    } else {
                        keys = angular.isArray(keys) ? keys : [keys];
                        angular.forEach(keys, function (key) {
                            localStorageService.removeItem(key);
                        });
                    }
                    $location.search('clearStorage', null);
                    $state.go('app.home');
                    reload(true);
                }, 1000);
            }

            function getProductCartLine(product, isCase) {
                return product[(isCase ? 'case' : 'single') + 'Line'];
            }

            function openTemp() {
                dialog.show({
                    styleClass: 'load-orders-from-pos',
                    controller: 'LoadOrdersFromPOSCtrl as posOrdersCtrl',
                    templateUrl: 'template/dialogs/load-orders-from-pos/index.html',
                    ariaLabelledby: 'load_orders_from_pos_title',
                    resolve: {
                        posUserFields: ['Api', function (api) {
                            return api.request({
                                method: 'GET',
                                url: '/retailers/:rid/users/:uid/orders/fromPos'
                            });
                        }]
                    }
                });
            }

            function openContactUs() {
                if (config.contactUsPageDesign && config.contactUsPageDesign.isUseContactPage) {
                    $state.go('app.userEdit.contactUs')
                } else {
                    dialog.show({
                        resolve: {
                            retailerDetails: config.retailer
                        },
                        templateUrl: 'template/dialogs/contact-us/index.html',
                        controller: 'ContactUsCtrl as contactUsCtrl',
                        styleClass: 'contact-us',
                        ariaLabelledby: 'contact_us_dialog_title'
                    });
                }
            }

            function showEbtErrorDialog(error){
                return dialog.show({
                    templateUrl: 'template/dialogs/ebt-error/index.html',
                    styleClass: 'ebt-error-wrapper',
                    controller: ['$scope', 'Dialog', function ($scope, dialog) {
                        $scope.error = error;
                        $scope.hide = function () {
                            dialog.hide();
                        };
                    }]
                });
            }

            function showPaymentErrorDialog(error) {
                return dialog.show({
                    templateUrl: 'template/dialogs/payment-error/index.html',
                    styleClass: 'payment-error-wrapper',
                    controller: ['$scope', 'Dialog', function ($scope, dialog) {
                        $scope.errorObj = error;
                        $scope.hide = function () {
                            dialog.hide();
                        };
                    }]
                });
            }

            function showEditOrderPaymentChooseDialog(orderPaymentMethods) {
                return dialog.show({
                    templateUrl: 'template/dialogs/edit-order-payment-choose/index.html',
                    controller: 'EditOrderPaymentChooseCtrl as editOrderPaymentChooseCtrl',
                    styleClass: 'edit-order-payment-choose',
                    locals: {
                        orderPaymentMethods: orderPaymentMethods
                    }
                })
            }

            function openSuggestionsDialog(product) {
                if (!product.item && (!product || !product._suggestions || !product._suggestions.length || !product._suggestions[0].id)) {
                    return;
                }
                if (product.item && (!product.item._suggestions || !product.item._suggestions.length)) {
                    return;
                }

                $rootScope.suggestionsDialogOpened = true;
                return dialog.show({
                    templateUrl: 'template/dialogs/product-suggestions/index.html',
                    controller: 'ProductSuggestionsCtrl as productSuggestionsCtrl',
                    styleClass: 'product-suggestions-popup',
                    ariaLabelledby: 'product_suggestions_dialog_title',
                    locals: {
                        product: product.item || product
                    }
                }).then(function(data) {
                    $rootScope.suggestionsDialogOpened = false;
                    if ($rootScope.triggerSuggestionsClose) {
                        $rootScope.triggerSuggestionsClose = false;
                        $rootScope.$emit('suggestions.closed');
                    }
                    return data;
                });
            }

            function openOutOfStockDialog(lines) {
                return dialog.show({
                    resolve: {
                        retailerDetails: config.retailer
                    },
                    templateUrl: 'template/dialogs/out-of-stock/index.html',
                    controller: 'OutOfStockCtrl as outOfStockCtrl',
                    styleClass: 'out-of-stock-popup',
                    ariaLabelledby: 'out_of_stock_dialog_title',
                    locals: {
                        lines: lines || []
                    }
                });
            }

            function openClearCartDialog(cart) {
                return dialog.show({
                    resolve: {
                        retailerDetails: config.retailer
                    },
                    templateUrl: 'template/dialogs/clear-cart/index.html',
                    controller: ['$scope', function ($dialogScope) {
                        $dialogScope.clearAllCart = function () {
                            DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Clear Cart'}});

                            cart.clear();
                            dialog.hide();

                            toast.show({
                                timeout: 3000,
                                content: '{{\'cart deleted\'| translate}}'
                            });

                        };
                    }],
                    styleClass: 'clear-cart-popup'
                });
            }

            function _isInvalidProductSpecial(special) {
                var nowDate = new Date(),
                    convertedEndDate = _getLocalDateFromUTCDate(new Date(special.endDate)),
                    convertedStartDate = _getLocalDateFromUTCDate(new Date(special.startDate));

                return /*is coupon and coupon specials shouldn't be shown*/ (special.isCoupon && !config.retailer.settings.showCouponsSpecials) /*special dates are not active*/ || convertedStartDate >= nowDate || convertedEndDate <= nowDate;
            }

            //this converts server static UTC time - to local time on machine/browser
            function _getLocalDateFromUTCDate(date) {
                return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
            }

            function filterProductSpecials(productBranch) {
                var productSpecials = [];
                if (productBranch && productBranch.specials) {
                    angular.forEach(productBranch.specials || [], function (special) {
                        if (_isInvalidProductSpecial(special)) {
                            return;
                        }

                        productSpecials.push(special);
                    });
                }
                return productSpecials;
            }

            function productHasClubSpecial(productBranch) {
                if (!productBranch || !productBranch.specials) {
                    return false;
                }

                var special;
                for (var i = 0; i < productBranch.specials.length; i++) {
                    special = productBranch.specials[i];
                    if (_isInvalidProductSpecial(special) || !special.loyaltyClubsIds || !special.loyaltyClubsIds.length) continue;

                    return true;
                }

                return false;
            }

            function showLoyaltyClubDialog(classes, fireAndForgot) {
                var loyaltyClubDriver, userLoyaltyClub,
                    isMultiLoyalty = ($rootScope.config.retailer.loyaltyClubDrivers|| [])
                        .filter(function (driver) {
                            return driver.isActive
                        }).length > 1;

                if (!isMultiLoyalty) {
                    loyaltyClubDriver = $rootScope.config.retailer.loyaltyClubDrivers[0];
                    userLoyaltyClub = !!user.loyaltyClubs && user.loyaltyClubs[0];
                }
                if (!isMultiLoyalty && (userLoyaltyClub && userLoyaltyClub.loyaltyCardId &&
                    (!userLoyaltyClub.loyaltyClubExpiration || new Date(userLoyaltyClub.loyaltyClubExpiration) >= new Date()) ||
                    (loyaltyClubDriver.clientConfig && loyaltyClubDriver.clientConfig.extendedLoyaltyClub)) || $rootScope.loyaltyDialogIsOpen) {
                    return $q.resolve();
                }

                if (!user.session.userId) {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Show loyalty club dialog when need to register or login'}});
                    return openLoginDialog(true).then(function () {
                        if (user.session.userId) {
                            return showLoyaltyClubDialog(classes, fireAndForgot);
                        }
                    });
                }

                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button',action: 'Click', label: 'Show loyalty club dialog'}});

                // if (loyaltyClubDriver &&
                //     loyaltyClubDriver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.MAX) {
                //     return user.getData(true).then(function(userData){
                //         return userData.creditCards.length;
                //     }).then(function(numberOfCreditCards) {
                //         return PaymentsService.addCreditCard({ isLoyalty: true, creditCardLoyaltyClubDriverId: $rootScope.config.retailer.loyaltyClubDriver.id})
                //             .then(function () {
                //                 return user.getData(true);
                //             }).then(function (newUserData) {
                //                 if (newUserData.creditCards.length > numberOfCreditCards) {
                //                     var message = $rootScope.config.retailer.loyaltyClubDriver.clientConfig &&
                //                         $rootScope.config.retailer.loyaltyClubDriver.clientConfig.text &&
                //                         $rootScope.config.retailer.loyaltyClubDriver.clientConfig.text.postActivationLabel || '';
                //                     return dialog.commonDialog().buttons([{
                //                         text: '{{\'OK\' | translate}}',
                //                         click: dialog.hide
                //                     }]).content(message).show();
                //                 } else {
                //                     return $q.reject();
                //                 }
                //             });
                //     });
                // }

                $rootScope.loyaltyDialogIsOpen = true;

                return SpDialogUrlManager.setDialogParams({
                    registerLoyalty: '1',
                    classes: !!classes && JSON.stringify(classes),
                    fireAndForgot: fireAndForgot
                }).then(function(result) {
                    $rootScope.loyaltyDialogIsOpen = false;
                    return result;
                });
            }

            function showLoyaltyIFrameDialog(url) {
                var isUserConsentRequired = !localStorageService.getItem('isFirstUserLogin'),
                    termsAgreement = !isUserConsentRequired,
                    promoAgreement = isUserConsentRequired ? false : user.data.allowSendPromotions;

                var data = {
                    retailerId: config.retailer.id,
                    sessionId: user.session.token,
                    firstName: user.data.firstName,
                    lastName: user.data.lastName,
                    email: user.data.email,
                    termsAgreement: termsAgreement,
                    promoAgreement: promoAgreement,
                    isUserConsentRequired: isUserConsentRequired
                };

                if(!url) {
                    return $timeout(function() { }, 0);
                }

                //If user already registered or just closed the login popup
                if(user.data.foreignId || !user.data.email){
                    return $timeout(function() { }, 0);
                }

                return SpDialogUrlManager.backClose().then(function() {
                    $timeout(function() {
                        return $rootScope.LoyaltyIFrameDialog.show(url, data);
                    }, 0);
                });
            }

            function showLoyaltyRegisterIFrameDialog(classes) {
                return dialog.show({
                    templateUrl: 'template/dialogs/loyalty/partials/iframe/index.html',
                    controller: 'LoyaltyCtrl',
                    controllerAs: 'loyaltyCtrl',
                    styleClass: 'loyaltyRegisterIframe',
                    resolve: {
                        fields: [function () {
                            return {};
                        }]
                    },
                    classes: classes,
                    disableClosing: true,
                    showClose: true
                });
            }

            function showUsersUploadDialog(mode, organizationId) {
                return dialog.show({
                    templateUrl: 'template/dialogs/process-users/upload-file.html',
                    controller: 'ProcessOrganizationUsersCtrl as processOrganizationUsersCtrl',
                    locals: {
                        mode: mode,
                        organizationId: organizationId
                    }
                });
            }

            function showUsersUploadCompleteDialog(mode, organizationId) {
                return dialog.show({
                    templateUrl: 'template/dialogs/process-users/upload-file-complite.html',
                    controller: 'ProcessOrganizationUsersCtrl as processOrganizationUsersCtrl',
                    locals: {
                        mode: mode,
                        organizationId: organizationId
                    }
                });
            }

            function trustYoutubeSrc(src) {
                var idVideo, url_array;
                if (src.indexOf('embed') > -1) {
                    idVideo = src.split('embed/')[1].split('?')[0];
                } else {
                    url_array = src.split('=');
                    idVideo = url_array[url_array.length - 1];
                }

                url_array = idVideo.split('/');
                idVideo = url_array[url_array.length - 1];

                var url = 'https://www.youtube.com/embed/' + idVideo + '?&showinfo=0&autoplay=1&rel=0';
                return trustSrc(url);
            }

            function trustSrc(src) {
                return $sce.trustAsResourceUrl(src);
            }

            function validateMinimumCost(minimumOrderPrice, notIncludeSpecials, areaName) {
                var text = $rootScope.config.retailer.settings.includeDeliveryFeeInCart === 'true' ? '<br/>' + _translateFilter('not_includes_delivery_fee') : '';

                return _getCart().validateMinimumCost(minimumOrderPrice, notIncludeSpecials)
                    .catch(function (price) {
                        closeUpdateDialog().then(function(){
                            showCommonDialog(
                                $interpolate(_translateFilter('Your order total {{areaName}}does not meet the {{minimum | currency}} minimum order total'))({
                                    areaName: areaName ? _translateFilter('to ') + areaName + ' ' : '',
                                    minimum: minimumOrderPrice
                                }) + text + '.<br/>' +
                                $interpolate(_translateFilter('Please add to your cart items worth {{difference | currency}} to proceed to checkout'))({
                                    difference: minimumOrderPrice - price
                                }) + '.' +
                                (notIncludeSpecials ? '<br/>' + _translateFilter('Not including items on sale') + '.' : '')
                            );
                        });
                        return $q.reject('Minimum Order Price');
                    });
            }

            function _deleteLoyaltyClub(loyaltyClubId) {
                return api.request({
                    method: 'DELETE',
                    url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + loyaltyClubId
                }).then(function () {
                    return user.getData(true);
                }).then(function() {
					// recalculate cart after removing the loyalty club
                    return _getCart().forceUpdate();
                });
            }

            function _renewLoyaltyClub(retailerLoyaltyClubDriver, userLoyaltyClub) {
                var names = {};
                names[config.language.id] = {short: ''};
				_getCart().addLine({
                    product: {
                        id: retailerLoyaltyClubDriver.clientConfig.product.renewRetailerProductId,
                        names: names
                    },
                    quantity: 1,
                    type: SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY,
                    metaData: JSON.stringify({
                        club: userLoyaltyClub.loyaltyClubId,
                        renewData : { customerId: userLoyaltyClub.loyaltyCardId, retailerLoyaltyClubDriverId: retailerLoyaltyClubDriver.id }
                    })
                }).then(function (){
                    return showCommonDialog({
                        title: '{{\'Loyalty membership renewal\' | translate}}',
                        content: '{{\'Your membership has been renewed successfully\' | translate}}.<br/>' +
                        '{{\'You can enjoy from the club benefits in this order\' | translate}}.',
                        buttons: [{
                            text: '{{\'Continue\' | translate}}',
                            click: '$dialog.hide()'
                        }]
                    });
                });
                loyaltyCtrl.connected = true;
            }

            function validateLoyaltyExpirationDate() {
                return user.getData().then(function (data) {
                    if (!user.data.loyaltyClubs || !user.data.loyaltyClubs.length) {
                        return 'Continue';
                    }

                    var promises = [];
                    angular.forEach(user.data.loyaltyClubs, function (loyaltyClub) {
                        if (!loyaltyClub.loyaltyClubExpiration || new Date(loyaltyClub.loyaltyClubExpiration) >= new Date()) return;
                        var loyaltyClubDriver = config.retailer.loyaltyClubDrivers.find(function (driver) {
                            return driver.loyaltyClubDriverId === loyaltyClub.loyaltyClubDriverId;
                        });

                        var loyaltyClientConfig = loyaltyClubDriver && loyaltyClubDriver.clientConfig;
                        var renewProductId = loyaltyClientConfig && loyaltyClientConfig.product && loyaltyClientConfig.product.renewRetailerProductId;
                        if (renewProductId) {
                            var registerLoyaltyLine = _getCart().lines[renewProductId + '0'];
                            if (registerLoyaltyLine) return 'Continue';
                        }

                        var renewPrice = 0;
                        if (loyaltyClientConfig && loyaltyClientConfig.isRenewActive){
                            renewPrice = ((loyaltyClientConfig.product && loyaltyClientConfig.product.renewProductPrice) || 0);
                        }
                        promises.push( _openLoyaltyMembershipExpiredDialog(loyaltyClubDriver, data, loyaltyClub, loyaltyClientConfig, renewPrice));
                    });

                    return $q.all(promises);
                }).catch(function () {
                    return; // in case there is no user
                });
            }

            function _openLoyaltyMembershipExpiredDialog(loyaltyClubDriver, data, loyaltyClub, loyaltyClientConfig, renewPrice) {
                return dialog.show({
                    templateUrl: 'template/dialogs/loyalty-membership-expired/index.html',
                    styleClass: 'loyalty-membership-expired',
                    controllerAs: 'loyaltyWarningCtrl',
                    ariaLabelledby: 'membership_expired_dialog_title',
                    controller: ['$scope', function () {
                        var loyaltyWarningCtrl = this;
                        loyaltyWarningCtrl.loyaltyClubDriver = loyaltyClubDriver;
                        loyaltyWarningCtrl.userData = data;
                        loyaltyWarningCtrl.userLoyaltyClub = loyaltyClub;
                        loyaltyWarningCtrl.dateFormat = (config.isUs ? 'MM/dd/yyyy' : 'dd/MM/yyyy');
                        loyaltyWarningCtrl.isRenewActive = (loyaltyClientConfig && loyaltyClientConfig.isRenewActive);
                        loyaltyWarningCtrl.renewProductPrice = renewPrice;
                        loyaltyWarningCtrl.contactPhone = config.retailer.contactPhone;
                    }]
                }).then(function (showLoyaltyDialog) {
                    if (!showLoyaltyDialog) {
                        return _deleteLoyaltyClub(loyaltyClub.id);
                    }

                    if (showLoyaltyDialog == 'FindCustomer') {
                        return _deleteLoyaltyClub(loyaltyClub.id).then(function () {
                            return showLoyaltyClubDialog({
                                show: 'fadeIn' + (config.language.direction === 'rtl' ? 'Right' : 'Left'),
                                hide: 'zoomOut'
                            }, true);
                        });
                    }
                    if (showLoyaltyDialog == 'Renew') {
                        return _renewLoyaltyClub(loyaltyClubDriver, loyaltyClub);
                    }
                });
            }

            function openPromotionDialog(preventIfNotLoggedIn) {
                if (!isRetailerPromotionActive()) {
                    return;
                }

                if (!user.session.userId && config.retailer.promotion.isReference) {
                    return !preventIfNotLoggedIn && openLoginDialog().then(function () {
                        if (user.session.userId) {
                            return openPromotionDialog();
                        }
                    });
                }

                return dialog.show({
                    templateUrl: 'template/dialogs/promotion-dialog/index.html',
                    controller: 'PromotionDialogCtrl as promotionDialogCtrl',
                    styleClass: 'promotion-dialog',
                    ariaLabelledby: 'promotion_dialog_title'
                });
            }


            function _getWeighableProductUnits(line) {
                if (!line.product.productDisplayModeId) {
                    line.weighableProductUnits = line.weighableProductUnits || Math.round(line.quantity / line.product.weight);
                } else {
                    // quantity for unit-weight product
                    if (!line.weighableProductUnits) {
                        return line.quantity;
                    }
                }
                return line.weighableProductUnits;
            }

            function _getSoldBy(line) {
                if (!line.product.productDisplayModeId) {
                    return null;
                } else {
                    // set soldBy for unit-weight products
                    if (!line.weighableProductUnits) {
                        return $rootScope.PRODUCT_DISPLAY.WEIGHT.name;
                    } else {
                        return $rootScope.PRODUCT_DISPLAY.UNIT.name;
                    }
                }
            }

            function getCashbackLabel(label, total) {
                var rx = /\$\{(.*)\}/g;
                var arr = rx.exec(label);
                if (!arr || !arr.length) {
                    return label;
                }
                var resultNumber = _currencyFilter(Number(Number(arr[1]) * Number(total)));
                return label.replace('${' + arr[1] + '}', resultNumber);
            }

            function copyToClipboard(txt, event) {
                if (event) {
                    event.stopPropagation();
                }

                var input = document.createElement('input'),
                    copied;
                input.id = 'copy';
                input.value = txt;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                if (document.execCommand('copy')) {
                    copied = true;
                }
                document.body.removeChild(input);

                return copied;
            }

            function isRetailerPromotionActive() {
                if (!config.retailer.promotion) {
                    return false
                }

                if (config.retailer.promotion.eventType === $rootScope.RETAILER_PROMOTION_EVENTS.REGISTER && !isUserLoggedIn()) {
                    return false;
                }

                if (isUserLoggedIn() && user.data) {
                    var promotion = (user.data.eligiblePromotions || []).find(function (promotionId) {
                        return config.retailer.promotion.id === promotionId;
                    });

                    return !!promotion;
                }

                return true;
            }

            function isUserLoggedIn(){
                return !!user.session.userId;
            }

            function openUserPromotionDialog(promotion) {
                return dialog.show({
                    templateUrl: 'template/dialogs/user-promotion-dialog/index.html',
                    ariaLabelledby: 'user_promotion_dialog_title',
                    controller: ['$scope', function (scope) {
                        scope.promotion = promotion;
                    }],
                    styleClass: 'user-promotion-dialog'
                });
            }

            //the score allows us to know which status is more important
            //that way we will be able to know what is the current state of the order even is one of its aisle statuses is more important
            var ORDER_STATUSES_SCORES = {};
            _setStatusesScore();

            function _setStatusesScore() {
                var current = 0,
                    groups = [ORDER_STATUS_STAGES.RECEIVED, ORDER_STATUS_STAGES.IN_PROCESS, ORDER_STATUS_STAGES.READY, ORDER_STATUS_STAGES.FINISH, ORDER_STATUS_STAGES.CANCELLED];

                angular.forEach(groups, function(statusesGroup) {
                    angular.forEach(statusesGroup, function(status, statusId) {
                        ORDER_STATUSES_SCORES[statusId] = current++;
                    });
                });
            }

            function isOrderInStatuses(order, statusesMap) {
                if (!order) {
                    return false;
                }

                var orderStatus = order.statusId;
                if (order.aisleStatuses && order.aisleStatuses.length) {
                    for (var i = 0; i < order.aisleStatuses.length; i++) {
                        if (ORDER_STATUSES_SCORES[orderStatus] < ORDER_STATUSES_SCORES[order.aisleStatuses[i].status]) {
                            orderStatus = order.aisleStatuses[i].status;
                        }
                    }
                }

                return !!statusesMap[orderStatus];
            }

            function showEditOrderWarning(orderId) {
                return dialog.show({
                    templateUrl: 'template/dialogs/edit-order-warning/index.html',
                    styleClass: 'edit-order-waning-dialog',
                    ariaLabelledby: 'edit_order_warning_dialog_title'
                }).then(function (is) {
                    if (!is) {
                        return false;
                    }

                    Orders = Orders || $injector.get('Orders');
                    return Orders.editOrder(orderId).then(function () {
                        return true;
                    });
                });
            }

			function _getCart() {
				Cart = Cart || $injector.get('Cart');
                return Cart;
            }

            function editOrderWIthValidationIfOrderEditable(orderId){
                Orders = Orders || $injector.get('Orders');
                return Orders.checkIfUserCanUpdateOrder(orderId).then(function(response){
                    if(response && response.isCustomerEditBlocked){
                        var contentHeader = '',
                            contentTitle = 'Your order can no longer be changed';
                        contentHeader = 'We have started collecting your original order.';

                        showCommonDialog({
                            title: '{{\'Edit Order\' | translate}}',
                            content: '<div style="font-size: 0.9em;">{{contentHeader | translate}}<br/>' + '{{contentTitle | translate}}.</div>',
                            controller: ['$scope', function ($scope) {
                                $scope.contentHeader = contentHeader;
                                $scope.contentTitle = contentTitle;
                            }],
                            buttons: [{
                                text: '{{\'OK\' | translate}}',
                                click: '$dialog.hide()'
                            }]
                        });
                    }
                    else{
                        return editOrder(orderId);
                    }
                })
            }

            /**
             * @returns {boolean}
             */
            function checkIsPremiumEditOrderToggled() {
              if (
                !config ||
                !config.retailer ||
                !config.retailer ||
                !config.retailer.premiumFeaturesEnabled ||
                !config.retailer.settings
              ) {
                return false;
              }

              var PREMIUM_UPDATE_TIME_SLOT_ID = 25;
              var isEnablePremium = config.retailer.premiumFeaturesEnabled.includes(
                PREMIUM_UPDATE_TIME_SLOT_ID
              );

              var settings = config.retailer.settings;
              var isToggled = settings.changeTimeSlot && settings.changeTimeSlot.isActive;

              return isEnablePremium && isToggled;
            }

            /**
             * @param {number} orderId 
             * @param {boolean} isFromCart 
             * @returns {Promise<StateObject>}
             */
            function redirectWhenEditOrder(orderId, isFromCart) {
              if (isFromCart) {
                return $q.resolve();
              }

              if (["app.ordersHistory.order", "app.ordersHistory"].includes($state.current.name)) {
                return $state.go("app.ordersHistory.order", {
                  oid: orderId,
                });
              }

              var changeTimeSlot =
                config.retailer &&
                config.retailer.settings &&
                config.retailer.settings.changeTimeSlot;

              if (!changeTimeSlot) {
                return $q.resolve();
              }

              switch (changeTimeSlot.editOrderOpenOn) {
                case "HOME":
                  return $state.go("app.home");

                case "ORDER_DETAIL":
                  return $state.go("app.ordersHistory.order", {
                    oid: orderId,
                  });

                default:
                  return $q.resovle();
              }
            }

            /**
             * @param {number} orderId 
             * @param {object} options
             * @param {boolean} options.isFromCart
             * @returns dialog
             */
            function editOrder(orderId, options) {
                options = options || {};

                DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Monitor Window - Edit Order'}});

                if (checkIsPremiumEditOrderToggled()) {
                  return redirectWhenEditOrder(orderId, options.isFromCart).then(function () {
                    // ECOM-10733: need timeout -> fully redirect -> prevent redirect back when click something
                    $timeout(function () {
                        openEditOrderDialog("LAUNCH_EDIT", true, orderId);
                    }, 0);

                  });
                }

                return showEditOrderWarning(orderId);
            }

            function isBeforeTimeSlot(order){
                if (!order || !config) {
                    return false
                }
                var settings = config.retailer.settings;
                var maxHourForOrderUpdate = Number(settings.maxHourForOrderUpdate);
                var minutesBeforeTimeSlot = Number(settings.minutesBeforeTimeSlot);
                var now = new Date();
                var shippingTime = new Date(order.shippingTimeFrom)
                var timeBeforeMinutes = new Date(order.shippingTimeFrom).setMinutes(shippingTime.getMinutes() - (minutesBeforeTimeSlot || 0));
                var isBeforeTimeSlotStart =  timeBeforeMinutes.valueOf() > now.valueOf();
                var isBeforeMaxHour = maxHourForOrderUpdate > now.getHours()
                return isBeforeMaxHour || isBeforeTimeSlotStart;
            }

            function getPercentageChange(oldNumber, newNumber) {
                var decreaseValue = oldNumber - newNumber;
                return (decreaseValue / oldNumber) * 100;
            }

            function isAdditionalPaymentRequired(order) {
                var cart = _getCart();
                var updateOrderPolicy;
                var settings = config.retailer.settings;
                if (settings.updateOrderPolicy) {
                    updateOrderPolicy = JSON.parse(settings.updateOrderPolicy);
                }
                if (!order || !cart || !updateOrderPolicy) {
                    return false
                }

                var orderCharge = order.amountCharged || order.overallCharged || order.initialCheckoutCharge || order.totalAmount;
                var finalPriceForView = cart.total.finalPriceForView;

                var paymentData = order.paymentData;
                var mainPayment = paymentData.mainPayment;

                var currentPriceChange = finalPriceForView- orderCharge
                var currentChangeInPercentage = getPercentageChange(finalPriceForView, orderCharge)
                var paymentUpdatePolicy = updateOrderPolicy[mainPayment.paymentMethodId];

                // There is no threshold setting for the current order payment method
                if (!paymentUpdatePolicy){
                    return false;
                }

                var maximumOrderDelta = paymentUpdatePolicy.maximumOrderDelta;
                var percentageChangeInOrder = paymentUpdatePolicy.percentageChangeInOrder;

                var isPercentageChangeExceeded = currentChangeInPercentage > percentageChangeInOrder;
                var isMaximumOrderDeltaExceeded = currentPriceChange > maximumOrderDelta;

                // var remaining = cart.getRemainingPayment(order);

                return isPercentageChangeExceeded || isMaximumOrderDeltaExceeded;
            }

            function isPaymentAmountRemaining(order) {
                var cart = _getCart();
                if (!order || !config) {
                    return false
                }
                var settings = config.retailer.settings;
                var orderCharge = order.amountCharged || order.overallCharged || order.initialCheckoutCharge || order.totalAmount || 0;
                var remainingCharge = (cart.total.finalPriceWithTax + cart.total.serviceFee.finalPriceWithTax + cart.total.deliveryCost.finalPriceWithTax) - orderCharge;
                var paymentOnUpdateOrderMinSum = !!settings.paymentOnUpdateOrderMinSum && Number(settings.paymentOnUpdateOrderMinSum);
                return settings.paymentOnUpdateOrder === 'true' && remainingCharge > (paymentOnUpdateOrderMinSum || 0)
            }

            function isOrderEditable(order) {
                if (!order || !config || order.isCustomerEditBlocked) {
                    return false
                }
                var updateProcessingOrderEnabled = config.retailer.settings.allowUpdateOrder === 'true' && isOrderInStatuses(order, ORDER_STATUS_STAGES.IN_PROCESS) && (![ORDER_STATUSES.REGISTERED, ORDER_STATUSES.COLLECTED].includes(order.statusId));
                var settings = config.retailer.settings;
                var updateOrderType = Number(settings.updateOrderType);
                var isUpdateOrderByStatus = !updateOrderType || updateOrderType === ORDER_UPDATE_POLICY.ORDER_STATUS;
                var allowUpdateAfterOrderClosed = settings.allowUpdateAfterOrderClosed === 'true' && isOrderInStatuses(order, ORDER_STATUS_STAGES.READY)
                
                if (isUpdateOrderByStatus) {
                    return (isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED) || updateProcessingOrderEnabled) && order.branchDeliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICK_AND_GO && _addOffsetToNormalizedTime(order.shippingTimeTo) >= new Date();
                }

                return isBeforeTimeSlot(order) || allowUpdateAfterOrderClosed;

            }

            function showNotEditAbleDialog(order) {
                var contentHeader = '',
                    contentTitle = 'Your order can no longer be changed';
                if (isOrderInStatuses(order, ORDER_STATUS_STAGES.IN_PROCESS)) {
                    contentHeader = 'We have started collecting your original order.';
                } else if (isOrderInStatuses(order, ORDER_STATUS_STAGES.READY)) {
                    contentHeader = 'We have finished collecting your original order.';
                } else if (isOrderInStatuses(order, ORDER_STATUS_STAGES.CANCELLED)) {
                    contentHeader = 'This is a canceled order.';
                } else if (order.branchDeliveryTypeId == SP_SERVICES.DELIVERY_TYPES.PICK_AND_GO) {
                    contentHeader = 'This is a pick and go order';
                } else {
                    contentHeader = 'We have finished collecting your original order.';
                }
                showCommonDialog({
                    title: '{{\'Edit Order\' | translate}}',
                    content: '<div style="font-size: 0.9em;">{{contentHeader | translate}}<br/>' + '{{contentTitle | translate}}.</div>',
                    controller: ['$scope', function ($scope) {
                        $scope.contentHeader = contentHeader;
                        $scope.contentTitle = contentTitle;
                    }],
                    buttons: [{
                        text: '{{\'OK\' | translate}}',
                        click: '$dialog.hide()'
                    }]
                });
            }

            //For example order.shippingTimeTo is stored with offset 0. When we run 'new Date(order.shippingTimeTo)' the new date will be shifted by time zone.
            function _addOffsetToNormalizedTime(timeToOffset){
                var currentDate = new Date();
                var timeToOffsetAsDate = new Date(timeToOffset)
                var timeZoneOffset = currentDate.getTimezoneOffset();
                return new Date(timeToOffsetAsDate.getTime() + timeZoneOffset*60000)
            }

            function loyaltyClubAutoRenew(userData) {
                var loyaltyClubDriver = config.retailer.loyaltyClubDriver && config.retailer.loyaltyClubDriver;
                if (!userData || !userData.loyaltyClubs || !userData.loyaltyClubs.length || !config.retailer.loyaltyClubDrivers || !config.retailer.loyaltyClubDrivers.length) {
                    return;
                }

                api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/_auto-renew'
                }).then(function (response) {
                    if (response.isDeleted) {
                        var driverClientConfig = loyaltyClubDriver.clientConfig;
                        return $state.go(driverClientConfig && driverClientConfig.extendedLoyaltyClub ? 'app.userEdit.extendedLoyalty' : 'app.userEdit.loyalty');
                    }
                    if (!response.isChanged) {
                        return;
                    }

                    user.getData(true);
                });
            }

            function loyaltyClubAutoConnect() {
                var driver = (config.retailer.loyaltyClubDrivers || []).find(function (driver) {
                    return driver.isActive && driver.clientConfig.isAutoConnectActive;
                });

                if (!driver) {
                    return;
                }

                api.request({
                    method: 'POST',
                    url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/_auto-connect'
                });
            }

            function reload(force) {
                if (force === true || _getCart().sendingSucceeded) {
                    _forceReload = true;
                    location.reload();
                    return;
                }

                var listener = $rootScope.$on('cart.update.complete', function () {
                    listener();
                    reload();
                });

                // force reload if didn't after 5 seconds
                $timeout(function() {
                    listener();
                    reload(true);
                }, angular.isNumber(force) ? force : 5000);
            }

            /**
             * Display message when there are no available delivery slots at all
             * @public
             *
             * @param {Boolean} isForce
             */
            function showDeliveryNoAvailableSlotsPopup(isForce) {
                var data = _getDeliveryNoAvailableSlotsMessage(isForce);

                if(!data) {
                    return $q.resolve();
                } else {
                    if(!isForce) $rootScope.deliveryNoAvailableSlotsShown = true;

                    return dialog.show({
                        templateUrl: 'template/dialogs/delivery-no-slots-popup/index.html',
                        disableClosing: true,
                        showClose: true,
                        controller: ['$scope', function ($scope) {
                            $scope.title = data.title;
                            $scope.text = (data.text || '').replace(/\n/g, '<br />');
                            $scope.cancel = function () {
                                dialog.hide();
                            };
                        }]
                    });
                }
            }

            function _getDeliveryNoAvailableSlotsMessage(isForce) {
                var currentLanguageId = config.language.id,
                    defaultLanguageId = config.retailer.languageId,
                    popupEnabled = config.retailer.settings.shippingNoAvailableSlotsPopupEnable,
                    messagesArr = config.retailer.shippingNoAvailableSlotsMessages,
                    messagesObj = {},
                    existsLanguageId = null;

                //== Disabled in backend or displayed already for this site refresh
                if(!popupEnabled) {
                    return null;
                }

                //== Disabled in backend or displayed already for this site refresh
                if($rootScope.deliveryNoAvailableSlotsShown && !isForce) {
                    return null;
                }

                //== Convert object received from API to Associated Array (Object) by language ID
                angular.forEach(messagesArr, function(message) {
                    if(message.languageId) {
                        existsLanguageId = message.languageId;
                        messagesObj[message.languageId] = {
                            title: message.title,
                            text: message.text
                        }
                    }
                });

                //== check if message exists on current selected language or on Retailer's default language
                if(messagesObj[currentLanguageId]) {
                    return messagesObj[currentLanguageId];
                } else if (messagesObj[defaultLanguageId]) {
                    return messagesObj[defaultLanguageId];
                }

                //== return the existing message or null
                return existsLanguageId && messagesObj[existsLanguageId] || null;
            }

            function createSearchList() {
                $rootScope.linksList = [];

                $timeout(function () {
                    var toolbarElement = angular.element(document.querySelector('body > header > .toolbar > .data'));
                    if (toolbarElement) {
                        _setLinksToList(toolbarElement)
                    }

                    var navBarElement = angular.element(document.querySelector('body > header > .menu > .menu-navigation-bar'));
                    if (navBarElement) {
                        _setLinksToList(navBarElement);
                    }

                    var footerElement = angular.element(document.querySelector('body > footer .retailer-links'));
                    if (footerElement) {
                        _setLinksToList(footerElement);
                    }
                });
            }

            function _setLinksToList(parentElement) {
                var baseUrl = $location.url() !== '/' ? $location.absUrl().replace($location.url(), '') : $location.absUrl(),
                    linkElements = parentElement && parentElement.length && parentElement[0].querySelectorAll('a, [ui-sref], [href]');
                angular.forEach(linkElements || [], function (element) {
                    var textContentFilter = ((element.childNodes && Array.from(element.childNodes)) || []).filter(function (child) {
                        return !!child.textContent && !!child.textContent.trim();
                    });
                    var altFilter = ((element.childNodes && Array.from(element.childNodes)) || []).filter(function (child) {
                        return  !!child.alt && !!child.alt.trim();
                    });

                    var text = (element.outerText && !!element.outerText.trim() && element.outerText.trim()) || (element.textContent && !!element.textContent.trim() && element.textContent.trim()) ||
                        (element.title && !!element.title.trim() && element.title.trim()) || (textContentFilter.length && textContentFilter[0].textContent) || (altFilter.length && altFilter[0].alt);

                    if (!!text && element.href) {
                        $rootScope.linksList.push({
                            text: text,
                            href: element.href.includes(baseUrl) ? element.pathname : element.href
                        })
                    }
                });
            }

            function getDateTimeFormat() {
                return config.isUs ? 'MM/dd/yyyy h:mma' : 'dd/MM/yyyy HH:mm'
            }

            function getDateFormat() {
                return config.isUs ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
            }

            function getTimeFormat() {
                return config.isUs ? 'h:mma' : 'HH:mm'
            }

            function getCurrentRoute() {
                return $location.path();
            }

            function goToHref(href) {
                if (!href) return;

                if (href.indexOf('/') === 0) {
                    return $location.url(href);
                }

                $window.open(href, '_blank');
            }

            function getUserAddress() {
                return User.getData().then(function (data) {
                    var address = data.addresses[0];
                    if (!address || !address.text1 || !address.city) {
                        return $q.reject(null);
                    }

                    if (Config.isZipCodeArea && Config.area && !address.zipCode) {
                        address.zipCode = Config.area;
                    }

                    return {
                        address: {
                            text1: address.text1,
                            street: address.street,
                            houseNumber: address.houseNumber,
                            entry: address.entry,
                            city: address.city,
                            zipCode: address.zipCode
                        }
                    }
                })
            }

            function compileUserAddress() {
                if (user.data.addresses && user.data.addresses.length) {
                    var userAddress = user.data.addresses[0];
                    return ((userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '') +
                        (userAddress.city ? ' ' + userAddress.city : '') +
                        (userAddress.country ? ' ' + userAddress.country : '')).trim();
                }
            }

            function isSingleItemSpecial(special) {
                return !!special.item && !special.levels[0].gifts.every(function(gifts) {
                    return special.levels[0].purchases.every(function(purchases) {
                        return gifts.products && purchases.products && JSON.stringify(gifts.products) !== JSON.stringify(purchases.products);
                    });
                });
            }

            function getCountries() {
                if(Countries && Countries.countries) {
                    return Object.entries(Countries.countries).map(function(row) {
                        row[1].isoCode = row[0];
                        return row[1];
                    });
                }
                return [];
            }

            function getCountryAutocompleteOptions() {
                var countries = getCountries();
                var ukCountryNames = ['England', 'Scotland', 'Wales', 'Northern Ireland'];
                if(!countries || !countries.length) {
                    return [];
                }
                var countryNames = countries.map(function (country) {
                    return country.name;
                });
                countryNames = countryNames.concat(ukCountryNames);
                return countryNames.sort();
            }

            function checkAddressFields(address) {
                var addressFields = address || {};
                return ['city', 'text1', 'zipCode', 'country'].some(function (item) {
                   return !!addressFields[item]
                })
            }

            function removeErrorsFromInputs() {
                var errorElements = Array.from(document.querySelectorAll('.sp-inline-error.shown'));
                var invalidInputs = Array.from(document.querySelectorAll('.sp-inline-error-invalid'));
                Array.prototype.push.apply(errorElements, invalidInputs);
                if (errorElements && errorElements.length) {
                    errorElements.forEach(function (element) {
                        angular.element(element).removeClass('shown sp-inline-error-invalid');
                    })
                }
            }

            function getAddressFromAutocomplete(result) {
                if(!result || !result.address_components || !result.address_components.length) {
                    return {};
                }
                var address = {};
                result.address_components.forEach(function (item) {
                    if(item.types && item.types.length) {
                        item.types.forEach(function (area) {
                            switch (area) {
                                case GOOGLE_ADDRESS_TYPES.COUNTRY:
                                    address.country = item.long_name
                                    break;
                                case GOOGLE_ADDRESS_TYPES.LOCALITY:
                                    address.city = item.long_name
                                    break;
                                case GOOGLE_ADDRESS_TYPES.POSTAL_TOWN:
                                    address.city = item.long_name
                                    break;
                                case GOOGLE_ADDRESS_TYPES.POSTAL_CODE:
                                    address.zipCode = item.long_name
                                    break;
                                case GOOGLE_ADDRESS_TYPES.ROUTE:
                                    address.text1 = item.long_name
                                    address.text2 = item.long_name
                                    break;
                            }
                        })
                    }
                });
                if (!address.country && config.retailer.settings.defaultShippingCountry) {
                    address.country = config.retailer.settings.defaultShippingCountry;
                }
                return address;
            }

            function getAddressByZipCode(zipCode) {
                return api.request({
                    method: 'GET',
                    url: '/v2/zipcode-providers/:rid/address',
                    params: {
                        zipCode: zipCode,
                        languageId: config.retailer.languageId
                    }
                }).then(function (results) {
                    if(!results) {
                        return [];
                    }

                    angular.forEach(results.results, function (address) {
                        var fixedAddress = address.street + ', ' + address.city + ' ' + zipCode + ', ' + address.country;
                        address.text1 = address.street;

                        if(address.buildingNumber) {
                            fixedAddress = address.buildingNumber + ', ' + fixedAddress;
                            address.text1 = address.buildingNumber + ' ' + address.text1;
                        }
                        if(address.buildingName) {
                            fixedAddress = address.buildingName + ', ' + fixedAddress;
                            address.text1 = address.buildingName + ', ' + address.text1;
                        }
                        if(address.subBuildingName) {
                            fixedAddress = address.subBuildingName + ', ' + fixedAddress;
                            address.text1 = address.subBuildingName + ', ' + address.text1;
                        }

                        address.description = fixedAddress;
                        address.houseNumber = "";//address.buildingNumber;
                    });

                    return results.results;
                })
            }

            function getCountryCode(countryName) {
                if (countryName) {
                    var countries = getCountries();
                    var country = countries.find(function (cnt) {
                        return cnt.name.toLowerCase() === countryName.toLowerCase();
                    })
                    return country && country.isoCode ? country.isoCode : null;
                }
                return null;
            }

            function checkForDeliveryPickupServiceFee(lines) {
                var area;
                try {
                    area = $rootScope.config.getBranchArea() || {}
                } catch (e) {
                    // do nothing
                }
                if (area) {
                    var linesArr = lines && typeof lines === 'object' ? Object.values(lines) : lines && Array.isArray(lines) ? lines : [];

                    return !!(linesArr.some(function (line) {
                            return line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY || line.type == SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE
                        }) && !!(area.retailerBranchProductDeliveryPrice || area.retailerProductDeliveryPrice) && linesArr.filter(function (line) {
                            return !(line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY || line.type == SP_SERVICES.CART_LINE_TYPES.SERVICE_FEE) && line.quantity;
                        }).length);
                }
            }

            function showCouponDescription() {
                return ($rootScope.config.retailer.loyaltyClubDrivers || []).find(function (driver) {
                    return driver.loyaltyClubDriverId === LOYALTY_CLUB_DRIVERS.BIRDZI;
                });

            }

            function convertArrayToObject(list, fieldName) {
                var arrayMap = {};
                list.forEach(function (row) {
                    arrayMap[row[fieldName]] = row;
                });

                return arrayMap;
            }

            function cleanUserAddressObject(userAddress) {
                delete userAddress.buildingName;
                delete userAddress.buildingNumber;
                delete userAddress.subBuildingName;
            }

            function getUserCashbackPoints(loyaltyClubId, pointsDecimalRound) {
                return api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/users/:uid/loyalty-clubs/' + loyaltyClubId + '/_points'
                }).then(function (response) {
                    response.points = Number.isInteger(response.points) ? response.points : response.points.toFixed(pointsDecimalRound);
                    return response;
                });
            }

            function isLoyaltyPremiumPackageEnabled() {
                var LOYALTY_PREMIUM_PACKAGE_ID = 16;
                return config.retailer.premiumFeaturesEnabled.includes(LOYALTY_PREMIUM_PACKAGE_ID);
            }

            function setGlobalCouponRedemptions(coupons) {
                if( !(coupons && Array.isArray(coupons) && coupons.length) || $rootScope.couponRedemptionsInProgress) {
                    return;
                }

                $rootScope.couponRedemptionsInProgress = true;
                var redemptions = {};
                coupons.forEach(function(coupon) {
                    if(coupon.remainingredemptions && coupon.remainingredemptions > 1 && coupon.special && coupon.id) {
                        redemptions[coupon.id] = coupon.remainingredemptions; // This parameter we get from Birdzi for each coupon
                    }
                });

                $rootScope.couponRedemptions = redemptions;
                $rootScope.couponRedemptionsInProgress = false;
            }

            function checkPopupDisabledUrls(currentRoute, disabledUrlsText) {
                if (currentRoute === '\/' || !disabledUrlsText) {
                    return false;
                }

                return disabledUrlsText.trim().includes(currentRoute);
            }

            function _convertToHtmlToCheckEmpty(text){
                var aux = document.createElement('div');
                aux.innerHTML = text; //parses the html
               return  aux.innerText.trim();
            }

            /**
             * @param {TabName} stepName
             * @param {boolean} isCancelOnClose 
             * @param {number} orderId 
             * @returns 
             */
            function openEditOrderDialog(stepName, isCancelOnClose, orderId) {
                if(!orderId) {
                    throw new Error('Missing orderId');
                }

                if(typeof isCancelOnClose !== 'boolean') {
                    throw new Error('Missing isCancelOnClose');
                }

                return SpDialogUrlManager.setDialogParams({
                    updateOrderV2: '1',
                    step: stepName,
                    isCancelOnClose: isCancelOnClose ? isCancelOnClose : false,
                    orderId: orderId
                });
            }

            /**
             * ECOM-7371
             * @param {*} num
             * @returns {number | null}
             */
            function roundNumber(num) {
              if (isNaN(num)) {
                return null;
              }

              return Math.round(num * 100) / 100;
            }


            /**
             * Display as UTC timezone so don't need to convert
             * @param {Date | string} shippingTimeFrom // saved as UTC timezone but it's actually retailer timezone
             * @returns {Date | null} // UTC timezone
             */
            function calculateLastUpdateOrderDateTime(shippingTimeFrom) {
              if (!shippingTimeFrom || !checkIsPremiumEditOrderToggled()) {
                return null;
              }

              var canUpdateBeforeHours =
                +config.retailer.settings.changeTimeSlot.canUpdateBeforeHours;

              if (isNaN(canUpdateBeforeHours)) {
                return null;
              }

                var shippingTimeFromMs = new Date(shippingTimeFrom).getTime();
                var configBeforeMs = canUpdateBeforeHours * 60 * 60 * 1000;

                var lastUpdateDateTime =  new Date(shippingTimeFromMs - configBeforeMs);

                return lastUpdateDateTime;
            }

            /**
             * @param {*} orderTimePlaced 
             * @return {Date | null}
             */
            function calculatePostponeUpdateDateTime(orderTimePlaced) {
              var MINUTE_IN_MS = 1000 * 60;
              var DAY_IN_MS = MINUTE_IN_MS * 60 * 24;

              var maxPostponeDays =
                (config.retailer.settings.changeTimeSlot &&
                  config.retailer.settings.changeTimeSlot.maxPostponeDays) ||
                1;

              var postponeUpdateDateTime = new Date(
                new Date(orderTimePlaced).getTime() + DAY_IN_MS * maxPostponeDays
              );

              return postponeUpdateDateTime;
            }

            /**
             * @param {number} deliveryTypeId 
             * @returns {'' | 'delivery' | 'pickup'}
             */
            function getDeliveryTypeText(deliveryTypeId){
                switch(deliveryTypeId){
                    case SP_SERVICES.DELIVERY_TYPES.DELIVERY:
                    case SP_SERVICES.DELIVERY_TYPES.EXPRESS_DELIVERY:
                        return 'delivery'

                    case SP_SERVICES.DELIVERY_TYPES.PICKUP:
                    case SP_SERVICES.DELIVERY_TYPES.PICK_AND_GO:
                    case SP_SERVICES.DELIVERY_TYPES.SCAN_AND_GO:
                        return 'pickup'

                    default:
                        return ''
                }
            }

            /**
             * Check logic to enable button update edit timeslot. Assume that we already check can update order items previously when open popup
             * @param {Order} order Refer type Order at launch-edit-order.js
             * @returns 
             */
            function checkCanUpdateTimeSlot(order) {
              if (!checkIsPremiumEditOrderToggled()) {
                return false;
              }

              var lastUpdateDateTime = calculateLastUpdateOrderDateTime(order.shippingTimeFrom);

              if (!lastUpdateDateTime) {
                return false;
              }

              var curTime = new Date()
              
              // RETAILER TIMEZONE = USER TIMEZONE
              // lastUpdateDateTime is at UTC time. Need to convert to retailer timezone
              var lastUpdateTimeInRetailerTimezone = _addOffsetToNormalizedTime(lastUpdateDateTime);
              var isBeforeLastUpdateTime = curTime < lastUpdateTimeInRetailerTimezone;

              var isNotCollected = isOrderInStatuses(order, ORDER_STATUS_STAGES.RECEIVED);

              var isBeforePostponeUpdateTime = curTime < calculatePostponeUpdateDateTime(order.timePlaced);

              var isRegularDeliveryTimeSlot =
                order.deliveryTimeTypeId === DELIVERY_TIMES_TYPES.REGULAR;

              return isBeforeLastUpdateTime && isNotCollected && isRegularDeliveryTimeSlot && isBeforePostponeUpdateTime;
            }

            /**
             * @param {Record<LanguageEnum, string>} text
             * @param {string} defTxtKey
             * @param { {key: string, value: string}[] =} keyValParams
             * @return {string}
             */
            function getTextByLangAndReplaceParams(text, defTxtKey, keyValParams) {
              text = text || {}

              var extractedText =
                angular.copy(text[$rootScope.config.language.culture]) ||
                _translateFilter(defTxtKey);

              var replacedText = extractedText;

              if (keyValParams) {
                for (var i = 0; i < keyValParams.length; i++) {
                  replacedText = replacedText.replace(keyValParams[i].key, keyValParams[i].value);
                }
              }

              return replacedText;
            }

            /**
             * @return {boolena}
             */
            function checkIsCalculateCheckoutTime(){
                return [
                  CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_POS,
                  CHARGE_SPECIALS_CALCULATION_TIME.CHECKOUT_SP,
                ].includes(config.retailer.settings.specialsCalculationTime);
            }
            
            /*
             * Check if the user can update order time slot. This method returns true if the user can update order items and the order is in edit mode.
             * @returns {boolean} true if the user can update order time slot, false otherwise
             */
            function isEnableUpdateTimeSlot() {
                Orders = Orders || $injector.get('Orders');
  
                return checkIsPremiumEditOrderToggled() && Orders.orderInEdit;
            }

            /**
             * Shows the finish dialog for update order V2
             * @param {number} orderId
             * @return {Promise<boolean>}
             */
            function showFinishUpdateDialog(orderId) {
                return dialog.show({
                    templateUrl: 'template/dialogs/update-order-v2/finish/index.html',
                    controller: ['$rootScope', '$scope', 'Dialog', function ($rootScope, $scope, dialog) {
                        $scope.dialog = dialog;
                        $scope.orderId = orderId;
                    }]
                }).then(function (result) {
                    if (result) {
                        $state.go('app.home')
                    }
                });
            }

            /**
             * Closes the update order v2 dialog if existed.
             * @return {Promise} - resolved when the dialog is closed
             */
            function closeUpdateDialog(delayTime){
                var isUpdateDialogOpenning = !!document.querySelector('.update-order-v2');
                if(!isUpdateDialogOpenning){
                    return $q.resolve();
                }
                var p = $q.defer();
                $rootScope.$emit('order.update.timeslot.close');
                
                delayTime = delayTime || 1000;
                $timeout(function(){
                    p.resolve();
                }, delayTime);
                return p.promise;
            }
            
            function updateOrderV2backToHomePage() {
                closeUpdateDialog(2000).then(function(){
                    $state.go('app.home');
                });
            }
           
            /**
             * Retrieves new time slot data from local storage.
             * @param {number} orderId
             * @param {number} cartId
             * @returns new time slot data
             */
            function getNewTimeSlotData(orderId, cartId) {
                var localStorageTimeslotDataId = 'updateTimeSlot-orderId-' + orderId + '-cartId-' + cartId;
                var timeSlotData = LocalStorage.getItem(localStorageTimeslotDataId);
                
                return timeSlotData;
            }

            function getTranslatedFullDateTime(dateTime){
              var dateTimeFormat = $rootScope.config.isUs ? "MM/dd hh:mm a" : "dd/MM HH:mm";
              var dayOfWeek = dateFilter(dateTime, "EEEE", "UTC");
              var transDayOfWeek = _translateFilter(dayOfWeek);

              var transTimeCalculate = dateFilter(dateTime, dateTimeFormat, "UTC");

              return transDayOfWeek + ", " + transTimeCalculate;
            }

            function isInternalUrl(url) {
                try {
                    if (url.startsWith('/')) {
                        url = location.protocol + '//' + location.host + url;
                    }
                    return new URL(url).origin == location.origin;
                } catch (error) {
                    return false;
                }
            }

            angular.element($window).bind('beforeunload', function (event) {
                if (_forceReload || _getCart().sendingSucceeded) {
                    return;
                }

                var message = $filter('translate')('Changes to your cart will be lost. Are you sure you want to leave?');
                event.returnValue = message;
                return message;
            });
        }]);

})(angular, app);
