(function (angular, app) {
    'use strict';

    /**
     * @typedef {Object} CheckDuplicateOrderBody
     * @property {number} cartId
     * @property {number} areaId
     * @property {number} deliveryTimeId
     * @property {number} total
     * @property {string} [metaData]
     */

    /**
     * @typedef {Object} DuplicatedOrderRes
     * @property {number} orderId
     * @property {number} TotalAmount
     * @property {number} AreaDeliveryTime_Id
     * @property {boolean} orderExists
     */

    /**
     * @typedef {Object} CheckoutRes
     * @property {number} id
     * @property {boolean} isCreditCardLoyalty
     */

    /**
     * @typedef {Object} CheckoutOptionsBody
     * @property {number} cartId
     * @property {number} areaId
     * @property {number} [languageId]
     * @property {string} phoneNumber
     * @property {string} friendlyName
     * @property {Object} address
     * @property {string} address.city
     * @property {string} address.text1
     * @property {string} [address.text2]
     * @property {string} [address.street]
     * @property {string} [address.houseNumber]
     * @property {string} [address.entry]
     * @property {string} [address.zipCode]
     * @property {Array<CheckoutPayment>} [payments]
     * @property {string} taxSaved
     * @property {number} [deliveryTimeId]
     * @property {string} [shippingCarrierCode]
     * @property {string} [shippingServiceCode]
     * @property {boolean} asapPreference
     * @property {number} substitutePreferences
     * @property {boolean} leaveByTheDoor
     * @property {string} ipAddress
     * @property {UserToken} token
     * @property {Array<string>} giftCards
     * @property {string} [comments]
     * @property {AreasRequestOptions} areasRequestOptions
     * @property {GoogleGeoCoordinates} geoCoordinates
     * @property {string} [externalPlaceId]
     * @property {Transaction} [transaction] (sequelize transaction)
     * @property {mongoose.ClientSession} [mongoSession] (mongoose session)
     * @property {DeliveryService} [deliveryService]
     * @property {Object} phoneNumberObject
     * @property {string} customValidatedPhoneNumber
     * @property {number} [retries]
     */

    var BIT_IFRAME_WRAPPER_SELECTOR = '#bit-payment-button-container',
        BIT_IFRAME_SELECTOR = BIT_IFRAME_WRAPPER_SELECTOR + ' iframe',
        PAYMENT_IFRAME_ID = 'payment_iframe';

    var RETURN_TO_LOYALTY_CARD_ERROR = new Error('Canceled');

    app
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('app.checkout.summary', {
                url: '/summary',
                metaTags: {
                    title: ['$filter', function ($filter) {
                        return ($filter('translate')('Checkout'));
                    }]
                },
                views: {
                    'checkoutTab': {
                        templateUrl: 'template/views/checkout/summary/index.html',
                        controller: 'CheckoutSummaryCtrl as summaryCtrl',
                        resolve: {
                            creditCardWindowType: ['Config', function(Config) {
                                return Config.initPromise.then(function() {
                                    if (Config.branch && Config.branch.creditCardWindowType) {
                                        return Config.branch.creditCardWindowType;
                                    }

                                    return Config.retailer.creditCardWindowType;
                                });
                            }]
                        }
                    }
                }
            });
        }])
        .controller('CheckoutSummaryCtrl', [
            '$scope', '$rootScope', '$window', '$state', '$filter', '$timeout', '$q', 'Dialog', 'Api', 'Config',
            'Util', 'Cart', 'User', 'LocalStorage', 'Loading', 'SpDeliveryAreasService', 'PaymentsService',
            'creditCardWindowType', 'PHONE_TYPES', 'CREDIT_CARD_MODES', 'SP_SERVICES', 'SP_PAYMENTS',
            'RETAILER_PROMOTION_EVENTS', 'CREDIT_CARD_TYPES', 'ORGANIZATION_TYPES', 'CREDIT_CARD_3DS_MODES', 'DataLayer', 'GoogleMapService',
            function ($scope, $rootScope, $window, $state, $filter, $timeout, $q, dialog, api, Config,
                      util, cart, user, LocalStorage, loading, SpDeliveryAreasService, PaymentsService,
                      creditCardWindowType, PHONE_TYPES, CREDIT_CARD_MODES, SP_SERVICES, SP_PAYMENTS,
                      RETAILER_PROMOTION_EVENTS, CREDIT_CARD_TYPES, ORGANIZATION_TYPES, CREDIT_CARD_3DS_MODES, DataLayer, GoogleMapService) {
                var summaryCtrl = this,
                    checkoutCtrl = $scope.checkoutCtrl,
                    _ebtEligibleListener,
                    _firstTimeAfterInit = false,
                    _roundCurrencyFilter = $filter('roundCurrency'),
                    LOYALTY_PREMIUM_PACKAGE = 16,
                    _addressComponents = ['city', 'text1'],
                    _previousFullDeliveryAddress = null,
                    _defaultGiftCardExclusionMessage = {
                        "he": "לתשומת ליבך: ישנם פריטים ממחלקות מסוימות שאינם ניתנים לרכישה בכרטיס המתנה ויחויבו בכרטיס האשראי שלך.",
                        "en": "Please note: There are items from certain departments that cannot be purchased with the gift card and will be charged to your credit card.",
                        "ar": "يرجى ملاحظة: هناك عناصر من أقسام معينة لا يمكن شراؤها ببطاقة الهدية وسيتم خصمها من بطاقتك الائتمانية.",
                        "ru": "Обратите внимание: некоторые товары из определенных отделов нельзя купить с помощью подарочной карты, и они будут списаны с вашей кредитной карты.",
                        "es": "Ten en cuenta: Hay artículos de ciertos departamentos que no se pueden comprar con la tarjeta de regalo y se cargarán a tu tarjeta de crédito."
                    },
                    _addressWithoutGeoInfo = null;

                if (!checkoutCtrl.detailsData) {
                    $state.go('app.checkout.details');
                    return;
                }
                summaryCtrl.showAddCardDialog = showAddCardDialog;
                summaryCtrl.chooseCreditCard = chooseCreditCard;
                summaryCtrl.showAddEBTCardDialog = showAddEBTCardDialog;
                summaryCtrl.showAddTavPlusCardDialog = showAddTavPlusCardDialog;
                summaryCtrl.removeCard = removeCard;
                summaryCtrl.openPayPalWindow = openPayPalWindow;
                summaryCtrl.openICreditMasterPassWindow = openICreditMasterPassWindow;
                summaryCtrl.initBitButton = initBitButton;
                summaryCtrl.bindIFrameLoad = bindIFrameLoad;
                summaryCtrl.isShowCheckoutButton = isShowCheckoutButton;
                summaryCtrl.onPaymentMethodChanged = onPaymentMethodChanged;
                summaryCtrl.onEBTAmountChange = onEBTAmountChange;
                summaryCtrl.openTavPlusExampleDialog = openTavPlusExampleDialog;
                summaryCtrl.onTavPlusAmountChange = onTavPlusAmountChange;
                summaryCtrl.redeemedNow = redeemedNow;
                summaryCtrl.unredeemed = unredeemed;
                summaryCtrl.openGiftCardsPopup = openGiftCardsPopup;
                summaryCtrl.presentLoyaltyPointsComponent = presentLoyaltyPointsComponent;
                summaryCtrl.removeRedeemedGiftCard = removeRedeemedGiftCard;
                summaryCtrl.creditCardDisplay = Config.retailer.settings && Config.retailer.settings.creditCardDisplay && JSON.parse(Config.retailer.settings.creditCardDisplay);

                summaryCtrl.errorState = {}
                summaryCtrl.primaryPayments = [];
                summaryCtrl.secondaryPayments = [];
                var requiredCvvGiftCards = [SP_PAYMENTS.PAYMENT_METHODS.IDS.NOFSHONIT];

                checkoutCtrl.isSummaryTab = true;
                checkoutCtrl.checkout = checkout;
                checkoutCtrl.checkoutCreditCustomer = checkoutCreditCustomer;
                checkoutCtrl.giftCardExclusionMessage = Config.retailer.giftCardExclusion && Config.retailer.giftCardExclusion.message ? Config.retailer.giftCardExclusion.message : _defaultGiftCardExclusionMessage;
                checkoutCtrl.pickupAddress = SpDeliveryAreasService.getAreaAddressText(checkoutCtrl.area,true,Config.branch)
                var ebtBranchSettings = Config.retailer.settings.eBTBranchSettings ? JSON.parse(Config.retailer.settings.eBTBranchSettings) : {};
                summaryCtrl.ebtBranchSettings = ebtBranchSettings;
                summaryCtrl.ebtBranchSettings.branchId = Config.branch.id;
                summaryCtrl.compiledUserAddress = util.compiledUserAddress();
                summaryCtrl.isCvvShownForCheckout = typeof(Config.retailer.settings.isCvvShownForCheckout) === 'undefined' || Config.retailer.settings.isCvvShownForCheckout === 'true';
                summaryCtrl.isLoyaltyPackageEnabled = Config.retailer.premiumFeaturesEnabled.includes(LOYALTY_PREMIUM_PACKAGE);
                summaryCtrl.deliveryAreaMethod = Config.retailer.deliveryAreaMethod;
                summaryCtrl.userCashbackLoyalty = user.data.loyaltyClubs && user.data.loyaltyClubs.length && user.data.loyaltyClubs.find(function(club) {
                    return _isCashbackLoyaltyClub(club.loyaltyClubId);
                });
                if(summaryCtrl.userCashbackLoyalty) {
                    summaryCtrl.isRedeemedNow = false;
                    _setCashbackLoyaltyClub(summaryCtrl.userCashbackLoyalty.loyaltyClubId);
                }

                if(summaryCtrl.activeCashbackLoyalty) {
                    util.getUserCashbackPoints(summaryCtrl.userCashbackLoyalty.id, summaryCtrl.activeCashbackLoyalty.pointsDecimalRound).then(function (response) {
                        summaryCtrl.userCashbackLoyalty.points = response.points || 0;
                        summaryCtrl.userCashbackLoyalty.pointsToken = response.token;

                        if(!summaryCtrl.activeCashbackLoyalty.isMinPointsRequired || (summaryCtrl.activeCashbackLoyalty.isMinPointsRequired && summaryCtrl.userCashbackLoyalty.points &&
                            summaryCtrl.userCashbackLoyalty.points >= summaryCtrl.activeCashbackLoyalty.minPointsNum)) {
                            summaryCtrl.userPointsToCashback = (summaryCtrl.userCashbackLoyalty.points / summaryCtrl.activeCashbackLoyalty.pointsToCashbackAmount) *
                                summaryCtrl.activeCashbackLoyalty.cashbackAmount;
                        }
                        summaryCtrl.precentsPointsOutOfMin = summaryCtrl.activeCashbackLoyalty.isMinPointsRequired && summaryCtrl.userCashbackLoyalty.points &&
                            summaryCtrl.userCashbackLoyalty.points < summaryCtrl.activeCashbackLoyalty.minPointsNum ? summaryCtrl.userCashbackLoyalty.points / summaryCtrl.activeCashbackLoyalty.minPointsNum * 100 : 0;
                        summaryCtrl.isUsedCashbackPoints = summaryCtrl.activeCashbackLoyalty && summaryCtrl.activeCashbackLoyalty.pointsToCashback && summaryCtrl.userCashbackLoyalty.redeemedPointsAmount &&
                            summaryCtrl.isLoyaltyPackageEnabled && (!summaryCtrl.activeCashbackLoyalty.isMinPointsRequired ||
                                (summaryCtrl.activeCashbackLoyalty.isMinPointsRequired && summaryCtrl.userCashbackLoyalty.points >= summaryCtrl.activeCashbackLoyalty.minPointsNum));

                        _setAvailablePointsAndCashback();
                    });
                }
                _setGiftCardPopupSettings();

                cart.forceUpdate().then(function() {
                    var cartLines = cart.getLines();
                    var cartLineProducts = [];
                    var cartLineCoupons = [];
                    if(cartLines && Array.isArray(cartLines) && cartLines.length) {
                        angular.forEach(cartLines, function (cartLine) {
                            if(cartLine && cartLine.product && cartLine.product.id) {
                                cartLine.product.quantity = cartLine.quantity;
                                cartLineProducts.push(cartLine.product);

                                if(cartLine.type === SP_SERVICES.CART_LINE_TYPES.COUPON) {
                                    cartLineCoupons.push(cartLine.product.localName || cartLine.product.id);
                                }
                            }
                        });
                    }
                    DataLayer.push(DataLayer.EVENTS.CHECKOUT, {products: cartLineProducts, data: {step: 3, option: 'payment details'}});

                    summaryCtrl.tipsWasRemoved = cart.tipsWasRemoved;
                    summaryCtrl.removeTipsOnPickUpText = Config.retailer.settings.removeTipsOnPickUpText ? JSON.parse(Config.retailer.settings.removeTipsOnPickUpText) : {};
                    checkoutCtrl.dataLayerCartProducts = angular.copy(cartLineProducts);
                    checkoutCtrl.deliveryType = checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.DELIVERY ? 'Delivery' : 'Pickup';
                    checkoutCtrl.taxAmount = parseFloat((cart.total.tax).toFixed(2));
                    checkoutCtrl.shippingCost = parseFloat((cart.total.deliveryCost.finalPriceWithTax || cart.total.deliveryCost.priceWithTax).toFixed(2));
                    checkoutCtrl.grandTotal = parseFloat((cart.total.finalPriceWithTax + cart.total.serviceFee.finalPriceWithTax + cart.total.deliveryCost.finalPriceWithTax).toFixed(2));
                    checkoutCtrl.couponData = cartLineCoupons[0] || '';

                    if(summaryCtrl.activeCashbackLoyalty) {
                        _setAvailablePointsAndCashback();
                    }

                    _setLoyaltySavingDriverName();
                });

                var currentDeliveryTypeId = checkoutCtrl.area.deliveryTypeId;
                checkoutCtrl.isRememberCreditCardsMode =
                    Config.creditCardConfig.mode === CREDIT_CARD_MODES.REMEMBER_CARDS && !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function(method) {
                        return _isCreditCard(method.name) && currentDeliveryTypeId === method.branchDeliveryTypeId;
                    }).length;

                checkoutCtrl.isCreditCard3DSActive = checkoutCtrl.isRememberCreditCardsMode && !!Config.creditCardConfig.creditCard3DSModes[CREDIT_CARD_3DS_MODES.ON_PAYMENT];

                checkoutCtrl.hasPaypalOption = !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (method) {
                    return method.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL && currentDeliveryTypeId === method.branchDeliveryTypeId;
                }).length;

                checkoutCtrl.hasICReditMasterPassOption = !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (method) {
                    return method.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS && currentDeliveryTypeId === method.branchDeliveryTypeId;
                }).length;

                summaryCtrl.secondaryPayments = [];
                for (var i = 0; i < Config.retailer.checkoutPaymentMethods.length; i++) {
                    var method = angular.copy(Config.retailer.checkoutPaymentMethods[i]);
                    if (!method.isPrimary && currentDeliveryTypeId === method.branchDeliveryTypeId) {
                        if (requiredCvvGiftCards.includes(method.paymentMethodId)) {
                            method.isCvvRequired = true;
                        }
                        summaryCtrl.secondaryPayments.push(method);
                    }  
                }

                summaryCtrl.primaryPayments = Config.retailer.checkoutPaymentMethods.filter(function (method) {
                    return method.isPrimary && currentDeliveryTypeId === method.branchDeliveryTypeId;
                });

                summaryCtrl.creditCardWindowType = creditCardWindowType;

                user.getData().then(function () {
                    return _setData();
                }).then(function () {
                    if (!checkoutCtrl.isRememberCreditCardsMode && summaryCtrl.creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                        if (!_validateCheckoutDetails()) {
                            return;
                        }

                        if (checkoutCtrl.waitForSaveCart) {
                          _firstTimeAfterInit = true;

                          var removeCartUpdateListener = $rootScope.$on("cart.update.complete", function () {
                              removeCartUpdateListener();

                              checkDuplicateOrder().then(function (res) {
                                if (res.orderExists) {
                                  return;
                                }

                                _callSetPaymentUrl();
                              });
                            }
                          );
                        } else {
                          checkDuplicateOrder().then(function (res) {
                            if (res.orderExists) {
                              return;
                            }

                            _setPaymentIFrameUrl();
                          });
                        }
                    }
                })

                util.currentScopeListener($scope, function() {
                    if (_ebtEligibleListener) {
                        _ebtEligibleListener();
                    }
                });

                _init();

                function _init(){
                    if (Config.retailer.settings.enableDefaultCountry) {
                        _addressComponents.push('country');
                    }
                    _addressWithoutGeoInfo = angular.copy(checkoutCtrl.detailsData.address, {});
                    var geoCoordinates = _addressWithoutGeoInfo.geoCoordinates;
                    angular.extend(_addressWithoutGeoInfo, {
                        lat: geoCoordinates && geoCoordinates.lat ? geoCoordinates.lat : null,
                        lng: geoCoordinates && geoCoordinates.lng ? geoCoordinates.lng : null
                    });

                    if (checkoutCtrl.detailsData.deliveryMethod.id !== $rootScope.SP_SERVICES.DELIVERY_TYPES.PICKUP &&
                        checkoutCtrl.isUseGMapDeliveryMethod && checkoutCtrl.isDropPinAddress) {
                        // If use droppin and don't find house number and street
                        // delete externalPlaceId from _addressWithoutGeoInfo
                        // only use lat lng
                        delete _addressWithoutGeoInfo.externalPlaceId;

                        if(checkoutCtrl.isDropPinAddressNotDetected && checkoutCtrl.isGoogleMapDropPinValidateHouseNumAndStreet){
                            var text1Obj = GoogleMapService.constructText1(_addressWithoutGeoInfo.countryCode, _addressWithoutGeoInfo.houseNumber, _addressWithoutGeoInfo.street, _addressWithoutGeoInfo.city);
                            _addressWithoutGeoInfo.text1 = text1Obj.value;

                        }
                        _addressWithoutGeoInfo.street = '';
                        _addressWithoutGeoInfo.houseNumber = '';
                    }

                    _previousFullDeliveryAddress = angular.copy(checkoutCtrl.previousFullDeliveryAddress, {});
                    var redundantFields = ['geoCoordinates', 'isSuggestedAddress', 'hasHouseNumberAndRoute', 'isShowEditAddress', 'isDisabledAddressField'];
                    redundantFields.forEach(function (field) {
                        delete _addressWithoutGeoInfo[field];
                    });

                    summaryCtrl.giftCardDisplay = Config.retailer.settings.giftCardDisplay ? JSON.parse(Config.retailer.settings.giftCardDisplay) : {};
                }

                function _callSetPaymentUrl() {
                    if(_firstTimeAfterInit){
                        _firstTimeAfterInit = false;
                        _setPaymentIFrameUrl();
                    }
                }

                /**
                 * Init the data
                 * @private
                 */
                function _setData() {
                    if (!checkoutCtrl.summaryData) {
                        checkoutCtrl.summaryData = {
                            paymentMethod: null,
                            storeCredit: '',
                            creditCard: {},
                            paymentsNumber: 1
                        };
                    }

                    if ($rootScope.isCreditCustomer) {
                        checkoutCtrl.summaryData.paymentMethod = {
                            name: SP_PAYMENTS.PAYMENT_METHODS.NAMES.CUSTOMER_CREDIT,
                            isStandAlone: true
                        };
                        onPaymentMethodChanged();
                    } else if (checkoutCtrl.userOrganization && checkoutCtrl.userOrganization.organizationType !== ORGANIZATION_TYPES.NO_OBLIGO) {
                        checkoutCtrl.summaryData.paymentMethod = {
                            name: SP_PAYMENTS.PAYMENT_METHODS.NAMES.ORGANIZATION,
                            isStandAlone: true
                        };
                        onPaymentMethodChanged();
                    } else if (checkoutCtrl.detailsData && checkoutCtrl.detailsData.deliveryMethod) {
                        var paymentMethods = [],
                            creditCardPayment;
                        angular.forEach(checkoutCtrl.detailsData.paymentMethods, function(paymentType) {
                            if (paymentType.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD) {
                                creditCardPayment = paymentType;
                            } else {
                                paymentMethods.push(paymentType);
                            }
                        });

                        if (creditCardPayment) {
                            paymentMethods.unshift(creditCardPayment);
                        }

                        var currentMethod = checkoutCtrl.summaryData.paymentMethod && paymentMethods.find(function(method) {
                            return method.name === checkoutCtrl.summaryData.paymentMethod.name;
                        });
                        if (!currentMethod) {
                            checkoutCtrl.summaryData.paymentMethod = paymentMethods[0];
                            onPaymentMethodChanged();
                        } else {
                            checkoutCtrl.summaryData.paymentMethod = currentMethod;
                        }
                        summaryCtrl.paymentMethods = paymentMethods;
                    }
                    summaryCtrl.creditCards = [];
                    summaryCtrl.ebtCards = [];
                    summaryCtrl.paypalAccounts = summaryCtrl.paypalAccounts || [];

                    if (user.data) {
                        summaryCtrl.creditCards = user.data.creditCards || [];
                        summaryCtrl.ebtCards = user.data.ebtCards || [];
                        checkoutCtrl.summaryData.storeCredit = user.data.storeCreditAccountNumber;
                        summaryCtrl.loyaltyClubCardId = user.data.loyaltyClubCardId;
                        summaryCtrl.loyaltyVerificationStatus = user.data.loyaltyVerificationStatus;

                        var activeCards = user.data.creditCards ? angular.filterCollection(user.data.creditCards, function (x) { return x.isActive; }) : null,
                            defaultCard = activeCards ? angular.filterCollection(activeCards, function(x) { return x.isDefault; }) : null,
                            loyaltyCreditCards = user.data.creditCards ? angular.filterCollection(user.data.creditCards, function (x) { return x.isLoyalty; }) : null;

                        if (loyaltyCreditCards && loyaltyCreditCards.length) {
                            checkoutCtrl.summaryData.creditCard = loyaltyCreditCards[0];
                        } else {
                            checkoutCtrl.summaryData.creditCard = defaultCard && defaultCard.length ? defaultCard[0] : (activeCards && activeCards.length ? activeCards[0] : null);
                        }

                        summaryCtrl.hasCard = !!summaryCtrl.creditCards.length;
                        summaryCtrl.hasLoyaltyCreditCards = !!(loyaltyCreditCards && loyaltyCreditCards.length);

                        _setPaypalAccounts(user.data.paypalAccounts);
                    }

                    _setEBTEligible();
                    _disableCartLoyalty();
                    _doACheckForEbt()
                }

                function _doACheckForEbt() {
                  if(summaryCtrl.cartEBTEligibleData && summaryCtrl.cartEBTEligibleData.cash 
                    && summaryCtrl.cartEBTEligibleData.snap && summaryCtrl.cartEBTEligibleData.cash.totalWithTax === 0 
                    && summaryCtrl.cartEBTEligibleData.snap.totalWithTax === 0
                    ){
                    var filteredMethods = summaryCtrl.paymentMethods.filter(function(method){ return method.name !== 'EBT'});
                     summaryCtrl.paymentMethods = filteredMethods;
                  }
                  var allowedUsers = Config.retailer.settings.eBTAllowedUsers ? JSON.parse(Config.retailer.settings.eBTAllowedUsers) : [];
                  if(allowedUsers.length && !allowedUsers.includes(user.data.id)){
                    var filteredMethods = summaryCtrl.paymentMethods.filter(function(method){ return method.name !== 'EBT'});
                    summaryCtrl.paymentMethods = filteredMethods;
                  }
                  if(Config.retailer.settings.eBTBranchSettings){
                    var settings = JSON.parse(Config.retailer.settings.eBTBranchSettings);
                    var shallWeKeepEBTOption = false;
                    Object.values(settings[Config.branch.id]).forEach(function(value) {
                        if(typeof(value) === 'boolean' && value ){
                          shallWeKeepEBTOption = true;
                        }
                      });
                    if(!shallWeKeepEBTOption){
                      var filteredMethods = summaryCtrl.paymentMethods.filter(function(method){ return method.name !== 'EBT'});
                      summaryCtrl.paymentMethods = filteredMethods;
                    }
                  }

                    // After switched to Primary and Secondary payment methods we need to exclude EBT from primaryPayments if not present in checkoutPaymentMethods
                    var isEbtVisible = false;
                    if(summaryCtrl.paymentMethods && Array.isArray(summaryCtrl.paymentMethods)) {
                        summaryCtrl.paymentMethods.forEach(function (paymentMethod) {
                            if (paymentMethod.name === 'EBT') {
                                isEbtVisible = true;
                            }
                        });
                    }

                    if(!isEbtVisible) {
                        summaryCtrl.primaryPayments = summaryCtrl.primaryPayments.filter(function (method) {
                            return method.name !== 'EBT';
                        });
                    }
                }

                function _setEBTEligible() {
                    if (_ebtEligibleListener) {
                        _ebtEligibleListener();
                    }

                    var ebtPaymentMethod = summaryCtrl.paymentMethods && Array.isArray(summaryCtrl.paymentMethods) && summaryCtrl.paymentMethods.find(function(method) {
                        return method.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT;
                    });
                    if (!ebtPaymentMethod) {
                        return;
                    }

                    function _set() {
                        summaryCtrl.cartEBTEligibleData = cart.getEBTEligible();
                        createCashSnapTaxRatio()
                        onEBTAmountChange();
                    }

                    _set();
                    _ebtEligibleListener = $rootScope.$on('cart.update.complete', _set);
                }

                function createCashSnapTaxRatio(){
                  var ebt = summaryCtrl.cartEBTEligibleData;
                  if(!ebt.entireCart){
                    return
                  }
                  var taxRatio = ebt.snap.immutableWithTaxWithoutDeposit / ebt.snap.immutableWithoutTaxWithoutDeposit;

                  if(!taxRatio){
                    taxRatio = 1;
                  }
                  summaryCtrl.cartEBTEligibleData.entireCart.immutableTaxRatio = taxRatio;
                  return
                }
                function showAddCardDialog() {
                    if(Config.retailer.settings.useDeliveryAddressAsBilling && summaryCtrl.compiledUserAddress) {
                        return dialog.show({
                            templateUrl: 'template/dialogs/use-delivery-address-as-billing/index.html',
                            controller: ['$scope', function ($dialogScope) {
                                var dialogCtrl = this;
                                dialogCtrl.sendUserAddress = false;
                                dialogCtrl.compiledUserAddress = summaryCtrl.compiledUserAddress;
                                var userAddress = user.data.addresses[0];
                                var userCountryName = userAddress && userAddress.country
                                if (userCountryName) {
                                    userAddress.countryCode = util.getCountryCode(userCountryName);
                                }
                                $dialogScope.continue = function () {
                                    dialog.hide();
                                    return PaymentsService.addCreditCard({
                                        sendUserAddress: dialogCtrl.sendUserAddress,
                                        address: userAddress.text1 ? userAddress.text1 : userAddress.text2 ? userAddress.text2 : '',
                                        city: userAddress.city ? userAddress.city : '',
                                        country: userAddress.country ? userAddress.country : '',
                                        countryCode: userAddress.countryCode ? userAddress.countryCode : '',
                                    }).then(function () {
                                        setTimeout(function () {user.getData(true).then(_setData)}, 1000);
                                    });

                                };
                            }],
                            controllerAs: 'dialogCtrl',
                            styleClass: 'clear-cart-popup'
                        });
                    } else {
                        return PaymentsService.addCreditCard().then(function () {
                            setTimeout(function () {user.getData(true).then(_setData)}, 1000);
                        });
                    }
                }

                function chooseCreditCard(creditCard) {
                    checkoutCtrl.summaryData.creditCard = creditCard;
                    checkoutCtrl.summaryData.creditCard.cvv = '';
                    _disableCartLoyalty();
                }

                function _disableCartLoyalty() {
                    cart.disableLoyalty(summaryCtrl.hasLoyaltyCreditCards && (
                        checkoutCtrl.summaryData.paymentMethod.name !== $rootScope.SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                        !checkoutCtrl.summaryData.creditCard.isLoyalty
                    ));
                }

                /**
                 * Add a new EBT card
                 * @param {boolean} checkBalance - check the balance after adding the card
                 */
                function showAddEBTCardDialog(checkBalance) {
                    return PaymentsService.addEBTCard().then(function () {
                        user.getData(true)
                            .then(_setData)
                    });
                }

                /**
                 * Add a new Tav plus card
                 */
                function showAddTavPlusCardDialog() {
                    return PaymentsService.addGiftCard(SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS, {})
                        .then(function (tavPlusCard) {
                            if (!tavPlusCard) {
                                return;
                            }

                            if (tavPlusCard.error || !tavPlusCard.token) {
                                if (tavPlusCard.error && tavPlusCard.error.status === 403 && tavPlusCard.error.data && tavPlusCard.error.data.error === 'userIsBlocked') {
                                    return _showTavPlusErrorDialog('Multiple attempts', 'Due multiple unsuccessful attempts ,the process cannot be completed', 'OK');
                                }
                                return _showTavPlusErrorDialog('An error occurred while processing the voucher', 'Try again or check your voucher details', 'TRY AGAIN');
                            }

                            if (!tavPlusCard.balance || tavPlusCard.balance == 0) {
                                return _showTavPlusErrorDialog('Your voucher has been fully utilized', 'Try another voucher number or choose a different payment method', 'OK');
                            }
                            checkoutCtrl.summaryData.tavPlus = tavPlusCard;
                            var total = cart.total.finalPriceWithTax + cart.total.serviceFee.finalPriceWithTax + cart.total.deliveryCost.finalPriceWithTax;
                            var amount = _roundCurrencyFilter(checkoutCtrl.summaryData.tavPlus.balance < total ? checkoutCtrl.summaryData.tavPlus.balance : total)
                            checkoutCtrl.summaryData.tavPlus.amount = amount;
                            summaryCtrl.tavPlusMaxAmount= amount;
                        }).catch(function (err) {
                            return _showTavPlusErrorDialog('An error occurred while processing the voucher', 'Try again or check your voucher details', 'TRY AGAIN');
                        });
                }

                function _showTavPlusErrorDialog(title, desc, buttonText) {
                    return dialog.show({
                        template: '<div class="tav-plus-error-dialog">' +
                            '<h4 class="title">{{title | translate}}</h4> ' +
                            '<div class="desc">{{desc | translate}}</div>' +
                            '<div class="contact-phone">{{(\'Customer service\' | translate) + \': \' + contactPhone}}</div>' +
                            '<button ng-if="buttonText" ng-click="$dialog.hide()">{{buttonText | translate}}</button>' +
                            '</div>',
                        controller: ['$scope', function (scope) {
                            scope.title = title;
                            scope.desc = desc;
                            scope.contactPhone = Config.retailer.contactPhone;
                            scope.buttonText = buttonText;
                        }]
                    });
                }

                function onPaymentMethodChanged(method) {
                    if(!method) {
                        method = Config.retailer.checkoutPaymentMethods.find(function (method) {
                            return method.name === checkoutCtrl.summaryData.paymentMethod.name;
                        });
                    }
                    if(method) { // Only if we found it in checkoutPaymentMethods, otherwise it can be CreditCustomer or Organization
                        checkoutCtrl.summaryData.paymentMethod = method;
                    } else if (checkoutCtrl.summaryData.paymentMethod && checkoutCtrl.summaryData.paymentMethod.name) {
                        method = checkoutCtrl.summaryData.paymentMethod;
                    }

                    _disableCartLoyalty();
                    checkoutCtrl.summaryData.ebt = {}
                    summaryCtrl.ebtCalculatedAmounts  = {};
                    summaryCtrl.cartEBTEligibleData = {};
                    cart.save({
                        paymentMethodId: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[checkoutCtrl.summaryData.paymentMethod.name],
                        source: SP_SERVICES.SOURCES.CHECKOUT_SUMMARY
                    });

                    if(method.isStandAlone && method.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                        summaryCtrl.redeemedGiftCards = {};
                        summaryCtrl.redeemedGiftCardIds = [];
                        summaryCtrl.totalGiftCards = 0;
                    }
                }

                function _setPaypalAccounts(paypalAccounts) {
                    summaryCtrl.paypalAccounts = angular.filterCollection(paypalAccounts || [], function (x) {
                        return x.billingAgreement || x.payerId === checkoutCtrl.summaryData.paypalPayerId;
                    });
                    var defaultPaypalAccount = angular.filterCollection(summaryCtrl.paypalAccounts, function(x) { return x.isDefault; });

                    checkoutCtrl.summaryData.paypalAccount = defaultPaypalAccount && defaultPaypalAccount.length ? defaultPaypalAccount[0]
                        : (summaryCtrl.paypalAccounts && summaryCtrl.paypalAccounts.length ? summaryCtrl.paypalAccounts[0] : null);
                }

                function isShowCheckoutButton() {
                    if (!checkoutCtrl || !checkoutCtrl.summaryData || !checkoutCtrl.summaryData.paymentMethod) {
                        return true;
                    }
                    if (checkoutCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.I_CREDIT_MASTER_PASS ||
                        checkoutCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.BIT) {
                        return false;
                    }
                    if (checkoutCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL) {
                        return !!summaryCtrl.paypalAccounts.length;
                    }
                    if (_isCreditCard(checkoutCtrl.summaryData.paymentMethod.name)) {
                        return checkoutCtrl.isRememberCreditCardsMode;
                    }
                    if(checkoutCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.ORGANIZATION) {
                        return checkoutCtrl.userOrganization.isActive;
                    }

                    return true;
                }

                if (!checkoutCtrl.isRememberCreditCardsMode) {
                    if (creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                        util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', function () {
                            _resetUrlOnPaymentIframe();
                        }));
                    }

                    // when creditCardWindowType === WINDOW the payWithCreditCard function is used instead
                    if (creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                        util.currentScopeListener($scope, $rootScope.$on('external_payment_iframe.finished', function _paymentFinished(data, event) {
                            if (!event || !event.data || (event.data.error && event.data.error.error) || (!event.data.paymentToken && !event.data.actionResponse)) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = event && event.data && event.data.error ? event.data.error.error : 'Please try again';
                                    }]
                                });
                                summaryCtrl.paymentCompleted = false;
                                _resetUrlOnPaymentIframe();

                                return;
                            }
                            var checkoutResponse;

                            if (event.data.actionResponse) {
                                checkoutResponse = event.data.actionResponse;
                            } else {
                                summaryCtrl.paymentCompleted = true;
                                checkoutCtrl.summaryData.paymentToken = event.data.paymentToken;
                                checkoutCtrl.summaryData.authNum = event.data.authNum;
                                checkoutCtrl.summaryData.preAuthAmount = event.data.amount;
                                if (event.data.paymentsNumber) {
                                    checkoutCtrl.summaryData.paymentsNumber = event.data.paymentsNumber;
                                }
                            }

                            checkout(null, {checkoutResponse: checkoutResponse});
                        }));
                    }
                }

                if (checkoutCtrl.hasPaypalOption) {
                    util.currentScopeListener($scope, $rootScope.$on('paypal.finished', function (data, event) {
                        if (!event || !event.data || (event.data.error && event.data.error.error) || !event.data.paymentToken) {
                            if (event && event.data && event.data.error) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = event.data.error.error;
                                    }]
                                });
                            }
                            summaryCtrl.paymentCompleted = false;
                            return;
                        }

                        summaryCtrl.paymentCompleted = true;
                        checkoutCtrl.summaryData.paymentToken = event.data.paymentToken;
                        checkoutCtrl.summaryData.paypalPayerId = event.data.payerId;
                        user.getData(true).then(function () {
                            _setPaypalAccounts(user.data.paypalAccounts);
                        });
                    }));
                }

                if (checkoutCtrl.hasICReditMasterPassOption) {
                    util.currentScopeListener($scope, $rootScope.$on('iCreditMasterPass.finished', function (data, event) {
                        if (!event || !event.data || (event.data.error && event.data.error.error) || !event.data.paymentToken) {
                            if (event && event.data && event.data.error) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = event.data.error.error;
                                    }]
                                });
                            }
                            summaryCtrl.paymentCompleted = false;
                            return;
                        }

                        summaryCtrl.paymentCompleted = true;
                        checkoutCtrl.summaryData.paymentToken = event.data.paymentToken;
                        checkout();
                    }));
                }

                function removeCard(cardId) {
                    api.request({
                        method: 'DELETE',
                        url: '/retailers/:rid/users/:uid/removecard',
                        data: {
                            cardId: cardId
                        },
                        headers: {
                            'content-type': 'application/json'
                        }
                    }).then(function () {
                      var filteredCards = summaryCtrl.ebtCards.filter(function(card) {return card.id !== cardId})
                      summaryCtrl.ebtCards = filteredCards;
                      checkoutCtrl.summaryData.ebt = {};
                        return user.getData(true);
                    }).then(function () {
                        _setData();
                    });
                }

                function openPayPalWindow() {
                    PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL).then(function(url) {
                        $window.open(url);
                    });
                }

                function openICreditMasterPassWindow() {
                    PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS).then(function(url) {
                        $window.open(url);
                    });
                }

                function initBitButton() {
                    return _addBitSdk().then(function() {
                        window.BitPayment.Buttons({
                            onCreate: _createBitPayment,
                            onApproved: _finishBitPayment,
                            onCancel: function() {
                                dialog.show({ template: '<h4>{{\'Payment Cancelled\' | translate}}</h4>' });
                            },
                            onTimeOut: function() {
                                dialog.show({ template: '<h4>{{\'Payment Expired\' | translate}}</h4>' });
                            }
                        }).render(BIT_IFRAME_WRAPPER_SELECTOR);

                        loading.counter(null, 1);
                        angular.element(document.querySelector(BIT_IFRAME_SELECTOR)).bind('load', _onBitIFrameLoad);
                    });
                }

                function _onBitIFrameLoad() {
                    loading.counter(null, -1);
                    angular.element(document.querySelector(BIT_IFRAME_SELECTOR)).unbind('load', _onBitIFrameLoad);
                }

                function _createBitPayment(callback) {
                    api.request({
                        method: 'POST',
                        url: '/retailers/' + Config.retailer.id + '/users/' + user.session.userId + '/_init-bit-payment',
                        data: {
                            cartId: cart.serverCartId
                        }
                    }).then(function(resp) {
                        callback(resp);
                    });
                }

                function _finishBitPayment(details) {
                    api.request({
                        method: 'POST',
                        url: '/retailers/' + Config.retailer.id + '/users/' + user.session.userId + '/_bit-completed-payment',
                        data: {
                            paymentInitiationId: details.paymentInitiationId || details.allData && details.allData.orderId
                        }
                    }).then(function(resp) {
                        if (!resp || (resp.error && resp.error.error) || !resp.paymentToken) {
                            if (resp && resp.error) {
                                dialog.show({
                                    template: '<h4>{{error | translate}}</h4>',
                                    controller: ['$scope', function (scope) {
                                        scope.error = resp.error.error;
                                    }]
                                });
                            }
                            summaryCtrl.paymentCompleted = false;
                            return;
                        }

                        summaryCtrl.paymentCompleted = true;
                        checkoutCtrl.summaryData.paymentToken = resp.paymentToken;
                        checkout();
                    });
                }

                function _addBitSdk() {
                    if (window.BitPayment) {
                        return $q.resolve();
                    }

                    loading.counter(null, 1);
                    return new $q(function(resolve, reject) {
                        var script = document.createElement('script');
                        script.setAttribute('id', 'bit-sdk');
                        script.src = 'https://public.bankhapoalim.co.il/bitcom/sdk';
                        script.onload = resolve;
                        script.onerror = function() {
                            document.head.removeChild(script);
                            reject();
                        };
                        document.head.append(script);
                    }).finally(function() {
                        loading.counter(null, -1);
                    });
                }

                function onEBTAmountChange() {
                  var noError = true;
                  summaryCtrl.cartEBTEligibleData = recalculate(checkoutCtrl.summaryData.ebt, summaryCtrl.cartEBTEligibleData )
                  summaryCtrl.ebtCalculatedAmounts = PaymentsService.calculateEBTAmounts(checkoutCtrl.summaryData.ebt, summaryCtrl.cartEBTEligibleData);
                  if( checkoutCtrl.summaryData.ebt && checkoutCtrl.summaryData.ebt.fsAmount > summaryCtrl.cartEBTEligibleData.snap.totalWithoutTax ){
                      summaryCtrl.errorState.SNAP = 'amount exceeded eligibility'
                      noError = false;
                  }
                  if(checkoutCtrl.summaryData.ebt && checkoutCtrl.summaryData.ebt.cbAmount > summaryCtrl.cartEBTEligibleData.cash.totalWithTax ){
                      summaryCtrl.errorState.CASH = 'amount exceeded eligibility';
                      noError = false;
                  }
                  if(summaryCtrl.errorState.SNAP && checkoutCtrl.summaryData.ebt && checkoutCtrl.summaryData.ebt.fsAmount <= summaryCtrl.cartEBTEligibleData.snap.totalWithoutTax ){
                    delete summaryCtrl.errorState.SNAP;
                    noError = true;
                  }
                  if(summaryCtrl.errorState.CASH && checkoutCtrl.summaryData.ebt && checkoutCtrl.summaryData.ebt.cbAmount <= summaryCtrl.cartEBTEligibleData.cash.totalWithTax ){
                    delete summaryCtrl.errorState.CASH;
                    noError = true;
                  }
                  return noError
                }
              function _roundDecimal(amount) {
                return Math.round((amount) * 1000) / 1000
              }
              function recalculate(ebt, ebtEligibleData) {
                // deltasLeft is the amount left to pay by customer. SNAP products are calculated in CASH products, but not vice versa
                // When user type amount in SNAP amount to pay (fsAmount) - we calculate how much should be shown in CASH eligible to pay.
                var fsAmount = (ebt && ebt.fsAmount) ? ebt.fsAmount : 0,
                cbAmount = (ebt && ebt.cbAmount) ? ebt.cbAmount : 0,
                deltaFsLeft = _roundDecimal(cbAmount - (ebtEligibleData.cash.immutableWithTax - ebtEligibleData.snap.immutableWithoutTax));
                var cashEligibleCalculation =   _roundDecimal(ebtEligibleData.cash.immutableWithTax -  (fsAmount *  ebtEligibleData.entireCart.immutableTaxRatio));
                if (cashEligibleCalculation < 0){
                    cashEligibleCalculation = 0;
                }
                ebtEligibleData.cash.eligibleWithoutTax = ebtEligibleData.cash.totalWithoutTax =  _roundDecimal(ebtEligibleData.cash.immutableWithoutTax - fsAmount);
                ebtEligibleData.cash.eligibleWithTax = ebtEligibleData.cash.totalWithTax =  cashEligibleCalculation;
                var snapTotalEligible = _roundDecimal(ebtEligibleData.snap.immutableWithoutTax -  deltaFsLeft)
                  if(deltaFsLeft > 0){
                      ebtEligibleData.snap.eligibleWithoutTax =
                          ebtEligibleData.snap.totalWithoutTax = _roundDecimal(ebtEligibleData.snap.immutableWithoutTax -  deltaFsLeft);
                          ebtEligibleData.snap.totalWithTax = ebtEligibleData.snap.eligibleWithTax  = _roundDecimal(ebtEligibleData.snap.immutableWithTax - deltaFsLeft);
                  }
                  else {
                      ebtEligibleData.snap.eligibleWithoutTax = ebtEligibleData.snap.totalWithoutTax = ebtEligibleData.snap.immutableWithoutTax;
                      ebtEligibleData.snap.totalWithTax = ebtEligibleData.snap.eligibleWithTax =  ebtEligibleData.snap.immutableWithTax;
                  }
                  if(snapTotalEligible < 0){
                      ebtEligibleData.snap.eligibleWithoutTax = ebtEligibleData.snap.totalWithoutTax = ebtEligibleData.snap.totalWithTax = 0;
                  }
                return ebtEligibleData
              }

                function openTavPlusExampleDialog() {
                    return dialog.show({
                        controller: ['$scope', function (scope) {}],
                        templateUrl: 'template/dialogs/tav-plus-example-dialog/index.html',
                        styleClass: 'tav-plus-example-dialog'
                    });
                }

                function onTavPlusAmountChange() {
                    if (checkoutCtrl.summaryData.tavPlus.amount) {
                        checkoutCtrl.summaryData.tavPlus.amount = Number(checkoutCtrl.summaryData.tavPlus.amount.toFixed(2));
                    }
                }

                function _getEBTTransaction() {
                    if (!summaryCtrl.ebtCards.length) {
                        return $q.reject('no ebt card');
                    }

                    var address = {};
                    if (checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                        address.addressText = Config.branch.location;
                        address.city = Config.branch.city;
                        address.zipCode = Config.branch.zipCode;
                    } else {
                        address.addressText = checkoutCtrl.detailsData.address.text1;
                        address.city = checkoutCtrl.detailsData.address.city;
                        address.zipCode = checkoutCtrl.detailsData.address.zipCode;
                    }

                    return PaymentsService.initEBTPayment({
                        creditCardId: summaryCtrl.ebtCards[0].id,
                        address: address,
                        isPickup: checkoutCtrl.detailsData.deliveryMethod.id === SP_SERVICES.DELIVERY_TYPES.PICKUP
                    }).then(function(resp) {
                        return resp.paymentToken;
                    });
                }

                /**
                 * Stop loading image for iFrame and unbind on load event
                 * @param {Event} event
                 * @private
                 */
                function _onIFrameLoad(event) {
                    try {
                        var toTryAccessIFrame = event.target.contentWindow.location.href;
                        if (toTryAccessIFrame.indexOf(window.location.origin) === -1) {
                            throw 'Set as loaded';
                        }
                    } catch (e) {
                        loading.counter(null, -1);
                        var iFrameElement = document.getElementById(PAYMENT_IFRAME_ID);
                        iFrameElement && angular.element(iFrameElement).unbind('load', _onIFrameLoad);
                    }
                }

                function _onIFrameLoaded() {
                    if (loading.getCount() === 0) {
                        return;
                    }

                    loading.counter(null, -1);
                    var iFrameElement = document.getElementById(PAYMENT_IFRAME_ID);
                    iFrameElement && angular.element(iFrameElement).unbind('load', _onIFrameLoad);
                }

                /**
                 * Start loading image for iFrame and bind on load event to iFrame
                 */
                function bindIFrameLoad() {
                    if (!checkoutCtrl.isRememberCreditCardsMode && !_validateCheckoutDetails()) {
                        return;
                    }

                    loading.counter(null, 1);
                    angular.element(document.getElementById(PAYMENT_IFRAME_ID)).bind('load', _onIFrameLoad);
                    util.currentScopeListener($scope, $rootScope.$on('init_cart_iframe_redirect.loaded', _onIFrameLoaded));
                }

                /**
                 * Will set the paymentIFrameUrl property
                 * @private
                 *
                 * @return {Promise<void>}
                 */
                function _setPaymentIFrameUrl() {
                    return _getPaymentIFrameUrl().then(function(url) {
                        summaryCtrl.paymentIFrameUrl = url;
                    });
                }

                /**
                 * Will reset the src attr on the iframe element
                 * @private
                 */
                function _resetUrlOnPaymentIframe() {
                    if (creditCardWindowType === SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) {
                        return;
                    }

                    var iframe = angular.element(document.getElementById(PAYMENT_IFRAME_ID));
                    if (!iframe.length) {
                        return;
                    }

                    _getPaymentIFrameUrl().then(function(url) {
                        iframe.attr('src', url);
                    });
                }

                /**
                 * Returns the url of the init payment page
                 * @private
                 *
                 * @return {Promise<string>}
                 */
                function _getPaymentIFrameUrl() {
                    var checkoutData = {};
                    return _preCheckout(checkoutData)
                        .then(function () {
                            return PaymentsService.getPaymentUrl(SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD, {
                                deliveryDate: checkoutCtrl.detailsData.delivery && checkoutCtrl.detailsData.delivery.time && checkoutCtrl.detailsData.delivery.time.newTo,
                                action: 'checkout',
                                actionData: checkoutData
                            });
                        });
                }


                /**
                 * Validates form element and show its errors
                 * @param {HTMLElement} formElement
                 * @returns {boolean}
                 * @private
                 */
                function _validateForm(formElement) {
                    formElement = angular.element(formElement);
                    if (formElement && formElement.length) {
                        var form = formElement.data('$formController');
                        util.setServerErrorToForm(form, formElement, {});
                        if (form.$invalid) {
                            util.setFirstErrorInput(formElement);
                            return false;
                        }
                    }
                    return true;
                }

                /**
                 * Validate the checkout data
                 * @returns {boolean}
                 * @private
                 */
                function _validateCheckoutDetails() {
                    if (!checkoutCtrl.detailsData) {
                        $state.go('app.checkout.details');
                        return false;
                    }

                    if (checkoutCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                        if (!summaryCtrl.ebtCards.length) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        msg: 'Please add your EBT card'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (!summaryCtrl.creditCards.length) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        msg: 'Please add your credit card'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (!checkoutCtrl.summaryData.ebt || (!checkoutCtrl.summaryData.ebt.fsAmount && !checkoutCtrl.summaryData.ebt.cbAmount)) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'ebtFSAmount',
                                        msg: 'Please add your amount'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (!onEBTAmountChange()) {
                            return false;
                        }
                    }
                    var isTavPlus = checkoutCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.TAV_PLUS;

                    if (isTavPlus) {
                        if (!checkoutCtrl.summaryData.tavPlus) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        msg: 'Please add your Tav plus card'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (!summaryCtrl.creditCards.length) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        msg: 'Please add your credit card'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (!checkoutCtrl.summaryData.tavPlus.amount) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'tavPlusAmount',
                                        msg: 'Please add your amount'
                                    }]
                                }
                            });
                            return false;
                        }
                    }
                    var placedCVV = checkoutCtrl.summaryData.cvv || (checkoutCtrl.summaryData && checkoutCtrl.summaryData.creditCard && checkoutCtrl.summaryData.creditCard.cvv);
                    if (checkoutCtrl.isRememberCreditCardsMode && (_isCreditCard(checkoutCtrl.summaryData.paymentMethod.name) || isTavPlus)) {
                        if (!summaryCtrl.hasCard) {
                            summaryCtrl.showAddCardDialog();
                            return false;
                        }

                        if (checkoutCtrl.isRememberCreditCardsMode && !placedCVV && summaryCtrl.isCvvShownForCheckout) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'cvv',
                                        msg: 'Please enter cvv'
                                    }]
                                }
                            });
                            return false;
                        }

                        if (checkoutCtrl.isRememberCreditCardsMode && placedCVV &&
                            (placedCVV.length < 3 || placedCVV.length > 4)) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        param: 'cvv',
                                        msg: 'Invalid cvv'
                                    }]
                                }
                            });
                            return false;
                        }
                    }

                    if (checkoutCtrl.summaryData.paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL) {
                        if (!checkoutCtrl.summaryData.paypalAccount ||
                            (checkoutCtrl.summaryData.paypalAccount && !checkoutCtrl.summaryData.paypalAccount.billingAgreement && !checkoutCtrl.summaryData.paymentToken)
                        ) {
                            _setServerErrorToForm({
                                response: {
                                    errors: [{
                                        msg: 'Please select PayPal account.'
                                    }]
                                }
                            });
                            return false;
                        }
                    }

                    var res = _validateForm(document.querySelector('.checkout .tab > .details form'));
                    res = res && _validateForm(document.querySelector('.checkout .tab > .summary form'));
                    return res;
                }

                function _isCreditCard(paymentMethod) {
                    return paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD ||
                        paymentMethod === SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT;
                }

                function _setServerErrorToForm(serverRes) {
                    var formElement = angular.element(document.querySelector('.checkout .tab > .summary form')),
                        form = formElement.data('$formController');
                    util.setServerErrorToForm(form, formElement, serverRes);
                    //util.setClientErrorToForm(form, formElement, serverRes);
                    var errEl = document.getElementsByClassName('cvv-error shown')
                    if(errEl && errEl.length) {
                        var text = angular.element(errEl[0]).text();
                        angular.element(errEl[0]).html($filter('retailerNameReplacement')(text));
                    }

                    if (serverRes && serverRes.response && serverRes.response.errors &&
                        serverRes.response.errors[0] && serverRes.response.errors[0].param) {
                        formElement[0].querySelector('[name="' + serverRes.response.errors[0].param + '"]').focus();
                    }
                }

                function checkLoyaltyCreditCard() {
                    if (summaryCtrl.hasLoyaltyCreditCards &&
                        (!_isCreditCard(checkoutCtrl.summaryData.paymentMethod.name) ||
                        !checkoutCtrl.summaryData.creditCard.isLoyalty)) {
                        return dialog.show({
                            templateUrl: 'template/dialogs/loyalty/loyalty-discounts-warning/index.html',
                            controller: ['$scope', function ($scope) {
                                $scope.hide = dialog.hide;
                            }]
                        });
                    } else {
                        return $q.resolve(true);
                    }
                }

                function _preCheckout(checkoutData) {
                    return checkLoyaltyCreditCard().then(function(result) {
                        if (!result) {
                            throw RETURN_TO_LOYALTY_CARD_ERROR;
                        }

                        // update user before getting payments, payment process might need to use the user data (initpayment for example)

                        var phones = {};

                        if(Config.retailer.customPhoneValidation.isActive){
                            checkoutCtrl.detailsData.mobilePhone.phoneNumber = [
                                checkoutCtrl.detailsData.mobilePhone.areaCode ? checkoutCtrl.detailsData.mobilePhone.areaCode.toString() : '',
                                checkoutCtrl.detailsData.mobilePhone.customPhoneNumber ? checkoutCtrl.detailsData.mobilePhone.customPhoneNumber.toString() : ''
                            ].join('')
                        }
                        phones[PHONE_TYPES.MOBILE] = checkoutCtrl.detailsData.mobilePhone;
                        phones[PHONE_TYPES.MOBILE].typeVal = PHONE_TYPES.MOBILE;
                        var address;
                        if (checkoutCtrl.detailsData.deliveryMethod.id !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                            address = _addressWithoutGeoInfo;
                            delete address.isDropPinAddress;
                            delete address.placeId
                        }

                        return api.request({
                            method: 'PATCH',
                            url: '/retailers/:rid/users/:uid',
                            data: {
                                address: address,
                                phones: phones,
                                storeCreditAccountNumber: checkoutCtrl.summaryData.storeCredit,
                                paypalBillingAgreement: checkoutCtrl.summaryData.paypalAccount ? checkoutCtrl.summaryData.paypalAccount.billingAgreement : null,
                                receiptOption: checkoutCtrl.detailsData.receiptOption
                            }
                        }, {
                            dialog: true,
                            withoutToast: true
                        });
                    }).then(function() {
                        return _setCheckoutData(checkoutData);
                    });
                }

                /**
                 * Send checkout
                 * @public
                 * @param {*} paymentForm 
                 * @param {CheckoutOptionsBody} outerCheckoutData 
                 */
                function checkout(paymentForm, outerCheckoutData) {
                    if (!_validateCheckoutDetails()) {
                        return;
                    }

                    /** @type {CheckoutOptionsBody} */
                    var checkoutData = outerCheckoutData || {};

                    return $q.resolve().then(function() {
                        if (checkoutData.checkoutResponse) {
                            return;
                        }

                        return _preCheckout(checkoutData)
                    }).then(function(){
                        var checkIsDisplayPopup = false;
                        var giftCardExclusionItems = Config.retailer.giftCardExclusion && Config.retailer.giftCardExclusion.items;
                        checkoutData.payments.some(function(payment) {
                            if (giftCardExclusionItems && giftCardExclusionItems[payment.methodId]) {
                                checkIsDisplayPopup = true;
                                return true;
                            }
                        });
                        if (checkIsDisplayPopup) {
                            return dialog.show({
                                templateUrl: 'template/dialogs/gift-card-exclude-items-message/index.html',
                                styleClass: 'gift-card-exclude-message-container',
                                controller: ['$scope', function ($scope) {
                                    $scope.message = checkoutCtrl.giftCardExclusionMessage[$rootScope.config.language.culture] || _defaultGiftCardExclusionMessage[$rootScope.config.language.culture];
                                }]
                            }).then(function(is) {
                                return !!is;
                            });
                        }
                        return true;
                    }).then(function (rs){
                        if(!rs) {
                            throw new Error('canceled');
                        }
                        checkoutData.payments.forEach(function (payment, index) {
                            if (!payment.isMain) {
                                var amountMinus = _calculateAmountGiftCardExcludeItem(payment.methodId);
                                payment.amount = payment.amount - amountMinus;
                                payment.preAuthAmount = payment.preAuthAmount - amountMinus;
                            }
                        });
                        checkoutData.payments = angular.copy(checkoutData.payments.filter(function (_payment) {
                            return _payment.isMain || _payment.amount > 0;
                        }));
                        return $q.resolve(true);
                    }).then(function(rs) {
                        if(!rs) {
                            throw new Error('canceled');
                        }
                        // getGaParams calls gtag api, which should always execute a callback that resolves - the timeout function is there in case it doesn't
                        return $q.race([
                            DataLayer.getGaParams(),
                            $timeout(function(){return {'err': 'timeout'}}, 3000)
                        ]);
                    }).then(function(gaParams) {
                        if (!gaParams) {
                            return;
                        }
                        try {
                            checkoutData.metaData = JSON.stringify({gaParams: gaParams});
                        }
                        catch (e) {
                            checkoutData.metaData = JSON.stringify({gaParams: {'err': 'json'}});
                        }
                        checkoutData.savePreviousMetaData = true;
                        checkoutData.includeGaCookies = true;
                    }).then(function() {
                        checkoutCtrl.checkingOut = true;

                        if (checkoutData.checkoutResponse) {
                            return checkoutData.checkoutResponse;
                        }

                        checkoutData.total = checkoutCtrl.summaryData.total;
                        if(summaryCtrl.ebtCalculatedAmounts && summaryCtrl.ebtCalculatedAmounts.total && summaryCtrl.ebtCalculatedAmounts.total.taxSaved){
                            checkoutData.taxSaved = summaryCtrl.ebtCalculatedAmounts.total.taxSaved;
                        }

                        if(Config.retailer.customPhoneValidation.isActive){
                            checkoutData.phoneNumber = [checkoutCtrl.detailsData.mobilePhone.areaCode ? checkoutCtrl.detailsData.mobilePhone.areaCode.toString() : '',
                                checkoutCtrl.detailsData.mobilePhone.customPhoneNumber ? checkoutCtrl.detailsData.mobilePhone.customPhoneNumber.toString() : ''
                            ].join('');
                        }

                        if(!checkoutData.phoneNumberObject.phoneNumber) {
                            checkoutData.phoneNumberObject.phoneNumber = checkoutData.phoneNumber;
                        }

                        if (summaryCtrl.ebtCalculatedAmounts && summaryCtrl.ebtCalculatedAmounts.fs) {
                          checkoutData.ebtWeightDeposit = summaryCtrl.ebtCalculatedAmounts.fs.deposit;
                        }

                        if (summaryCtrl.ebtCalculatedAmounts && summaryCtrl.ebtCalculatedAmounts.cb) {
                            checkoutData.ebtWeightDeposit = checkoutData.ebtWeightDeposit || 0;
                            checkoutData.ebtWeightDeposit += summaryCtrl.ebtCalculatedAmounts.cb.deposit;
                        }

                        if (checkoutCtrl.detailsData.deliveryMethod.id !== $rootScope.SP_SERVICES.DELIVERY_TYPES.PICKUP &&
                            checkoutCtrl.isUseGMapDeliveryMethod) {
                            if(checkoutCtrl.isDropPinAddress){
                                delete checkoutData.address.externalPlaceId;
                                delete checkoutData.externalPlaceId;
                            }
                            
                            if(checkoutData.address.lat && checkoutData.address.lng && !checkoutData.address.externalPlaceId){
                                checkoutData.address.isDropPinAddress = true;
                            }
                        }


                        return api.request({
                            method: 'POST',
                            data: checkoutData,
                            url: '/v2/retailers/:rid/branches/:bid/users/:uid/isDuplicateOrder'
                        }).then(
                        /**
                         * @param {DuplicatedOrderRes} order 
                         * @returns {DuplicatedOrderRes | CheckoutRes}
                         */
                        function (order) {
                            if (!order.orderExists) {
                                return _retry(function (retries) {
                                    checkoutData.retries = retries;
                                    return api.request({
                                        method: 'POST',
                                        data: checkoutData,
                                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/_checkout'
                                    }, {
                                        withoutToast: true
                                    });
                                }, checkoutCtrl.summaryData.paymentToken ? 5 : 1);
                            } else return order;
                        });
                    }).then(function(order){
                        handleAfterCheckout(order, checkoutData)
                    }).catch(function (data) {
                        DataLayer.pushDebug('analytics debug on frontendPurchaseError', true)
                        checkoutCtrl.checkingOut = false;

                        if (data === RETURN_TO_LOYALTY_CARD_ERROR) {
                            return;
                        }

                        // try to cancel payments made using regular initpayment flow
                        _cancelTokenPayment(checkoutData, data.data);

                        if (data.statusCode !== 300) {
                            if (data.response) {
                                if (data.response.error) {
                                    //== when Credit customer is limited we need to display a custom popup with remaining sum
                                    if (data.response.code === 'creditCustomerLimited') {
                                        return cart.checkUserCreditLimited(true, 'app.cartSummary');
                                    } else if (data.response && data.response.xErrorCode && data.response.details) {
                                        util.showEbtErrorDialog(data.response);
                                    } else if (data.response.error === "The delivery time is no longer available") {
                                        var _t = $filter('translate');
                                        util.showCommonDialog({
                                            disableClosing: true,
                                            title: _t('Time slot unavailable'),
                                            content: _t("We're sorry, this time slot is no longer available. Please select a different time"),
                                            buttons: [
                                                {
                                                    text: _t('Return to schedule'),
                                                    click: 'returnToSchedule()'
                                                }
                                            ],
                                            controller: ['$scope', function ($dialogScope) {
                                                $dialogScope.returnToSchedule = function () {
                                                    dialog.hide();
                                                    $state.go('^.details', {}, { reload: true });
                                                };
                                            }]
                                        });
                                    } else if (data.response.paymentError) {
                                        util.showPaymentErrorDialog(data.response);
                                    }
                                    else {
                                        util.showCommonDialog({
                                            title: data.response.title ? '{{\'' +  data.response.title  + '\' | translate}}' : '{{\'Something Went Wrong\' | translate}}...',
                                            content: _getMissingProductsMessage(data.response) || '{{\'' + data.response.error.replace(/'/g, '\\\'') + '\' | translate}}'
                                        });
                                    }
                                } else if (data.response.errors) {
                                    util.setServerErrorToForm(paymentForm, document.querySelector('form[name=paymentForm]'), data);
                                }
                            }

                            data.apiOptions = data.apiOptions || {};
                            data.apiOptions.withoutToast = false;
                            return $rootScope.$emit('spApi.error', data);
                        }

                        $state.go('^.details');
                        checkoutCtrl.showDidYouMeanDialog(data.response.addresses);
                    });
                }

                /**
                 * @typedef {Object} CheckDuplicateOrderType
                 * @property {boolean} orderExists
                 */

                /**
                 * Call to API to check if current order is same amount + delivery type id + within x mins.
                 * 
                 * - If duplicate => clear cart, send DataLayer, then redirect to checkout finish
                 * page with previous order id
                 * 
                 * - If not => do nothing
                 *
                 * @returns {Promise<CheckDuplicateOrderType>}
                 */
                function checkDuplicateOrder() {
                  /** @type {CheckoutOptionsBody} */
                  var checkoutData = {};
                  _setCheckoutData(checkoutData);

                  /** @type {CheckDuplicateOrderBody} */
                  var checkDuplicateOrderBody = {
                    cartId: checkoutData.cartId,
                    areaId: checkoutData.areaId,
                    deliveryTimeId: checkoutData.deliveryTimeId,
                    total: checkoutCtrl.summaryData.total,
                  };

                  return api
                    .request({
                      method: "POST",
                      data: checkDuplicateOrderBody,
                      url: "/v2/retailers/:rid/branches/:bid/users/:uid/isDuplicateOrder",
                    })
                    .then(handleAfterCheckDuplicateOrder);
                }

                /**
                * @param {DuplicatedOrderRes} order
                * @return {Promise<CheckDuplicateOrderType>}
                */
                function handleAfterCheckDuplicateOrder(order) {
                  if (!order.orderExists) {
                    return { orderExists: false };
                  }

                  /** @type {CheckoutOptionsBody} */
                  var checkoutData = {};
                  _setCheckoutData(checkoutData);

                  handleAfterCheckout(order, checkoutData)

                  return { orderExists: true };
                }

                /**
                 * @param {DuplicatedOrderRes | CheckoutRes} order
                 * @param {CheckoutOptionsBody} checkoutData
                 * @return {void}
                 */
                function handleAfterCheckout(order, checkoutData) {
                  DataLayer.pushDebug("analytics debug on frontendPurchaseA", true);

                  $scope.$root.$emit("checkout", order);

                  if (checkoutCtrl.summaryData.total > 0 && !order.orderExists) {
                    sendFinalCheckOutEvent(order, checkoutCtrl.summaryData.total, cart.getLines());
                  }

                  user.getData(true);
                  cart.setTimeTravel(); // clear time travel before saving cart

                  if (order.orderExists) {
                    cart.clear();
                  } else {
                    cart.clear(true);
                  }

                  LocalStorage.removeItem("typedAreaAddressComponents");
                  checkoutCtrl.oid = order.id;
                  checkoutCtrl.isCreditCardLoyalty = order.isCreditCardLoyalty;

                  if (!order["orderExists"]) {
                    DataLayer.pushDebug("analytics debug on frontendPurchaseB", true);
                    DataLayer.push(
                      DataLayer.EVENTS.PURCHASE,
                      {
                        purchase: {
                          id: order.id,
                          affiliation: checkoutCtrl.deliveryType || "",
                          revenue: checkoutCtrl.grandTotal || "",
                          tax: checkoutCtrl.taxAmount || "",
                          shipping: checkoutCtrl.shippingCost || "",
                          coupon: checkoutCtrl.couponData || "",
                          paymentType: checkoutCtrl.summaryData.paymentMethod.name,
                        },
                        products: checkoutCtrl.dataLayerCartProducts,
                      },
                      true
                    );

                    DataLayer.pushDebug("analytics debug on frontendPurchaseBF", true);
                  } else {
                    DataLayer.pushDebug("analytics debug on frontendPurchaseC", true);
                  }

                  $scope.$root.$emit("checkoutFinished");
                  $state.go("app.checkout.finish");
                  _setConfigArea();
                  $rootScope.deliveryFeeFromTimeslot = null;

                  if (checkoutData.pickingBranchId) {
                    var pickingBranch = Config.retailer.branches.find(function (branch) {
                      return branch.id === checkoutData.pickingBranchId;
                    });

                    var changes = {
                      originalBranchId: Config.branch.id,
                      branchId: checkoutData.pickingBranchId,
                      branchAreaId: pickingBranch.areas[0].id,
                      pickupBranchId: null,
                      isLiveResult: true,
                    };

                    setTimeout(function () {
                      _updatePickingBranch(order.id, changes);
                    }, 1000);
                  }
                }

                function _updatePickingBranch(orderId, changes) {
                    api.request({
                        method: 'PATCH',
                        url: '/retailers/:rid/orders/' + orderId + '/updatePickingBranch',
                        data: changes
                    }, {
                        withoutToast: true
                    }).catch(function () {
                        return;
                    });
                }

                /**
                 * @param {any} address 
                 * @returns {boolean}
                 */
                function _isPreviousAddress(address) {
                    if (!_previousFullDeliveryAddress) {
                        return false;
                    }
                    var prevAddr = _previousFullDeliveryAddress.address;
                    return _addressComponents.every(function (field) {
                        if (!prevAddr || !prevAddr[field] || !address[field]) {
                            return false;
                        }
                        return prevAddr[field].trim().toLowerCase() === address[field].trim().toLowerCase();
                    })
                }

                function _setCheckoutData(data) {
                    var address = checkoutCtrl.detailsData.address;
                    if (Config.retailer && (Config.retailer.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.ZIP_CODE || Config.retailer.deliveryAreaMethod === SP_SERVICES.DELIVERY_AREA_METHODS.CITY)) {
                        address.text1 = address.street + (address.houseNumber ? ' ' + address.houseNumber : '') + (address.entry ? ' ' + address.entry : '');
                    }

                    checkoutCtrl.summaryData.total = cart.total.finalPriceWithTax + cart.total.serviceFee.finalPriceWithTax + cart.total.deliveryCost.finalPriceWithTax;

                    data.cartId = cart.serverCartId;
                    data.deliveryMethod = checkoutCtrl.detailsData.deliveryMethod.id;
                    data.comments = checkoutCtrl.detailsData.comments;
                    data.address = _addressWithoutGeoInfo || {};
                    data.areaId = checkoutCtrl.area.id;
                    data.languageId = Config.language.id;
                    data.friendlyName = checkoutCtrl.detailsData.friendlyName;
                    data.substitutePreferences = checkoutCtrl.detailsData.substitutePreferences;
                    data.phoneNumber = checkoutCtrl.detailsData.mobilePhone.phoneNumber;
                    data.leaveByTheDoor = checkoutCtrl.detailsData.leaveByTheDoor;
                    data.externalPlaceId = address.externalPlaceId;
                    data.geoCoordinates = address.geoCoordinates;
                    data.address.friendlyName = data.friendlyName || '';
                    data.customPhoneValidation = Config.retailer.customPhoneValidation && Config.retailer.customPhoneValidation.isActive;
                    data.phoneNumberObject = checkoutCtrl.detailsData.mobilePhone;
                    data.receiptOption = checkoutCtrl.detailsData.receiptOption;
                    data.pickingBranchId = checkoutCtrl.detailsData.delivery.time.pickingBranchId;

                    if (LocalStorage.getItem('promotionId') && LocalStorage.getItem('inviteId')) {
                        data.invitePromotion = {
                            promotionId: LocalStorage.getItem('promotionId'),
                            inviteId: LocalStorage.getItem('inviteId')
                        };
                    }

                    if (checkoutCtrl.detailsData.isShippingService) {
                        data.shippingServiceCode = checkoutCtrl.detailsData.delivery.time.serviceCode;
                        data.shippingCarrierCode = checkoutCtrl.detailsData.delivery.time.carrierCode;
                    } else {
                        data.deliveryTimeId = checkoutCtrl.detailsData.delivery.time.id;
                        data.deliveryTimeDate = checkoutCtrl.detailsData.delivery.times.date;
                        data.asapPreference = checkoutCtrl.detailsData.delivery.times.asap;
                        data.shippingCarrierCode = checkoutCtrl.detailsData.shippingCarrierCode;
                    }

                    if (checkoutCtrl.summaryData.giftCardId) {
                        data.giftCards = [checkoutCtrl.summaryData.giftCardId];
                    }

                    var linkedStore = Config.retailer.linkedStores && Config.retailer.linkedStores.find(function (s) { return s.id == Config.branch.id });
                    if (linkedStore){
                        var pickingStore = Config.retailer.pickingStores && Config.retailer.pickingStores.find(function (s) { return s.id == linkedStore.pickingBranchId });
                        data.pickingRetailerId = pickingStore ? pickingStore.retailerId : null;
                        data.pickingRetailerBranchId = pickingStore ? pickingStore.id : null;
                    }

                    if (checkoutCtrl.detailsData.deliveryMethod.id !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                        var isPrevAddr = _isPreviousAddress(address);
                        if (isPrevAddr) {
                            var geoCoordinates = address.geoCoordinates || _previousFullDeliveryAddress.geoCoordinates;
                            var externalPlaceId = address.externalPlaceId || _previousFullDeliveryAddress.externalPlaceId;
                            angular.extend(data, {
                                geoCoordinates: geoCoordinates,
                                externalPlaceId: externalPlaceId
                            });
                        }
                    }

                    return _setCheckoutPayments(data);
                }

                function sendFinalCheckOutEvent(order, totalPrice, lines){
                    var products =[];
                    angular.forEach(lines, function(line){
                        if(line.product && line.product.branch && line.product.branch.regularPrice) {
                            var quantity = (line.product.isWeighable) ? (line.quantity * 1000) : line.quantity;
                            var price = (line.product.isWeighable) ? (line.product.branch.regularPrice / 1000) : line.product.branch.regularPrice;
                            products.push({
                                productId: line.product.id,
                                quantity: Math.round(quantity),
                                unitPrice: price
                            });
                        }
                    })
                    var sourceType = "internal";
                    var sourceValue = "FinalCheckOut";
                    if (cart.cartMetaData && cart.cartMetaData.utm_source){
                        sourceType = cart.cartMetaData.utm_source;
                        sourceValue = cart.cartMetaData.utm_campaign_id;
                    }
                    $rootScope.$emit('finalCheckout',order.id, totalPrice, products, sourceType, sourceValue);
                }

                /**
                 * Set the checkout data payments
                 * @private
                 *
                 * @param {Object} checkoutData
                 *
                 * @returns {Promise<Array<Object>>}
                 */
                function
                _setCheckoutPayments(checkoutData) {
                    var mainPayment = {
                        methodId: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[checkoutCtrl.summaryData.paymentMethod.name],
                        paymentsNumber: checkoutCtrl.summaryData.paymentsNumber,
                        isMain: true
                    };

                    if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT || mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.TAV_PLUS) {
                        mainPayment.methodId = SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD;
                    }

                    if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD) {
                        if (checkoutCtrl.isRememberCreditCardsMode && checkoutCtrl.summaryData.creditCard) {
                            mainPayment.creditCardId = checkoutCtrl.summaryData.creditCard.id;
                            mainPayment.cvv = checkoutCtrl.summaryData.cvv || checkoutCtrl.summaryData.creditCard.cvv;
                            mainPayment.isLoyalty = checkoutCtrl.summaryData.creditCard.isLoyalty;
                            if (checkoutCtrl.summaryData.creditCard.cardType === CREDIT_CARD_TYPES.DIRECT) {
                                mainPayment.paymentsNumber = 1;
                            }
                        } else if (!checkoutCtrl.isRememberCreditCardsMode && checkoutCtrl.summaryData.paymentToken) {
                            mainPayment.token = checkoutCtrl.summaryData.paymentToken;
                            mainPayment.authNum = checkoutCtrl.summaryData.authNum;
                            mainPayment.preAuthAmount = checkoutCtrl.summaryData.preAuthAmount;
                        }
                    } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.STORE_CREDIT && checkoutCtrl.summaryData.storeCredit) {
                        mainPayment.token = checkoutCtrl.summaryData.storeCredit;
                    } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.PAYPAL &&
                        (checkoutCtrl.summaryData.paymentToken || checkoutCtrl.summaryData.paypalAccount)) {
                        if (checkoutCtrl.summaryData.paymentToken && checkoutCtrl.summaryData.paypalAccount.payerId === checkoutCtrl.summaryData.paypalPayerId) {
                            mainPayment.token = checkoutCtrl.summaryData.paymentToken;
                        } else {
                            mainPayment.billingAgreement = checkoutCtrl.summaryData.paypalAccount ? checkoutCtrl.summaryData.paypalAccount.billingAgreement : null;
                        }
                    } else if (mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.ORGANIZATION) {
                        mainPayment.token = checkoutCtrl.summaryData.organization;
                    } else if ((mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.I_CREDIT_MASTER_PASS ||
                        mainPayment.methodId === SP_PAYMENTS.PAYMENT_METHODS.IDS.BIT) && checkoutCtrl.summaryData.paymentToken) {
                        mainPayment.token = checkoutCtrl.summaryData.paymentToken;
                        mainPayment.authNum = checkoutCtrl.summaryData.authNum;
                    }
                    checkoutData.payments = [];
                    return $q.resolve().then(function() {
                        if (checkoutCtrl.summaryData.paymentMethod.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !checkoutCtrl.summaryData.ebt.fsAmount) {
                            return;
                        }

                        return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP);
                    }).then(function(ebtFSPaymentToken) {
                        if (ebtFSPaymentToken) {
                            checkoutData.payments.push({
                                methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                                typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_SNAP,
                                amount: checkoutCtrl.summaryData.ebt.fsAmount,
                                preAuthAmount: checkoutCtrl.summaryData.ebt.fsAmount,
                                creditCardId: summaryCtrl.ebtCards[0].id,
                                token: ebtFSPaymentToken
                            });
                        }

                        if (checkoutCtrl.summaryData.paymentMethod.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT || !checkoutCtrl.summaryData.ebt.cbAmount) {
                            return;
                        }

                        return _getEBTCheckoutPaymentTransaction(SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH);
                    }).then(function(ebtCBPaymentToken) {
                        if (ebtCBPaymentToken) {
                            checkoutData.payments.push({
                                methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.EBT,
                                typeId: SP_PAYMENTS.PAYMENT_TYPES.EBT_CASH,
                                amount: checkoutCtrl.summaryData.ebt.cbAmount,
                                preAuthAmount: checkoutCtrl.summaryData.ebt.cbAmount,
                                creditCardId: summaryCtrl.ebtCards[0].id,
                                token: ebtCBPaymentToken
                            });
                        }

                        if(!checkoutCtrl.summaryData.paymentMethod.isStandAlone && checkoutCtrl.summaryData.paymentMethod.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT) {
                            if(summaryCtrl.isUsedCashbackPoints && summaryCtrl.userCashbackLoyalty) {
                                var loyaltyPointsPayment = {
                                    methodId: SP_PAYMENTS.PAYMENT_METHODS.IDS.LOYALTY_POINTS,
                                    amount: summaryCtrl.availableUserCashback,
                                    paymentsNumber: 1,
                                    token: summaryCtrl.userCashbackLoyalty.pointsToken,
                                    lastFourDigits: summaryCtrl.userCashbackLoyalty.loyaltyCardId && summaryCtrl.userCashbackLoyalty.loyaltyCardId.slice(-4),
                                    userLoyaltyClubId: summaryCtrl.userCashbackLoyalty.id
                                };

                                checkoutData.payments.push(loyaltyPointsPayment);
                            }

                            if(summaryCtrl.redeemedGiftCardIds) {
                                angular.forEach(summaryCtrl.redeemedGiftCardIds, function (giftCardId) {
                                    var paymentData = {
                                        methodId: summaryCtrl.redeemedGiftCards[giftCardId].methodId,
                                        typeId: SP_PAYMENTS.PAYMENT_TYPES.CREDIT,
                                        amount: summaryCtrl.redeemedGiftCards[giftCardId].amount,
                                        preAuthAmount: summaryCtrl.redeemedGiftCards[giftCardId].amount,
                                        token: summaryCtrl.redeemedGiftCards[giftCardId].token,
                                        lastFourDigits: summaryCtrl.redeemedGiftCards[giftCardId].lastFourDigits
                                    }

                                    if (summaryCtrl.redeemedGiftCards[giftCardId].cvv) {
                                        paymentData['cvv'] = summaryCtrl.redeemedGiftCards[giftCardId].cvv;
                                    }
                                    if (summaryCtrl.redeemedGiftCards[giftCardId].queryTransId) {
                                        paymentData['queryTransId'] = summaryCtrl.redeemedGiftCards[giftCardId].queryTransId;
                                    }
                                    checkoutData.payments.push(paymentData);
                                });
                            }
                        }

                        checkoutData.payments.push(mainPayment);

                        var is3DSCard = checkoutCtrl.isCreditCard3DSActive &&
                            checkoutCtrl.summaryData.creditCard && checkoutCtrl.summaryData.creditCard.is3DS;
                        if (mainPayment.methodId !== SP_PAYMENTS.PAYMENT_METHODS.IDS.CREDIT_CARD ||
                            (checkoutCtrl.isRememberCreditCardsMode && !is3DSCard) || // Saves cards that don`t need 3DS
                            (!checkoutCtrl.isRememberCreditCardsMode && creditCardWindowType !== SP_PAYMENTS.CREDIT_CARD_WINDOW_TYPES.WINDOW) // One time payment cards that not in new window that display inline iframe
                        ) {
                            return;
                        }

                        // This will open popup/new window
                        return PaymentsService.payWithCreditCard({
                            creditCardId: (checkoutCtrl.summaryData.creditCard || {}).id,
                            cvv: checkoutCtrl.summaryData.cvv || mainPayment.cvv,
							deliveryDate: checkoutCtrl.detailsData.delivery && checkoutCtrl.detailsData.delivery.times && checkoutCtrl.detailsData.delivery.times.date,
                            action: 'checkout',
                            actionData: checkoutData
                        }).catch(function(err) {
                            if (err.statusCode === 409 && err.response &&
                                err.response.error === 'Already checked out' && err.response.orderId) {
                                return { actionResponse: { id: err.response.orderId } };
                            } else {
                                throw err;
                            }
                        });
                    }).then(function(paymentData) {
                        if (!paymentData) {
                            return;
                        }

                        if (paymentData.actionResponse) {
                            checkoutData.checkoutResponse = paymentData.actionResponse;
                        } else if (paymentData.skip3DS) {
                            mainPayment.skip3DS = true;
                        } else {
                            mainPayment.token = paymentData.paymentToken;
                            mainPayment.authNum = paymentData.authNum;
                            mainPayment.isPaymentCompleted = paymentData.isPaymentCompleted; // In cases where the 3DSecure cannot be performed separately from J5
                            if (paymentData.paymentsNumber) {
                                mainPayment.paymentsNumber = paymentData.paymentsNumber;
                            }
                        }
                    });
                }

                function _getEBTCheckoutPaymentTransaction(paymentType) {
                    return dialog.show({
                        templateUrl: 'template/dialogs/ebt-pin-message-dialog/index.html',
                        styleClass: 'ebt-pin-message-dialog',
                        controller: ['$scope', function ($scope) {
                            $scope.paymentType = paymentType;
                        }]
                    }).then(function(is) {
                        if (!is) {
                            throw new Error('canceled');
                        }

                        return _getEBTTransaction();
                    });
                }

                function _cancelTokenPayment(checkoutData, error) {
                    var mainPayment = checkoutData.payments && checkoutData.payments.find(function (payment) {
                        return payment.isMain;
                    });
                    var ebtPayment = checkoutData.payments && checkoutData.payments.find(function (payment) {
                      return payment.methodId ===  SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME.EBT;
                    })
                    if (!mainPayment && !ebtPayment) {
                        return;
                    }
                  if (ebtPayment) {
                    if(error.payments && error.payments[0] && error.payments[0].token){
                      var ebtBalance = JSON.parse(error.payments[0].token)
                      return api.request({
                        method: 'POST',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/sendEBTErrorEmail',
                        data: {
                          payments: checkoutData.payments,
                          ebtBalance: ebtBalance.ebtBalance,
                          userEmail: user.data.email,
                          orderTime: Date.now(),
                          lastFourDigits: summaryCtrl.ebtCards[0].lastFourDigits
                        }
                      }, {
                        withoutToast: true
                      })
                    }
                  }

                  if(mainPayment){
                    mainPayment.amount = checkoutCtrl.summaryData.total;
                    return api.request({
                        method: 'POST',
                        url: '/v2/retailers/:rid/branches/:bid/users/:uid/_cancelTokenPayment',
                        data: {
                            payments: [mainPayment]
                        }
                    }, {
                        withoutToast: true
                    }).catch(function () {
                        return;
                    });
                  }

                }

                /**
                 * Make checkout for Credit Customer - check is OK and proceed
                 * @public
                 *
                 * @param {HTMLElement} paymentForm
                 */
                function checkoutCreditCustomer(paymentForm) {
                    return cart.checkUserCreditLimited(true, 'app.cartSummary').then(function(isLimited) {
                        if(!isLimited) {
                            return checkout(paymentForm);
                        }
                    });
                }

                function _retry(action, retries){
                    retries--;
                    return action(retries).catch(function(resp) {
                        if (!retries || resp.statusCode >= 300 && resp.statusCode < 500) {
                            throw resp;
                        }

                        loading.counter(null, 1);
                        return $timeout(function () {
                            loading.counter(null, -1);
                            return _retry(action, retries);
                        }, 5000);
                    });
                }

                function _setConfigArea() {
                    if (checkoutCtrl.area.id === Config.branchAreaId) {
                        return;
                    }

                    return SpDeliveryAreasService.getChooseAreaMode().then(function(chooseAreMode) {
                        var areaName = checkoutCtrl.area.name,
                            typedArea;
                        if (checkoutCtrl.area.deliveryTypeId !== SP_SERVICES.DELIVERY_TYPES.PICKUP) {
                            if (chooseAreMode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_AREA) {
                                areaName = typedArea = ((checkoutCtrl.area.names || [])[0] || {}).name || checkoutCtrl.area.name;
                            }
                            if (chooseAreMode === SP_SERVICES.CHOOSE_AREA_MODE.TYPE_GOOGLE_ADDRESS) {
                                typedArea = checkoutCtrl.detailsData.address.text1 +
                                    (checkoutCtrl.detailsData.address.zipCode ? ', ' + checkoutCtrl.detailsData.address.zipCode : '') +
                                    (checkoutCtrl.detailsData.address.city ? ', ' + checkoutCtrl.detailsData.address.city : '');
                            }
                        }
                        Config.branchAreaId = checkoutCtrl.area.id;
                        Config.area = areaName;
                        LocalStorage.setItem('area', areaName);
                        // LocalStorage.setItem('typedAreaText', typedArea);
                    });
                }

                function _getMissingProductsMessage(errorResponse) {
                    if (!errorResponse.missingProducts || !errorResponse.missingProducts.length) return;

                    var products = [];
                    angular.forEach(errorResponse.missingProducts, function (missingProduct) {
                        products.push('<div>' + (missingProduct.name || '{{\'id\' | translate}}: ' + missingProduct.id) + '</div>');
                    });
                    return '<div>{{\'The following products are not active\' | translate}}:</div><br/>' + products.join('');
                }

                function _setLoyaltySavingDriverName() {
                    if (!cart.total.loyaltyClubs) {
                        return;
                    }

                    angular.forEach(cart.total.loyaltyClubs || {}, function (loyaltyClub, id) {
                        angular.forEach(Config.retailer.loyaltyClubDrivers, function (driver) {
                            if (driver.loyaltyClubIds && !!driver.loyaltyClubIds.find(function (driverLoyaltyClubId) {
                               return Number(id) === driverLoyaltyClubId;
                            })) {
                                loyaltyClub.name = driver.name;
                            }
                        });
                    });
                }

                function redeemedNow() {
                    if(summaryCtrl.totalPriceForPointsCalculation <= 0) {
                        return util.showCommonDialog('{{\'לא ניתן לממש את נקודות המועדון (קאשבק) אם הסכום לתשלום בכרטיס האשראי הוא 0\' | translate}}');
                    }
                    return api.request({
                        method: 'POST',
                        url: '/v2/retailers/:rid/users/' + user.data.id + '/loyalty-clubs/' + summaryCtrl.userCashbackLoyalty.id + '/insertUserRedemptionPoints',
                        data: {
                            redeemedPointsAmount: summaryCtrl.availableUserCashback,
                            pointsToken: summaryCtrl.userCashbackLoyalty.pointsToken,
                            cartId: cart.serverCartId
                        }
                    }).then(function() {
                        summaryCtrl.totalPriceForPointsCalculation -= summaryCtrl.availableUserCashback;
                        summaryCtrl.userCashbackLoyalty.redeemedPointsAmount = summaryCtrl.availableUserCashback;
                        summaryCtrl.isRedeemedNow = true;
                        summaryCtrl.showAnimation = true;
                        summaryCtrl.isUsedCashbackPoints = true;
                        _calculateUserPoints();
                        // $timeout(function () {
                        //     summaryCtrl.showAnimation = false;
                        // }, 5000)
                    })
                }

                function unredeemed() {
                    return api.request({
                        method: 'DELETE',
                        url: '/v2/retailers/:rid/users/' + user.data.id + '/loyalty-clubs/' + summaryCtrl.userCashbackLoyalty.id + '/deleteUserRedemptionPoints/' + cart.serverCartId,
                    }).then(function() {
                        summaryCtrl.totalPriceForPointsCalculation += summaryCtrl.availableUserCashback;
                        _calculateUserPoints();
                        summaryCtrl.isRedeemedNow = false;
                        summaryCtrl.isUsedCashbackPoints = false;
                        summaryCtrl.showAnimation = false;
                        delete summaryCtrl.userCashbackLoyalty.redeemedPointsAmount;
                    })
                }

                function presentLoyaltyPointsComponent() {
                    if(!checkoutCtrl.summaryData.paymentMethod.isStandAlone &&
                        checkoutCtrl.summaryData.paymentMethod.name !== SP_PAYMENTS.PAYMENT_METHODS.NAMES.EBT &&
                        summaryCtrl.userCashbackLoyalty && summaryCtrl.activeCashbackLoyalty &&
                        summaryCtrl.activeCashbackLoyalty.pointsToCashback &&
                        summaryCtrl.isLoyaltyPackageEnabled && summaryCtrl.activeCashbackLoyalty.loyaltyPages.paymentDetailsPage.isActive &&
                        (summaryCtrl.activeCashbackLoyalty.isMinPointsRequired ||
                            (!summaryCtrl.activeCashbackLoyalty.isMinPointsRequired && summaryCtrl.userCashbackLoyalty.points))) {
                        return true;
                    }

                    return false;
                }

                function removeRedeemedGiftCard(giftCardId) {
                    var redeemedGiftCards = summaryCtrl._removeRedeemedGiftCard(giftCardId);

                    summaryCtrl.totalPriceForPointsCalculation += summaryCtrl.totalGiftCards || 0;
                    summaryCtrl.totalGiftCards = 0;
                    summaryCtrl.redeemedGiftCards = redeemedGiftCards;
                    summaryCtrl.redeemedGiftCardIds = Object.keys(summaryCtrl.redeemedGiftCards);
                    angular.forEach(summaryCtrl.redeemedGiftCardIds, function (giftCardId) {
                        summaryCtrl.totalGiftCards += summaryCtrl.redeemedGiftCards[giftCardId].amount;
                    });

                    if(summaryCtrl.userCashbackLoyalty) {
                        summaryCtrl.totalPriceForPointsCalculation -= summaryCtrl.totalGiftCards || 0;
                        summaryCtrl.availableUserCashback -= summaryCtrl.totalGiftCards || 0;
                        _calculateUserPoints();
                    }
                }

                function _setAvailablePointsAndCashback() {
                    summaryCtrl.totalPriceForPointsCalculation = $rootScope.cart.total.finalPriceWithTax;

                    if(summaryCtrl.userPointsToCashback) {
                        summaryCtrl.availableUserCashback = summaryCtrl.userPointsToCashback > summaryCtrl.totalPriceForPointsCalculation ? summaryCtrl.totalPriceForPointsCalculation : summaryCtrl.userPointsToCashback;
                    }

                    if(!checkoutCtrl.summaryData.paymentMethod.isStandAlone && summaryCtrl.isUsedCashbackPoints) {
                        summaryCtrl.totalPriceForPointsCalculation -= summaryCtrl.availableUserCashback;
                    }

                    _calculateUserPoints();
                }

                function _calculateUserPoints() {
                    if(summaryCtrl.totalPriceForPointsCalculation > 0) {
                        var points = summaryCtrl.totalPriceForPointsCalculation / summaryCtrl.activeCashbackLoyalty.moneyToPointRatio;
                        summaryCtrl.currentOrderPoints = Number(points.toFixed(1));
                    } else {
                        summaryCtrl.currentOrderPoints = null;
                    }
                }

                function _isCashbackLoyaltyClub(loyaltyClubId) {
                    var isCashbackLoyaltyClub = false;
                    angular.forEach(Config.retailer.loyaltyClubDrivers, function (loyaltyClubDriver) {
                        isCashbackLoyaltyClub = loyaltyClubDriver.clientConfig.loyaltyClubs && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] &&
                            loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback;
                    });

                    return isCashbackLoyaltyClub;
                }

                function _setCashbackLoyaltyClub(loyaltyClubId) {
                    angular.forEach(Config.retailer.loyaltyClubDrivers, function (loyaltyClubDriver) {
                        if(loyaltyClubDriver.clientConfig.loyaltyClubs && loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId] &&
                            loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId].pointsToCashback) {
                            summaryCtrl.activeCashbackLoyalty = loyaltyClubDriver.clientConfig.loyaltyClubs[loyaltyClubId];
                            summaryCtrl.activeCashbackLoyalty.name = loyaltyClubDriver.name;
                            summaryCtrl.activeCashbackLoyalty.pointsDecimalRound = loyaltyClubDriver.clientConfig.pointsDecimalRound;
                        }
                    });
                }

                function _setGiftCardPopupSettings() {
                    summaryCtrl.giftCardPopupSettings = Config.retailer.settings.checkoutDisplaySettings ? JSON.parse(Config.retailer.settings.checkoutDisplaySettings) : {};

                    if(summaryCtrl.giftCardPopupSettings) {
                        var keys = Object.keys(summaryCtrl.giftCardPopupSettings);

                        angular.forEach(keys, function (key) {
                            if(summaryCtrl.giftCardPopupSettings[key].paymentLinkText) {
                                summaryCtrl.giftCardPopupSettings[key].paymentLinkText = summaryCtrl.giftCardPopupSettings[key].paymentLinkText.split('{click here}');
                            }
                        });
                    }
                }

                function openGiftCardsPopup(secondaryPayments) {
                    summaryCtrl.giftCards = secondaryPayments;

                    var options = {
                        giftCards: summaryCtrl.giftCards,
                        redeemedGiftCards: summaryCtrl.redeemedGiftCards,
                        mainPaymentMethod: SP_PAYMENTS.PAYMENT_METHODS.ID_BY_NAME[checkoutCtrl.summaryData.paymentMethod.name],
                        isUsedCashbackPoints: summaryCtrl.isUsedCashbackPoints,
                        availableUserCashback: summaryCtrl.availableUserCashback
                    }

                    return dialog.show({
                        templateUrl: 'template/dialogs/add-gift-card/index.html',
                        controller: 'AddGiftCardCtrl',
                        controllerAs: 'addGiftCardCtrl',
                        ariaLabelledby: 'add_gift_card_title',
                        styleClass: 'add-gift-cards-dialog',
                        clickOutsideToClose: false,
                        disableClosing: true,
                        locals: { options: options },
                        resolve: {
                            summaryCtrl: summaryCtrl
                        }
                    }).then(function (result) {
                        if(result && angular.isObject(result)) {
                            summaryCtrl.totalPriceForPointsCalculation += summaryCtrl.totalGiftCards || 0;
                            summaryCtrl.totalGiftCards = 0;
                            summaryCtrl.redeemedGiftCards = result;
                            summaryCtrl.redeemedGiftCardIds = Object.keys(summaryCtrl.redeemedGiftCards);
                            angular.forEach(summaryCtrl.redeemedGiftCardIds, function (giftCardId) {
                                summaryCtrl.totalGiftCards += summaryCtrl.redeemedGiftCards[giftCardId].amount;
                            });

                            if(summaryCtrl.userCashbackLoyalty) {
                                summaryCtrl.totalPriceForPointsCalculation -= summaryCtrl.totalGiftCards || 0;
                                summaryCtrl.availableUserCashback -= summaryCtrl.totalGiftCards || 0;
                                _calculateUserPoints();
                            }
                        }
                    });
                }

                function _calculateAmountGiftCardExcludeItem(paymentMethodId) {
                    var giftCardExclusionItems = Config.retailer.giftCardExclusion && Config.retailer.giftCardExclusion.items;
                    var totalAmount = 0;
                    if(giftCardExclusionItems && giftCardExclusionItems[paymentMethodId]) {
                        var giftCardExclusionItemsObj = {};
                        giftCardExclusionItems[paymentMethodId].forEach(function (item) {
                            giftCardExclusionItemsObj[item.id] = item;
                        });
                        Object.keys(cart.lines).forEach(function (lineId) {
                            var cartLine = cart.lines[lineId];
                            var productInLine = cart.lines[lineId].product;
                            var needToExclude = false;
                            if (productInLine && productInLine.family && productInLine.family.categories) {
                                productInLine.family.categories.some(function (category) {
                                    var itemGiftCardExclusion = giftCardExclusionItemsObj[category.id];
                                    if (category && itemGiftCardExclusion) {
                                        if (itemGiftCardExclusion.familyId) {
                                            if (productInLine.family.id === itemGiftCardExclusion.familyId) {
                                                needToExclude = true;
                                                return true;
                                            }
                                        } else {
                                            needToExclude = true;
                                            return true;
                                        }
                                    }
                                });
                                if (needToExclude) {
                                    totalAmount = totalAmount += Number(Math.abs(cartLine.finalPriceWithTax));
                                }
                            }
                        });
                    }
                    return totalAmount;
                }
            }]);
})(angular, app);
